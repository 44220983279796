import React, { useEffect, useState } from 'react';

function DashboardVars(props){
    const [data, setData] = useState({
        allDashboards : [
            {
                type : "custom",
                components : [
                    {
                        position : 0,
                        selectedReport : {
                            "recordID": 93,
                            "active": true,
                            "relatedUserID": 1,
                            "userFullName": "Matthew Olivos",
                            "userAccountID": 10,
                            "query": "(recordID not_blank 'true')",
                            "stem": "origination",
                            "branch": "policies",
                            "criteria": {
                                "existing": [
                                    {
                                        "attr": "recordID",
                                        "criterion": {
                                            "not_blank": true
                                        },
                                        "formType": "int",
                                        "groupID": 1,
                                        "groupOperator": "AND",
                                        "inlineOperator": "AND"
                                    }
                                ],
                                "groupBy": ""
                            },
                            "columns": [
                                {
                                    "id": "_ik7vsyqml",
                                    "columnName": "policyStatus",
                                    "friendlyTerm": "Policy Status",
                                    "frozen": false
                                },
                                {
                                    "id": "_odnqapp4d",
                                    "columnName": "policyNumber",
                                    "friendlyTerm": "Policy Number",
                                    "frozen": false
                                },
                                {
                                    "id": "_j1et7uhtb",
                                    "columnName": "primaryFirstName",
                                    "friendlyTerm": "Primary First Name",
                                    "frozen": false
                                },
                                {
                                    "id": "_xjes9d81y",
                                    "columnName": "primaryLastName",
                                    "friendlyTerm": "Primary Last Name",
                                    "frozen": false
                                },
                                {
                                    "id": "_7llpt3wc2",
                                    "columnName": "deathBenefit",
                                    "friendlyTerm": "Death Benefit",
                                    "frozen": false
                                },
                                {
                                    "id": "_aq37vgthf",
                                    "columnName": "insuranceCompany",
                                    "friendlyTerm": "Insurance Company",
                                    "frozen": false
                                }
                            ],
                            "generated": true,
                            "editable": "no",
                            "scheduled": false,
                            "selectAll": false,
                            "groupID": 0,
                            "referenceRecordID": null,
                            "details": {
                                "name": "Example Originatin",
                                "description": "",
                                "system": false,
                                "startDate": "",
                                "endDate": "",
                                "scrollType": "pagination",
                                "displayType": "",
                                "viewAccessList": [
                                    "owner"
                                ],
                                "editAccessList": [
                                    "owner"
                                ]
                            }
                        },
                        sourceReportID : 93,
                        type : "report",
                    },
                    {
                        position : 1,
                        sourceReportID : 491,
                        type : "report",
                    }
                ]
            },
        ],
        selectedDashboard : {
            type : "custom",
            components : [
                {
                    position : 0,
                    selectedReport : {
                        "recordID": 93,
                        "active": true,
                        "relatedUserID": 1,
                        "userFullName": "Matthew Olivos",
                        "userAccountID": 10,
                        "query": "(recordID not_blank 'true')",
                        "stem": "origination",
                        "branch": "policies",
                        "criteria": {
                            "existing": [
                                {
                                    "attr": "recordID",
                                    "criterion": {
                                        "not_blank": true
                                    },
                                    "formType": "int",
                                    "groupID": 1,
                                    "groupOperator": "AND",
                                    "inlineOperator": "AND"
                                }
                            ],
                            "groupBy": ""
                        },
                        "columns": [
                            {
                                "id": "_ik7vsyqml",
                                "columnName": "policyStatus",
                                "friendlyTerm": "Policy Status",
                                "frozen": false
                            },
                            {
                                "id": "_odnqapp4d",
                                "columnName": "policyNumber",
                                "friendlyTerm": "Policy Number",
                                "frozen": false
                            },
                            {
                                "id": "_j1et7uhtb",
                                "columnName": "primaryFirstName",
                                "friendlyTerm": "Primary First Name",
                                "frozen": false
                            },
                            {
                                "id": "_xjes9d81y",
                                "columnName": "primaryLastName",
                                "friendlyTerm": "Primary Last Name",
                                "frozen": false
                            },
                            {
                                "id": "_7llpt3wc2",
                                "columnName": "deathBenefit",
                                "friendlyTerm": "Death Benefit",
                                "frozen": false
                            },
                            {
                                "id": "_aq37vgthf",
                                "columnName": "insuranceCompany",
                                "friendlyTerm": "Insurance Company",
                                "frozen": false
                            }
                        ],
                        "generated": true,
                        "editable": "no",
                        "scheduled": false,
                        "selectAll": false,
                        "groupID": 0,
                        "referenceRecordID": null,
                        "details": {
                            "name": "Example Originatin",
                            "description": "",
                            "system": false,
                            "startDate": "",
                            "endDate": "",
                            "scrollType": "pagination",
                            "displayType": "",
                            "viewAccessList": [
                                "owner"
                            ],
                            "editAccessList": [
                                "owner"
                            ]
                        }
                    },
                    type : "report",
                    sorting : [],
                },
                {
                    position : 1,
                    selectedReport : 
                    {
                        "recordID": 527,
                        "active": true,
                        "relatedUserID": 1,
                        "userFullName": "Matthew Olivos",
                        "userAccountID": 10,
                        "query": "(recordID not_blank 'true')",
                        "stem": "summary",
                        "branch": "summary",
                        "criteria": {
                            "existing": [
                                {
                                    "attr": "recordID",
                                    "criterion": {
                                        "not_blank": true
                                    },
                                    "formType": "int",
                                    "groupID": 1,
                                    "groupOperator": "AND",
                                    "inlineOperator": "AND"
                                }
                            ],
                            "groupBy": [
                                {
                                    "formType": "generatedList",
                                    "groupOperator": "groupBy",
                                    "friendlyTerm": "Account Manager",
                                    "attr": "accountManager",
                                    "groupID": 0,
                                    "relativeKey": 0,
                                    "id": "_abkdk0xd9",
                                    "combineBy": "Equal Value"
                                },
                                {
                                    "id": "_wnonawg9d",
                                    "formType": "generatedList",
                                    "criterion": {},
                                    "relativeKey": 0,
                                    "groupID": 0,
                                    "groupOperator": "groupBy",
                                    "inlineOperator": "AND",
                                    "attr": "policyStatus",
                                    "friendlyTerm": "Policy Status",
                                    "combineBy": "Equal Value"
                                },
                                {
                                    "id": "_ume035dbs",
                                    "formType": "int",
                                    "criterion": {},
                                    "relativeKey": 0,
                                    "groupID": 0,
                                    "groupOperator": "groupBy",
                                    "inlineOperator": "AND",
                                    "attr": "deathBenefit",
                                    "friendlyTerm": "Death Benefit",
                                    "combineBy": "100,000"
                                }
                            ]
                        },
                        "columns": [
                            {
                                "id": "_abkdk0xd9",
                                "columnName": "accountManager",
                                "friendlyTerm": "Account Manager",
                                "frozen": false
                            },
                            {
                                "id": "_wnonawg9d",
                                "columnName": "policyStatus",
                                "friendlyTerm": "Policy Status",
                                "frozen": false
                            },
                            {
                                "id": "_ume035dbs",
                                "columnName": "deathBenefit",
                                "friendlyTerm": "Death Benefit",
                                "frozen": false
                            },
                            {
                                "id": "_jafbt0x1u",
                                "customKey": 1,
                                "friendlyTerm": "TDB",
                                "custom": true,
                                "frozen": false,
                                "fieldBy": "deathBenefit",
                                "summarizeBy": "Sum"
                            },
                            {
                                "custom": "preset",
                                "customKey": 2,
                                "fieldBy": "recordID",
                                "friendlyTerm": "Count",
                                "frozen": false,
                                "id": "_jvgql6x0k",
                                "summarizeBy": "Count"
                            }
                        ],
                        "generated": true,
                        "editable": "false",
                        "scheduled": false,
                        "selectAll": true,
                        "groupID": 0,
                        "referenceRecordID": 491,
                        "referenceBranch": "policies",
                        "referenceStem": "origination",
                        "details": {
                            "name": "Working Example Template",
                            "description": "",
                            "system": false,
                            "startDate": "",
                            "endDate": "",
                            "scrollType": "pagination",
                            "displayType": "dynamic",
                            "viewAccessList": [
                                "owner"
                            ],
                            "editAccessList": [
                                "owner"
                            ]
                        }
                    },
                    type : "report",
                    sorting : [],
                },
                {position : 2,
                    selectedReport : {
                        "recordID": 658,
                        "active": true,
                        "relatedUserID": 34,
                        "userFullName": "Armando Cabrera",
                        "userAccountID": 10,
                        "query": "(recordID not_blank 'true')",
                        "stem": "summary",
                        "branch": "summary",
                        "criteria": {
                            "existing": [
                                {
                                    "attr": "recordID",
                                    "criterion": {
                                        "not_blank": true
                                    },
                                    "formType": "int",
                                    "groupID": 1,
                                    "groupOperator": "AND",
                                    "inlineOperator": "AND"
                                }
                            ],
                            "groupBy": [
                                {
                                    "formType": "date",
                                    "groupOperator": "groupBy",
                                    "friendlyTerm": "Purchase Date",
                                    "attr": "purchaseDate",
                                    "groupID": 0,
                                    "relativeKey": 0,
                                    "id": "_wdbywg3g1",
                                    "combineBy": "Year"
                                }
                            ]
                        },
                        "columns": [
                            {
                                "id": "_wdbywg3g1",
                                "columnName": "purchaseDate",
                                "friendlyTerm": "Purchase Date",
                                "frozen": false
                            },
                            {
                                "custom": "preset",
                                "customKey": 1,
                                "fieldBy": "recordID",
                                "friendlyTerm": "Count",
                                "frozen": false,
                                "id": "_slqayztx4",
                                "summarizeBy": "Count"
                            },
                            {
                                "id": "_ukv6k39j1",
                                "customKey": 2,
                                "friendlyTerm": "Face Value",
                                "custom": true,
                                "frozen": false,
                                "fieldBy": "deathBenefit",
                                "summarizeBy": "Sum"
                            },
                            {
                                "id": "_7s8dnioud",
                                "customKey": 3,
                                "friendlyTerm": "% of STOLI",
                                "custom": true,
                                "frozen": false,
                                "fieldBy": "percentOfSTOLI",
                                "summarizeBy": "Sum"
                            },
                            {
                                "id": "_b35nl25rh",
                                "customKey": 4,
                                "friendlyTerm": "Cost Basis",
                                "custom": true,
                                "frozen": false,
                                "fieldBy": "bookValue",
                                "summarizeBy": "Sum"
                            },
                            {
                                "id": "_dlgr328ki",
                                "customKey": 5,
                                "friendlyTerm": "Average IRR",
                                "custom": true,
                                "frozen": false,
                                "fieldBy": "purchaseIRR",
                                "summarizeBy": "Average"
                            }
                        ],
                        "generated": true,
                        "editable": "false",
                        "scheduled": false,
                        "selectAll": true,
                        "groupID": 0,
                        "referenceRecordID": null,
                        "referenceBranch": "policies",
                        "referenceStem": "servicing",
                        "details": {
                            "name": "Transaction History",
                            "description": "",
                            "system": false,
                            "startDate": "",
                            "endDate": "",
                            "scrollType": "pagination",
                            "displayType": "legacy",
                            "viewAccessList": [
                                "team"
                            ],
                            "editAccessList": [
                                "team"
                            ]
                        }
                    },
                    type : "report",
                    sorting : [],
                }
            ]
        },
    });

    const updateData = (path, attr, value) => {
        setData((prevState) => {
          const newState = { ...prevState };
          const pathSegments = path?.split('.');
          let current = newState;

          for (let i = 0; i < pathSegments.length - 1; i++) {
            const segment = pathSegments[i];

            if (segment.includes('[')) {
              const [key, indexStr] = segment.split('[');
              const index = parseInt(indexStr.replace(']', ''), 10);

              if (!current[key]) {
                current[key] = [];
              }
      
              if (!current[key][index]) {
                current[key][index] = {};
              }
      
              current = current[key][index];
            } else {
              if (!current[segment]) {
                current[segment] = {};
              }
      
              current = current[segment];
            }
          }
      
          const lastSegment = pathSegments[pathSegments.length - 1];
          if (lastSegment.includes('[')) {
            const [key, indexStr] = lastSegment.split('[');
            const index = parseInt(indexStr.replace(']', ''), 10);
      
            if (!current[key]) {
              current[key] = [];
            }
      
            if (!current[key][index]) {
              current[key][index] = {};
            }
      
            if (Array.isArray(attr)) {
              current[key][index] = attr;
            } else {
              current[key][index][attr] = value;
            }
          } else {
            if (Array.isArray(attr)) {
              current[lastSegment] = attr;
            } else {
              current[lastSegment][attr] = value;
            }
          }
      
          return newState;
        });
      };
    const functions = {

    }

    const dashboardVars = {
        data,
        // setData,
        setData : updateData,
        functions
    }

    return dashboardVars;
};

export default DashboardVars;