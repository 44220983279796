import { React, useEffect, useState, useRef } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

function Dropdown(props){
    const dropdownRef = useRef(null);
    const isInitialLoad = useRef(true);
    const previousChainRef = useRef(JSON.stringify(props?.chainHandler?.chain ?? []));

    const leadingPrompt = props?.leadingPrompt ? props.leadingPrompt + " " : '';
    const [dropdownList, setDropdownList] = useState(() => [...(props?.list ?? [])]);
    const [invertStatus, setInvertStatus] = useState(false);
    const [dropdownHandler, setDropdownHandler] = useState(
        props?.prompt ?? props?.default ?? props?.list?.[0] ?? undefined,
    );
    const inactive =
        props?.inactive ||
        ((!dropdownList && !props?.list)) ||
        (((typeof dropdownList === "array" && !dropdownList?.length) ||
            (typeof dropdownList === "object" && !Object.keys(dropdownList).length === 0)) && (props?.prompt ?? props?.default));

    const classNames = Array.isArray(props?.className)
    ? props?.className.join(' ')
    : props?.className || '';

    function printList() {
        if (!dropdownList) return null;
    
        let listOptions = [];
        if (Array.isArray(dropdownList)) {
            // Assume dropdownList is always an array when valid
            listOptions = [...dropdownList];
        } else if (typeof dropdownList === 'object') {
            // Handle the case where dropdownList might be an object
            listOptions = Object.entries(dropdownList).map(([key, value]) => 
                value[props?.valuePointer] ?? key
            );
        }
    
        listOptions.sort(); // Sort list options based on your criteria
    
        if (props?.default && !listOptions.includes(props?.default)) {
            listOptions.unshift(props?.default);
        }
    
        return listOptions.map(value => {
            const dataObject = dropdownList.find(option => option[props?.valuePointer ?? "value"] === value) || {};
            const valueDisplay = `${value}${dataObject[props?.countPointer ?? "count"] ? " (" + dataObject[props?.countPointer ?? "count"].toLocaleString() + ")" : ''}`;
    
            return (
                <div
                    key={value}
                    title={valueDisplay}
                    className={`option cC bR dP p g bold${dropdownHandler === value ? " selected" : ''}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        props?.setShowMenu(false);
                        setDropdownHandler(value);
                        props?.onClick(value);
                    }}
                    onMouseOver={(e) => {e?.stopPropagation()}}
                    onMouseLeave={(e) => {e?.stopPropagation()}}
                >
                    <div className="f cL gCW">
                        {valueDisplay}
                    </div>
                </div>
            );
        });
    }
    

    useEffect(() => {
        const calculatePosition = () => {
          // Get the DOM element for the dropdown
          const dropdownElement = dropdownRef.current;
    
          if (dropdownElement) {
            // Get the bounding rect for the dropdown element
            const dropdownRect = dropdownElement.getBoundingClientRect();
    
            // Calculate the vertical position of the dropdown relative to the screen
            const dropdownTopRelativeToScreen = dropdownRect.top;
    
            // Determine whether to invert based on the position
            const shouldInvertDropdown = dropdownTopRelativeToScreen > window.innerHeight / 2;
    
            setInvertStatus(shouldInvertDropdown);
          }
        };
    
        // Calculate position initially
        calculatePosition();
    
        // Attach event listeners for scroll events on the parent container
        const parentContainer = dropdownRef.current.closest('.list'); // Adjust the selector as needed
        if (parentContainer) {
          parentContainer.addEventListener('scroll', calculatePosition);
        }
    
        // Clean up the event listener when the component unmounts
        return () => {
          if (parentContainer) {
            parentContainer.removeEventListener('scroll', calculatePosition);
          }
        };
    }, []);

    useEffect(()=>{
        if(props?.generatedList){
            setDropdownList(props?.generatedList);
        }
    }, [props?.generatedList]);

    useEffect(()=>{
        if(props?.list){
            setDropdownList(props?.list);
        }
    }, [props?.list]);

    useEffect(()=>{
    }, [dropdownHandler]);

    useEffect(() => {
        setDropdownHandler(props?.prompt ?? props?.default ?? props?.list?.[0] ?? undefined);
    }, [props]);
    
    useEffect(() => {
        const currentChain = JSON.stringify(props?.chainHandler?.chain ?? []);
        
        if (isInitialLoad.current) {
            previousChainRef.current = currentChain;
            isInitialLoad.current = false;
        } else if (previousChainRef.current !== currentChain) {
            setDropdownList([...(props?.list ?? [])]);
            setDropdownHandler(props?.prompt ?? props?.default ?? props?.list?.[0] ?? undefined);
            props?.setShowMenu(false);
    
            if (typeof props?.chainHandler?.sync === 'function') {
                props.chainHandler.sync();
            }
    
            previousChainRef.current = currentChain;
        }
    }, [props?.chainHandler]);
    
    return(
        <div className="g f cC fC">
            <div
                ref={dropdownRef}
                className={`dropdown btn s bR cC g nS p${` ${classNames || "inherit"}`}${inactive ?
                    " inactive" : ''}${props?.showMenu ?
                        " opened" : ''}${props?.flex ?
                            " flex" : ''}`
                }
                onClick={dropdownList && !inactive ? (e) => {
                    if(!props?.showMenu && props?.reset){
                        props?.reset();
                    }
                    props?.setShowMenu();
                    e.stopPropagation();
                } : null}
                onMouseOver={(e) => {if (props?.showMenu){e?.target?.classList?.add('hovering')}}}
                onMouseLeave={(e) =>  {e?.target?.classList?.remove('hovering')}}
            >
                <div className={`f cC gCW bold`}>
                    {`${leadingPrompt}${dropdownHandler}`}
                </div>
                {!props?.showMenu && !invertStatus || props?.showMenu && invertStatus ?
                    <ArrowDropDownIcon/>
                :
                    <ArrowDropUpIcon/>
                }
                {props?.showMenu &&
                    <div className={`dropdown menu nS g bR dP${props?.inverted || invertStatus ? " inverted" : ''}`}>
                        {printList()}
                    </div>
                }
            </div>
        </div>
    )
}

export default Dropdown;
