import React, { useEffect, useState, useRef } from 'react';

import PublicIcon from '@mui/icons-material/Public';

function ResultsRenderHandler({
    session,
    cachedFormattedDates
  }) {
    
    const envFunctions = session?.env?.functions;

    function boldSearchText(text, searchStrings) {
        if(!text || (searchStrings?.length === 0 || !searchStrings || searchStrings?.[0] === '')){
            return text;
        }

        const lowercaseText = { value: text?.toLowerCase() };
        const result = [];
        searchStrings.sort((a, b) => b.length - a.length);

        searchStrings
        ?.filter(str => str?.trim())
        ?.forEach(searchString => {
            const lowercaseSearchString = searchString?.toLowerCase();
            let startIndex = lowercaseText?.value?.indexOf(lowercaseSearchString);

            while (startIndex !== -1) {
                const endIndex = startIndex + searchString?.length;
                result.push({ string: text?.substring(0, startIndex), bold: false });
                result.push({ string: text?.substring(startIndex, endIndex), bold: true });

                text = text?.substring(endIndex);
                lowercaseText.value = lowercaseText?.value?.substring(endIndex);
                startIndex = lowercaseText?.value?.indexOf(lowercaseSearchString);
            }
        });

        if (text?.length > 0) {
            result.push({ string: text, bold: false });
        }

        return (
            result.map((portion, index) => (
                <span className={portion?.bold ? 'bold' : ''} key={index + "-" + portion?.string}>
                    {portion?.string}
                </span>
            ))
        );
    }

    const renderFormattedColumnValue = (columnValue, returnType, searchData, special) => {
        const stringOnly = searchData === ("stringOnly" || !searchData);

        if(!columnValue){
            return null;
        }

        if(special === "grouped" || special === "Count"){
            return columnValue;
        }

        switch (returnType) {
            case "percentage":
                return stringOnly ?
                    envFunctions.convertFloatToPercentage(columnValue).toString()
                :
                    boldSearchText(envFunctions.convertFloatToPercentage(columnValue).toString(), searchData, stringOnly);
            case "currency":
                return stringOnly ?
                    envFunctions.convertIntToCurrency(columnValue).toString()
                :
                    boldSearchText(envFunctions.convertIntToCurrency(columnValue).toString(), searchData, stringOnly);
            case "date":
                if(special === 'excel'){
                    return columnValue; 
                }

                if (cachedFormattedDates.has(columnValue)) {
                    const formattedDate = cachedFormattedDates.get(columnValue);
                    return stringOnly ?
                        formattedDate
                    :
                        boldSearchText(formattedDate, searchData, stringOnly);
                }

                const reformattedDate = envFunctions?.reformatDate(columnValue);
                cachedFormattedDates.set(columnValue, reformattedDate);

                if (reformattedDate === "Invalid Date" || !columnValue) {
                    return null;
                }

                return stringOnly ?
                    reformattedDate?.toString()
                :
                    boldSearchText(reformattedDate.toString(), searchData, stringOnly);

            case "link":
                return (
                    <a className="link" target="_blank" href={columnValue}>
                        <div className="f cC g">
                            <PublicIcon />
                            <div className="f gCW">
                                { stringOnly ?
                                    columnValue?.toString()
                                :
                                    boldSearchText(columnValue?.toString(), searchData, stringOnly)}
                            </div>
                        </div>
                    </a>
                );
            default:
                return stringOnly ?
                    columnValue?.toString()
                :
                    boldSearchText(columnValue?.toString(), searchData, stringOnly);
        }
    };

    const functions = {
        renderFormattedColumnValue,
        boldSearchText
    }

    const resultsRenderHandler = {
        functions,
    };

    return resultsRenderHandler;
};

export default ResultsRenderHandler;

