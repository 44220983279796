import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import TimelineRequest from '../../Components/TimelineRequest';
import DefaultTimelinePost from '../../Components/Timeline/DefaultTimelinePost';
import ServicingTimelinePost from '../../Components/Timeline/ServicingTimelinePost';
import MessageBoard from '../../Components/MessageBoard/MessageBoard';
import ClosingTab from './ClosingTab';
import BidsReport from './BidsReport';
import PremiumSchedule from './PremiumSchedule';
import TimelinePostDetails from '../../Components/Timeline/TimelinePostDetails.js';
import LineChart from "../../Components/Chart/LineChart.js";
import { dateFormat, convertIntToCurrency } from '../../envFunctions.js';

import TimelineIcon from '@mui/icons-material/Timeline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CircularProgress from '@mui/material/CircularProgress';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import InputField from '../../Components/InputField.js';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import VerifiedIcon from '@mui/icons-material/Verified';
import RuleIcon from '@mui/icons-material/Rule';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import FolderIcon from '@mui/icons-material/Folder';
import StarsIcon from '@mui/icons-material/Stars';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import HistoryIcon from '@mui/icons-material/History';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import TvIcon from '@mui/icons-material/Tv';
import ComputerIcon from '@mui/icons-material/Computer';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ForumIcon from '@mui/icons-material/Forum';
import FolderOffIcon from '@mui/icons-material/FolderOff';

function SinglePolicy(props){
    const [policyData, setPolicyData] = useState();
    const [bidData, setBidData] = useState({});
    const [policyTimelineData, setPolicyTimelineData] = useState({});
    const [singlePolicyActivityView, setSinglePolicyActivityView] = useState();
    const [activityTimelineType, setActivityTimelineType] = useState("default");
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [bidsLoadingStatus, setBidsLoadingStatus] = useState(true);
    const [shareLink, setShareLink] = useState('');
    const [caseShareLink, setCaseShareLink] = useState('');
    const [showStatusDetail, setShowStatusDetail] = useState(false);
    const [currentTimelinePost, setCurrentTimelinePost] = useState();
    const [currentStatusKey, setCurrentStatusKey] = useState(0);
    const [openRequestCount, setOpenRequestCount] = useState(0);
    const [closingTabEligable, setClosingTabEligable] = useState(false);
    const [requestCount, setRequestCount] = useState(0);
    const [accountType, setAccountType] = useState('');
    const [lastVOC, setLastVOC] = useState({});
    const [nextPremium, setNextPremium] = useState({});
    const [firstVOCDate, setFirstVOCDate] = useState();
    const [premiumScheduleData, setPremiumScheduleData] = useState();
    const [premiumChartData, setPremiumChartData] = useState(null);
    const [showPremiumChart, setShowPremiumChart] = useState(false);
    const [visiblePremiumCount, setVisiblePremiumCount] = useState(48);
    const [notificationCounter, setNotificationCounter] = useState({});
    const { hash } = useLocation();
    const [currentView, setCurrentView] = useState(hash ? hash : "timeline");
    const approvedTabs = ["#timeline", "#bids", "#documents", "#messages", "#closing", "#details", "#premiumSchedule"];
    const currentUrl = window.location.href;
    const navigate = useNavigate();
    const params = useParams();
    const [policyType, setPolicyType] = useState(currentUrl.includes("servicing") ? "servicing" : "origination");
    const policyKey = policyType + params.relatedPolicyID;
    const [bidsDownloadStatus, setBidsDownloadStatus] = useState(false);

    function closingTabCheck(step) {
        const steps = [
          "Offer Letter Out / CRF",
          "Offer Letter In",
          "Contracts Out",
          "Contracts Back (Digital)",
          "Contracts Back",
          "Escrow / At Carrier",
          "Rescission",
          "Closed Won"
        ];

        const index = steps.indexOf(step);
        const offerLetterIndex = steps.indexOf("Offer Letter Out / CRF");

        return index >= offerLetterIndex;
    }

    function loadPolicyData(){
        const foundPolicy = props?.policiesProps?.policies.find(policy => policy.hasOwnProperty(policyKey));

        if (foundPolicy) {
            const policyData = foundPolicy[policyKey];

            const getProperty = (property) => policyData?.[property];
            const primaryFullName = `${getProperty("primaryFirstName")} ${getProperty("primaryLastName")}`;
            const secondaryFullName = `${getProperty("secondaryFirstName")} ${getProperty("secondaryLastName")}`;
            const primaryAge = getAge(getProperty("primaryBirthDate"));
            const secondaryAge = getAge(getProperty("secondaryBirthDate"));
            const deathBenefit = getProperty("deathBenefit") ?? getProperty("faceValue");

            const updatedPolicy = {
                ...policyData,
                primaryFullName,
                secondaryFullName,
                primaryAge,
                secondaryAge,
                deathBenefit,
            };

            if (policyType === "origination") {
                updatedPolicy.PVSID = getProperty("PVSID");
                setClosingTabEligable(closingTabCheck(policyData?.policyStatus));
            }

            setPolicyData(updatedPolicy);
            setPolicyType(policyType);

            props?.sessionVars?.envVars?.caseHandler?.functions?.generateShareLinkData(
                props.session?.env?.functions?.buildFetchRequest,
                policyType === "origination" ? "origination" : "servicing",
                params.relatedPolicyID
            )
            .then(shareLink => {
                setShareLink(shareLink);
                setCaseShareLink(shareLink);
            })
            .catch(error => {
                console.error("Error generating share link data:", error);
            });
        }

        return;
    }

    function loadSinglePolicyTimeline(){
        var x = 1;
        var tempLastVOC = null;
        let requestURL = "pullSinglePolicyTimeline";
        let requestBody = {
            "relatedPolicyID" : params.relatedPolicyID,
        };

        if(currentUrl.includes("servicing")){
            requestURL = "servicing/getPolicyTimeline";
            requestBody = {
                "recordID" : params.relatedPolicyID,
            }
        }

        props.session?.env?.functions?.buildFetchRequest(requestURL, requestBody)
            .then(response => response.json())
            .then(resData => {
                let timelineData = {};
                let openRequestCounter = 0;
                let requestCounter = 0;
                let tempFirstVOCDate;

                if(resData.status === 200){
                    if(currentUrl.includes("servicing")){
                        for (var key in resData.timeline){
                            if(resData.timeline[key].carrierTask === ''){
                                continue;
                            }

                            if(resData.timeline[key].carrierTask === "VOC" && !tempFirstVOCDate){
                                tempFirstVOCDate = resData.timeline[key].dateCreated;
                            }

                            if(resData.timeline[key].carrierTask === "VOC" && tempLastVOC === null){
                                tempLastVOC = {...resData.timeline[key]};
                            }

                            timelineData[x] = resData.timeline[key];
                            x++;
                        }
                    }else{
                        for (var key in resData.policyTimelineData){
                            timelineData[x] = resData.policyTimelineData[key];
                            if(resData.policyTimelineData[key].type === "Request"){
                                if(resData.policyTimelineData[key].checkStatus === "Open"){
                                    openRequestCounter++;
                                }
                                requestCounter++;
                            }
                            x++;
                        }
                    }

                    if(tempFirstVOCDate && !firstVOCDate){
                        setFirstVOCDate(tempFirstVOCDate);
                    }
                }

                setPolicyTimelineData(timelineData);
                setLoadingStatus(false);
                setOpenRequestCount(openRequestCounter);
                setRequestCount(requestCounter);
                setLastVOC(tempLastVOC);
                updateReadStatus();
            }, (error) => {
                if (error) {
                 console.log(error);
                }
            });
    }

    function loadSinglePolicyBids(){
        const paramVals = {
            "relatedPolicyID" : params.relatedPolicyID,
        };

        var x = 0;
        props.session?.env?.functions?.buildFetchRequest("pullSinglePolicyBidsData", paramVals)
            .then(response => response.json())
            .then(resData => {
                let tempBidData = {};
                if(resData.status === 200){
                    for (var currentBid in resData.bidData){
                        const bid = {
                            bidDate : resData.bidData[x].bidDate,
                            bidAmount : resData.bidData[x].bidAmount,
                            bidNotes : resData.bidData[x].bidNotes,
                            bidRDB : resData.bidData[x].bidRDB,
                            x : x
                        };

                        bid.bidDate = dateFormat(bid.bidDate);
                        tempBidData[x] = bid;
                        x++;
                    }
                }

                setBidData(tempBidData);
                setBidsLoadingStatus(false);
        }, (error) => {
            if (error) {
                console.log(error);
            }
        });
    }

    function loadSinglePolicyPremiums(){
        var x = 1;
        const today = new Date();
        const formattedDate = `${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}`;

        let requestURL = "servicing/getPremiumSchedule";
        const paramVals = {
            "recordID" : params.relatedPolicyID,
            "startDate" : formattedDate,
        };

        props.session?.env?.functions?.buildFetchRequest(requestURL, paramVals)
            .then(response => response.json())
                .then(resData => {
                    if(resData.status === 200){
                        setPremiumScheduleData(resData?.projectedPremiums);
                        setNextPremium(resData?.projectedPremiums[0]);
                    }
            }, (error) => {
                if (error) {
                 console.log(error);
                }
            });
    }

    function updateRequest(x, toggle){
        setPolicyTimelineData( policyTimelineData =>  { return { ...policyTimelineData, [x] : { ...policyTimelineData[x], checkStatus : toggle }}});
    }

    function loadTimeline(){
        let timelineBlock = [];
        let notificationCount = 0;
        let closingTypes = [
            "Closing - CRF",
            "Closing - Contract",
            "Closing - Deficiency"
        ];

        if(Object.keys(policyTimelineData).length === 0 && accountType === "origination"){
            return (
                loadingStatus ?
                    <div className="singlePolicyEmptyTimeline">
                        <div className="singlePolicyTimelineInner loading loadingDisplay">
                            <div>
                                <CircularProgress color="inherit"/>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="singleTimeline g b bR t b fC cC">
                        <div className="timelineIssueDate">
                            {policyData?.submissionDate ? "Submission Date: " + timeConverter(policyData?.submissionDate) : null }
                        </div>
                    </div>
            )
        }
        
        if(policyData?.nextStep && activityTimelineType === "default"){
            timelineBlock.push(
                <div key="nextStep" className="nextStepPost">
                    <div className="nextStepPostIcon">
                        <NextWeekIcon/>
                    </div>
                    <div className="nextStepPostInner cL">
                        <div className="nextStepIdentifier cC">
                            <span>
                                Next Step:
                            </span>
                        </div>
                        <div className="nextStepPrompt cL s">
                            {policyData?.nextStep}
                        </div>
                    </div>
                </div>
            );
        }

        for (var key of Object.keys(policyTimelineData)){
            if(policyTimelineData[key].objectType === "servicing"){
                if(policyTimelineData[key].type === "Status" || policyTimelineData[key].type === "Note"){
                    timelineBlock.push(
                        <DefaultTimelinePost
                            key={key+policyTimelineData[key].recordID}
                            status={policyTimelineData[key].status}
                            timeStamp={policyTimelineData[key].timeStamp}
                            type={policyTimelineData[key].type}
                            data={policyTimelineData[key]}
                            timelineType="origination"
                            setShowDetails={setShowStatusDetail}
                            setCurrentTimelinePost={setCurrentTimelinePost}
                            startDate={policyData?.servicingStartDate}
                            soldDate={policyData?.soldDate}
                            amountPaid={policyData?.totalAmountPaid}
                            session={props?.session}
                        />
                    );

                    if(policyTimelineData[key].readStatus === false && policyTimelineData[key].originator === "Internal"){
                        notificationCount++;
                    }
                }

                if(policyTimelineData[key].type === "VOC" || policyTimelineData[key].type === "Payment" ){
                    timelineBlock.push(
                        <ServicingTimelinePost
                            key={key+policyTimelineData[key].recordID}
                            status={policyTimelineData[key].status}
                            timeStamp={policyTimelineData[key].dateCreated}
                            type={policyTimelineData[key].type}
                            data={policyTimelineData[key]}
                            timelineType="servicing"
                            setShowDetails={setShowStatusDetail}
                            setCurrentTimelinePost={setCurrentTimelinePost}
                            firstVOCDate={firstVOCDate}
                            soldDate={policyData?.soldDate}
                            session={props?.session}
                        />
                    );
                }
            }else{
                if(policyTimelineData[key].type === "Request"){
                    timelineBlock.push(
                        <TimelineRequest
                            key={key}
                            policyKey={key}
                            objectData={policyTimelineData[key]}
                            changeActivityView={props.changeActivityView}
                            setCurrentStatusKey={setCurrentStatusKey}
                            setShowStatusDetail={setShowStatusDetail}
                            updateRequest={updateRequest}
                            setOpenRequestCount={setOpenRequestCount}
                            openRequestCount={openRequestCount}
                            navigateToDocument={navigateToDocument}
                            mobileDevice={props.mobileDevice}
                            sessionUser={props.sessionUser}
                            updatePage={props.updatePage}
                            objectType="origination"
                            sessionVars={props.sessionVars}
                            session={props?.session}
                        />
                    );
                }else{
                    if(activityTimelineType === "default" && !closingTypes.includes(policyTimelineData[key].type) ){
                        timelineBlock.push(
                            <DefaultTimelinePost
                                key={key+policyTimelineData[key].recordID}
                                status={policyTimelineData[key].status}
                                timeStamp={policyTimelineData[key].timeStamp}
                                type={policyTimelineData[key].type}
                                data={policyTimelineData[key]}
                                timelineType="origination"
                                setShowDetails={setShowStatusDetail}
                                setCurrentTimelinePost={setCurrentTimelinePost}
                                session={props?.session}
                            />
                        );

                        if(policyTimelineData[key].readStatus === false && policyTimelineData[key].originator === "Internal"){
                            notificationCount++;
                        }
                    }
                }
            }
        }

        if (notificationCounter?.timeline !== notificationCount) {
            setNotificationCounter(prevState => ({
              ...prevState,
              timeline: notificationCount
            }));
        }

        if(activityTimelineType === "default" && accountType === "origination"){
            timelineBlock.push(
                <div key={"idleStamp"} className="idleStamp">
                    <MoreVertIcon/>
                </div>);
            timelineBlock.push(
                <div key={"timelineIssueDate"} className="timelineIssueDate">
                    {policyData?.submissionDate ? "Submission Date: " + timeConverter(policyData?.submissionDate)
                    :
                    <CircularProgress color="inherit"/>
                    }
                </div>);
        }

        if(requestCount == 0 && activityTimelineType === "steps"){
            timelineBlock.push(
                <div key="emptyRequests" className="emptyRequests">
                    <div className="emptyRequestsMessage">
                        <div className="emptyRequestsPrompt">
                            There are no current requests sent out for you to complete
                        </div>
                        <div className="emptyRequestsIcon">
                            <ThumbUpAltIcon/>
                        </div>
                    </div>

                </div>
            );
        }

        return (
            <div className="singleTimeline g b bR t b fC cC">
                {timelineBlock}
            </div>
        )

    }

    function loadSinglePolicyTimelineBar(){
        let currentStatus = '';
        let loadingStatus = '';
        let cancelledPolicyTypes = [
            "Client Not Interested",
            "Unqualified Closed",
            "Closed Lost with Bid",
            "Closed Lost to Competitor",
            "Closed Lost",
            "Closed Lost no bid"
            ];
        let closedPolicyTypes = ["Closed Won"];
        let currentProgress = 2.5;
        var statusArray = [];
            statusArray["Lead"] = 2;
            statusArray["Pre-Priced"] = 5;
            statusArray["Meds/Illustration Ordered"] = 10;
            statusArray["Underwriting (LE's)"] = 25;
            statusArray["Pricing / Valuation"] = 30;
            statusArray["Shipped"] = 60;
            statusArray["Bids"] = 70;
            statusArray["Offer Letter Out / CRF"] = 75;
            statusArray["Offer Letter In"] = 80;
            statusArray["Contracts Out"] = 85;
            statusArray["Contracts Back (Digital)"] = 90;
            statusArray["Contracts Back"] = 90;
            statusArray["Rescission"] = 95;
            statusArray["Invoicing"] = 98;

        if(Object.keys(policyTimelineData).length !== 0){
            for (var statusKey in policyTimelineData){
                if (statusArray[policyTimelineData[statusKey].status] !== undefined) {
                    if(statusArray[policyTimelineData[statusKey].status] > currentProgress){
                        currentProgress = statusArray[policyTimelineData[statusKey].status];
                    }
                }
            }
        }

        let latestPostKey = Infinity;

        for (let key in policyTimelineData) {
            if (policyTimelineData[key].type === "Status") {
              if (Number(key) < latestPostKey) {
                latestPostKey = Number(key);
              }
            }
        }

        if(Object.keys(policyTimelineData).length !== 0 && cancelledPolicyTypes.includes(policyTimelineData[latestPostKey].status)){
            currentStatus = " failed";
            currentProgress = 100;
            return (
                <div className="timelineTrackBar">
                    <div className={"timelineTrackStatus" + currentStatus + loadingStatus} style={{ width: currentProgress + "%" }}></div>
                </div>
            )
        }

        if(Object.keys(policyTimelineData).length !== 0 && closedPolicyTypes.includes(policyTimelineData[latestPostKey].status)){
            currentProgress = 100;
            currentStatus = " completed";
            return (
                <div className="timelineTrackBar">
                    <div className={"timelineTrackStatus" + currentStatus + loadingStatus} style={{ width: currentProgress + "%" }}></div>
                </div>
            )
        }

        if(currentProgress === 0){
            loadingStatus = " loading";
            return (
                <div className="timelineTrackBar">
                    <div className={"timelineTrackStatus" + currentStatus + loadingStatus} style={{ width: currentProgress + "%" }}></div>
                </div>
            )
        }

        return (
            <div className="timelineTrackBar">
                <div className={"timelineTrackStatus" + currentStatus + loadingStatus} style={{ width: currentProgress + "%" }}></div>
            </div>
        )
    }

    function navigateToDocument(sourceLink){
        setShareLink(sourceLink);
        navigate("#documents");
    }

    function updateReadStatus(){
        let requestLink = "updateReadStatus";
        let requestOptions = {
            "type" : "policy",
            "relatedID" : params.relatedPolicyID,
            "unread": false,
        };

        if(currentUrl.includes("servicing")){
            requestLink = "servicing/updateReadStatus";
            requestOptions = {
                "type":"policy",
                "policyID":params.relatedPolicyID,
                "unread": false,
            };
        }

        props.session?.env?.functions?.buildFetchRequest(requestLink, requestOptions)
            .then(response => response.json())
            .then(resData => {
                if(resData.status === 200){                        
                    props.loadNotifications();
                }

                if (resData?.readStatus === false) {
                    props.markSessionPolicyAsRead(params.relatedPolicyID);
                }
            }, (error) => {
                if (error) {
                 console.log(error);
                }
              }).catch((error) => {
                console.log(error);
            });
    }

    function timeConverter(currentTime){
         let timeStamp =parseInt(Date.parse(currentTime) / 1000);
         timeStamp = new Date(timeStamp * 1000);
         timeStamp = (timeStamp.getUTCMonth()+1) + "/" + timeStamp.getUTCDate() + "/" + timeStamp.getUTCFullYear();

         return timeStamp;
    }

    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    function convertCurrency(int){
        const formatCash = n => {
            if (n < 1e3) return n;
            if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
            if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
            if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
            if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
          };

          if(formatCash(int) === 0){
            return "-";
          }else{
            return formatCash(int);
          }
    }

    function currentActivity(activityView){
        if(singlePolicyActivityView === activityView){
            return " selected";
        }
        return '';
    }

    function displayHolderTag(){
        if(policyData?.secondaryFirstName !== '' && policyData?.secondaryFirstName !== undefined){
            return (
                <div className="holderTag">
                    <GroupIcon/>
                </div>
            )
        }else{
            return (
                <div className="holderTag">
                    <PersonIcon/>
                </div>
            )
        }
    }

    function updateActivityView(activityView){
        var activityViewString = activityView.replace('#', '');
        if (window.location.hash !== '#' + activityViewString && props?.session?.env?.functions?.eligablePath() && props?.session?.user?.data?.currentPage === "policies") {
            navigate("#" + activityViewString);
        }
        setCurrentView(activityViewString);
        setSinglePolicyActivityView(activityViewString);
    }

    function loadPolicyHolderData(){
        let returningPolicyHolderData = [];
        let secondaryInsured = false;
        returningPolicyHolderData.push(
            <div
                key={2}
                className="policyDetailsHeader bold fR cL"
            >
                    Insured
            </div>
        )

        if(policyData?.secondaryFirstName !== '' && policyData?.secondaryFirstName !== undefined){
            secondaryInsured = true;
            returningPolicyHolderData.push(
                <div key={3} className="policyDetailsSplitHeader bold">
                    <div className="primaryTitle">
                        Primary
                    </div>
                    <div className="secondaryTitle">
                        Secondary
                    </div>
                </div>
            )
        }
        if(secondaryInsured){
            if(props.mobileDevice){
                returningPolicyHolderData.push(
                <div key={"4Double"} className="formCanvas">
                    <div className="doubleFormFieldRow">
                        <InputField loadingStatus={loadingStatus} value={policyData?.primaryFullName} placeholder={"Name"} name={ "firstName"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={policyData?.secondaryFullName} placeholder={"Name"} name={ "secondaryLastName"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={policyData?.primaryGender} placeholder={"Gender"} name={ "gender"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={policyData?.secondaryGender} placeholder={"Gender"} name={ "secondaryGender"} readOnly={ true }/>
                    </div>
                    {accountType === "fund" &&
                        <div className="doubleFormFieldRow">
                            <InputField loadingStatus={loadingStatus} value={policyData?.primaryDateOfDeath} placeholder={"Date of Death"} name={ "Date of Death"} readOnly={ true }/>
                        </div>
                    }
                    <div className="doubleFormFieldRow">
                        <InputField loadingStatus={loadingStatus} value={policyData?.primaryAge} placeholder={"Age"} name={ "age"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={policyData?.secondaryAge} placeholder={"Age"} name={ "secondaryAge"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={dateFormat(policyData?.birthDate)} placeholder={"Birth Date"} name={ "birthDate"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={policyData?.secondaryBirthDate} placeholder={"Birth Date"} name={ "secondaryBirthDate"} readOnly={ true }/>
                    </div>
                    {accountType === "fund" &&
                        <div className="doubleFormFieldRow">
                            <InputField loadingStatus={loadingStatus} value={policyData?.secondaryDateOfDeath} placeholder={"Date of Death"} name={ "Date of Death"} readOnly={ true }/>
                        </div>
                    }
                    <div className="doubleFormFieldRow">
                        <InputField loadingStatus={loadingStatus} value={policyData?.primaryPhoneNumber} placeholder={"Phone Number"} name={ "Phone Number"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={policyData?.primaryEmail} placeholder={"Email"} name={"Email"} readOnly={ true }/>
                    </div>
                </div>);
            }else{
                returningPolicyHolderData.push(
                    <div key={"4Double"} className="formCanvas">
                        <div className="quadFormFieldRow">
                            <InputField loadingStatus={loadingStatus} value={policyData?.primaryFullName} placeholder={"Name"} name={ "firstName"} readOnly={ true }/>
                            <InputField loadingStatus={loadingStatus} value={policyData?.primaryGender} placeholder={"Gender"} name={ "gender"} readOnly={ true }/>
                            <InputField loadingStatus={loadingStatus} value={policyData?.secondaryFullName} placeholder={"Name"} name={ "secondaryLastName"} readOnly={ true }/>
                            <InputField loadingStatus={loadingStatus} value={policyData?.secondaryGender} placeholder={"Gender"} name={ "secondaryGender"} readOnly={ true }/>
                        </div>
                        <div className="quadFormFieldRow">
                            <InputField loadingStatus={loadingStatus} value={policyData?.primaryAge} placeholder={"Age"} name={ "age"} readOnly={ true }/>
                            <InputField loadingStatus={loadingStatus} value={dateFormat(policyData?.primaryBirthDate)} placeholder={"Birth Date"} name={ "birthDate"} readOnly={ true }/>
                            <InputField loadingStatus={loadingStatus} value={policyData?.secondaryAge} placeholder={"Age"} name={ "secondaryAge"} readOnly={ true }/>
                            <InputField loadingStatus={loadingStatus} value={dateFormat(policyData?.secondaryBirthDate)} placeholder={"Birth Date"} name={ "secondaryBirthDate"} readOnly={ true }/>
                        </div>
                        {accountType === "fund" &&
                            <div className="doubleFormFieldRow">
                                <InputField loadingStatus={loadingStatus} value={policyData?.primaryDateOfDeath} placeholder={"Date of Death ( Primary )"} name={ "Date of Death"} readOnly={ true }/>
                                <InputField loadingStatus={loadingStatus} value={policyData?.secondaryDateOfDeath} placeholder={"Date of Death ( Secondary )"} name={ "Date of Death"} readOnly={ true }/>
                            </div>
                        }
                        <div className="doubleFormFieldRow">
                            <InputField loadingStatus={loadingStatus} value={policyData?.primaryPhoneNumber} placeholder={"Phone Number"} name={ "Phone Number"} readOnly={ true }/>
                            <InputField loadingStatus={loadingStatus} value={policyData?.primaryEmail} placeholder={"Email"} name={ "Email"} readOnly={ true }/>
                        </div>
                    </div>);
            }

        }else{
            returningPolicyHolderData.push(
                <div key={"4Single"} className="formCanvas">
                    <div className="doubleFormFieldRow">
                        <InputField loadingStatus={loadingStatus} value={policyData?.primaryFullName} placeholder={"Name"} name={ "firstName"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={policyData?.primaryGender} placeholder={"Gender"} name={ "gender"} readOnly={ true }/>
                    </div>
                    <div className="doubleFormFieldRow">
                        <InputField loadingStatus={loadingStatus} value={policyData?.primaryAge} placeholder={"Age"} name={ "age"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={dateFormat(policyData?.primaryBirthDate)} placeholder={"Birth Date"} name={ "birthDate"} readOnly={ true }/>
                    </div>
                    {accountType === "fund" &&
                        <div className="doubleFormFieldRow">
                            <InputField loadingStatus={loadingStatus} value={policyData?.primaryDateOfDeath} placeholder={"Date of Death"} name={ "Date of Death"} readOnly={ true }/>
                        </div>
                    }
                    <div className="doubleFormFieldRow">
                        <InputField loadingStatus={loadingStatus} value={policyData?.primaryPhoneNumber} placeholder={"Phone Number"} name={ "Phone Number"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={policyData?.primaryEmail} placeholder={"Email"} name={ "Email"} readOnly={ true }/>
                    </div>
                </div>);
        }

        returningPolicyHolderData.push(
            <div key={0} className="policyDetailsHeader bold g cL fR cL">
                {policyData && "Policy " + policyData?.policyNumber} 
                {policyData?.leadSource === "Abacus Pays" &&
                    <div className="source abacusPays cL g f bR dP ns">
                        <StarsIcon/>
                        <div>{policyData?.leadSource}</div> 
                    </div>
                }
                {policyData?.leadSource === "Television" &&
                    <div className="source abacusPays cL g f bR dP ns">
                        <TvIcon/>
                        <div>{policyData?.leadSource}</div> 
                    </div>
                }
                {policyData?.leadSource === "Digital" &&
                    <div className="source abacusPays cL g f bR dP ns">
                        <ComputerIcon/>
                        <div>{policyData?.leadSource}</div> 
                    </div>
                }
                {policyData?.leadSource === "Phone" &&
                    <div className="source abacusPays cL g f bR dP ns">
                        <PhoneIphoneIcon/>
                        <div>{policyData?.leadSource}</div> 
                    </div>
                }
                {policyData?.leadSource === "Marketplace" &&
                    <div className="source direct cL g f bR dP ns">
                        <LoyaltyIcon/>
                        <div>Direct</div> 
                    </div>
                }
                {policyData && !policyData?.leadSource && !policyData?.relatedFundID &&
                    <div className="source direct cL g f bR dP ns">
                        <LoyaltyIcon/>
                        <div>Direct</div> 
                    </div>
                }
            </div>
        );

        if(policyData && !policyData?.relatedFundID){
            returningPolicyHolderData.push(
                <div key={"brokerCanvas"} className="formCanvas">
                    <div className="doubleFormFieldRow">
                        <InputField loadingStatus={loadingStatus} value={policyData?.insuranceCompany} placeholder={"Insurance Company"} name={"carrier"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={dateFormat(policyData?.issueDate)} placeholder={"Issue Date"} name={"policyIssueDate"} readOnly={ true }/>
                    </div>
                    <div className="doubleFormFieldRow">
                        <InputField loadingStatus={loadingStatus} value={policyData?.policyStatus} placeholder={"Current Status"} name={ "status"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={convertIntToCurrency(policyData?.deathBenefit, props.mobileDevice)} placeholder={"Death Benefit"} name={"deathBenefit"} readOnly={ true }/>
                    </div>
                    <div className="doubleFormFieldRow">
                        <InputField loadingStatus={loadingStatus} value={policyData?.ownerSitusState} placeholder={"Situs State"} name={ "ownerSitusState"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={policyData?.ownerType} placeholder={"Owner Type"} name={"ownerType"} readOnly={ true }/>
                    </div>
                </div>
            );
        }

        if(policyData?.relatedFundID){
            returningPolicyHolderData.push(
                <div key={"fundCanvas"} className="formCanvas">
                    <div className="doubleFormFieldRow">
                        <InputField loadingStatus={loadingStatus} value={policyData?.insuranceCompany} placeholder={"Insurance Company"} name={"carrier"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={dateFormat(policyData?.issueDate)} placeholder={"Issue Date"} name={"policyIssueDate"} readOnly={ true }/>
                    </div>
                    <div className="doubleFormFieldRow">
                        <InputField loadingStatus={loadingStatus} value={policyData?.policyStatus} placeholder={"Current Status"} name={ "status"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={convertIntToCurrency(policyData?.deathBenefit, props.mobileDevice)} placeholder={"Death Benefit"} name={"deathBenefit"} readOnly={ true }/>
                    </div>
                    <div className="doubleFormFieldRow">
                        <InputField loadingStatus={loadingStatus} value={policyData?.deathBenefitType} placeholder={"Death Benefit Type"} name={ "policyDeathBenefitType"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={policyData?.policyType} placeholder={"Policy Type"} name={"policyType"} readOnly={ true }/>
                    </div>
                </div>
            );
        }

        if(policyData && !policyData?.relatedFundID){
            returningPolicyHolderData.push(<div key={5} className="policyDetailsHeader bold fR cL">Case Assistance</div>);
            returningPolicyHolderData.push(
                <div key={6} className="formCanvas">
                    <div className="trioFormFieldRow">
                        <InputField loadingStatus={loadingStatus} value={policyData?.accountManager} placeholder={"Account Manager"} name={ "accountManager"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={policyData?.caseProcessor} placeholder={"Case Processor"} name={ "caseProcessor"} readOnly={ true }/>
                        <InputField loadingStatus={loadingStatus} value={policyData?.caseOwner} placeholder={"Case Owner"} name={ "caseOwner"} readOnly={ true }/>
                    </div>
                </div>);
        }

        return returningPolicyHolderData
    }

    function toggleActivityTimeline(){
        if(activityTimelineType === "default"){
            setActivityTimelineType("steps");
        }else{
            setActivityTimelineType("default");
        }
    }

    function printSummaryStatement(type){
        let returningStatement = [];
        var totalBids = 0;
        var initialOffer = "-";
        var currentOffer = "-";

        if(type === "broker"){
            if(!policyType || bidsLoadingStatus){
                return (
                    <div className="summaryStatement g load cC">
                        <div className="loadingProgress">
                            <CircularProgress color="inherit"/>
                        </div>
                    </div>
                )
            }
    
            if(Object.keys(bidData).length > 0){
                totalBids = Object.keys(bidData).length;
                initialOffer = convertIntToCurrency(bidData[Object.keys(bidData).length - 1].bidAmount, props.mobileDevice);
                currentOffer = convertIntToCurrency(bidData[0].bidAmount, props.mobileDevice);
            }
    
            returningStatement.push(
                <div
                    key={"originatorStatement"}
                    className="summaryStatement g cC dG">
                    {/* <div className="f g cC data">
                        <div className="summaryStatementTitle">
                            # of Bids
                        </div>
                        <div className="summaryStatementBidValue cC s e">
                            {totalBids}
                        </div>
                    </div>
                    <div className="f g cC data">
                        <div className="summaryStatementTitle">
                            Initial Offer
                        </div>
                        <div className="summaryStatementBidValue cC s e">
                            {initialOffer}
                        </div>
                    </div>
                    <div className="f g cC data">
                        <div className="summaryStatementTitle">
                            Current Offer
                        </div>
                        <div className="summaryStatementBidValue cC s e">
                            {currentOffer}
                        </div>
                    </div> */}
                    <div className={`section one g fC cC dP alt f`}>
                        <div className="icon g cC f fR total bold">
                            <div className="f cC oH">
                                <div className="gCW">
                                    {currentOffer}
                                </div>
                            </div>
                        </div>
                        <div className="divider">
                        </div>
                        <div className="data f cC">
                            Highest Bid
                        </div>
                    </div>
                    <div className={`section two g fC cC dP f`}>
                        <div className="icon g cC f fR total bold">
                            <div className="f cC">
                                {totalBids}
                            </div>
                        </div>
                        <div className="divider">
                        </div>
                        <div className="data f cC">
                            Bid Count
                        </div>
                    </div>
                    <div className={`section three split g f fC cC dP`}>
                        <div className="icon g cC f dG">
                            <div>
                            <div className="f cC">
                                Qualified
                            </div>
                            <div className="f cC">
                                {policyData?.numQualifiedFunds ?? 0}
                            </div>
                            </div>
                            <div>
                            <div className="f cC">
                                Declined
                            </div>
                            <div className="f cC">
                                {policyData?.numUnqualifiedFunds ?? 0}
                            </div>
                            </div>
                        </div>
                        <div className="divider">
                        </div>
                        <div className="data f cC">
                            Funds Shown
                        </div>
                    </div>
                </div>
            )
        }
 
        if(type === "fund"){
            if(!policyType || bidsLoadingStatus){
                return (
                    <div className="summaryStatement">
                        <div className="loadingProgress">
                            <CircularProgress color="inherit"/>
                        </div>
                    </div>
                )
            }

            if(Object.keys(bidData).length > 0){
                totalBids = Object.keys(bidData).length;
                initialOffer = convertIntToCurrency(bidData[Object.keys(bidData).length - 1].bidAmount, props.mobileDevice);
                currentOffer = convertIntToCurrency(bidData[0].bidAmount, props.mobileDevice);
            }

            returningStatement.push(
                <div key="fundStatement" className="summaryFunderStatement g cC">
                    <div className="summaryPremium">
                        <div className="summaryPremiumPrompt cC">
                            <div>
                                Next Premium
                            </div>
                        </div>
                        <div className="summaryPremiumHeader">
                            <div className="cL">Date</div>
                            <div className="cR">Amount</div>
                        </div>
                        <div className="summaryPremiumValues">
                            <div className="cL">{nextPremium?.date ? dateFormat(nextPremium.date) : "-"}</div>
                            <div className="cR">{nextPremium?.projectedPremium || nextPremium?.projectedPremium  ? convertIntToCurrency(nextPremium.projectedPremium, props.mobileDevice) : "-"}</div>
                        </div>
                    </div>
                    <div className="summaryVOC">
                        <div className="summaryVOCPrompt cC">
                            <div>
                                Last VOC
                            </div>
                        </div>
                        <div className="summaryVOCHeader">
                            <div className="cL">Date</div>
                            <div>COI</div>
                            <div>AV</div>
                            <div className="cR">CSV</div>
                        </div>
                        <div className="summaryVOCValues">
                            <div className="cL">{lastVOC?.completedDate ? dateFormat(lastVOC.completedDate) : "-"}</div>
                            <div className="cC bold">{lastVOC?.currentCOI || lastVOC?.currentCOI  ? convertIntToCurrency(lastVOC.currentCOI, props.mobileDevice) : "-"}</div>
                            <div className="cC">{lastVOC?.accountValue || lastVOC?.accountValue === 0? convertIntToCurrency(lastVOC.accountValue, props.mobileDevice) : "-"}</div>
                            <div className="cR bold">{lastVOC?.cashValue || lastVOC?.cashValue === 0 ? convertIntToCurrency(lastVOC.cashValue, props.mobileDevice) : "-"}</div>
                        </div>
                    </div>
                </div>,
                <div key="summaryBookValue" className="summaryBookValue cL">
                    <div className="summaryBookValuePrompt">
                        Total Book Value:
                    </div>
                    <div className="summaryBookValueValue">
                        {policyData?.bookValue || policyData?.bookValue === 0 ? convertIntToCurrency(policyData?.bookValue, props.mobileDevice) : "-"}
                    </div>
                </div>
            )
        }

        return returningStatement;
    }

    function handlePremiumScheduleScroll(e){
        const { scrollTop, scrollHeight, offsetHeight } = e.target;
        const hasScrollReachedBottom =
                offsetHeight + scrollTop > scrollHeight - 40;

        if(hasScrollReachedBottom && visiblePremiumCount < Object.keys(premiumScheduleData).length){
            setVisiblePremiumCount(visiblePremiumCount + 48);
        }
    }

    function requestCountPrompt(){
        if(loadingStatus){
            return;
        }
        if(openRequestCount > 0){
            return ( 
                <div>
                    Awaiting <b>{ openRequestCount }</b> { openRequestCount > 1 ? "submissions" : "submission" } by you
                </div>
            );

        }else{
            return ( <div className="requestCompleteHeader">
                        <span>No open requests</span>
                        <DoneAllRoundedIcon/>
                    </div>)
        }
    }

    function currentViewActivity(){
        if(currentView === "timeline"){
            return (
                <div className={"singlePolicyTimelineTracker g f " + accountType}>
                    <div className="policyDetailsCardTitle">
                        <span>Policy Timeline</span>
                        <div className="groupIcon">
                            <TimelineIcon/>
                        </div>
                        {accountType === "origination" && 
                            originationTimelineTopBar()
                        }
                    </div>
                    <div className="policyDetailsCardFace bR tO">
                        {loadTimeline()}
                    </div>
                </div>
            )
        }

        if(currentView === "documents"){
            return (
                <div className="documentsBlock f">
                    <div className="documentsBlockInner">
                        {!shareLink &&
                            <div className="loadingDrive">
                                <div className="loadingDrivePrompt">Loading Drive</div>
                                <DriveFolderUploadIcon/>
                            </div>
                        }
                        {shareLink === "empty" &&
                            <div className="loadingDrive">
                                <div className="loadingDrivePrompt">
                                    No directory found. If you would like to create the folders, please contact us.
                                </div>
                                <FolderOffIcon/>
                            </div>
                        }
                        {shareLink !== "empty" &&
                            <iframe className="singlePolicyDocuments" security="restricted" src={shareLink}/>
                        }
                    </div>
                </div>
            )
        }

        if(currentView === "messages"){
            return (
                <div className="communicationWrapper g f pR">
                    <div className="messageInfoHeader g cC">
                        <div className="policyDetailsHolderTag">
                            <QuestionAnswerIcon/>
                        </div>
                        {policyData?.policyNumber &&
                            <span>{policyData?.policyNumber + " Messages"}
                            </span>
                        }
                    </div>
                    <MessageBoard
                        relatedPolicyID={params?.relatedPolicyID}
                        sessionUser={props.sessionUser}
                        loadNotifications={props.loadNotifications}
                        markSessionPolicyAsRead={props.markSessionPolicyAsRead}
                        policyData={policyData}
                        loadingStatus={loadingStatus}
                        pullType={policyData?.relatedFundID ? "servicing" : "origination"}
                        sessionVars={props.sessionVars}
                        session={props?.session}
                    />
                </div>
            )
        }

        if(currentView === "bids"){            
            return (
                <div className="bidsPanel bR g pR">
                    <BidsReport
                        downloadBidReport={downloadBidReport}
                        bidData={bidData}
                        convertIntToCurrency={convertIntToCurrency}
                        bidsLoadingStatus={bidsLoadingStatus}
                        mobileDevice={props.mobileDevice}
                        downloadStatus={bidsDownloadStatus}
                        session={props?.session}
                    />
                </div>
            )
        }

        if(currentView === "closing"){
            return (
                <div className="closingTab bR">
                    <ClosingTab
                        policyTimelineData={policyTimelineData}
                        changeActivityView={props.changeActivityView}
                        setCurrentStatusKey={setCurrentStatusKey}
                        setShowStatusDetail={setShowStatusDetail}
                        updateRequest={updateRequest}
                        navigateToDocument={navigateToDocument}
                        sessionUser={props.sessionUser}
                        downloadBidReport={downloadBidReport}
                        downloadReport={downloadReport}
                        policyData={policyData}
                        updatePage={props.updatePage}
                        loadingStatus={loadingStatus}
                        sessionVars={props.sessionVars}
                        session={props?.session}
                    />
                </div>
            )
        }

        if(currentView === "details" && props.mobileDevice){
            let details = [
                <div key="mobileDetail1" className="policyDetailsCardTitle">
                    <div className="overViewDataBlock">
                        {currentUrl.includes("servicing") &&
                            printSummaryStatement("fund")
                        }
                        {!currentUrl.includes("servicing") &&
                            printSummaryStatement("broker")
                        }
                    </div>
                    <div className="policyInfoBar g f cC">
                        <div className="policyDetailsHolderTag">
                            {displayHolderTag()}
                        </div>
                        {policyData?.qbLink && props?.sessionUser?.accountData[props?.sessionUser?.accountID]?.teamList?.find((teamMember) => teamMember.userID === props?.sessionUser?.userID)?.isAbacusEmployee &&
                            <div className="f cL">
                                <a className="cR link" href={policyData?.qbLink}>
                                    View on Quickbase
                                </a>
                            </div>
                        }
                    </div>
                </div>,
                <div key="mobileDetail2" className="policyDetails">
                    <div className="profileDetails g dG">
                        {loadPolicyHolderData()}
                    </div>
                </div>
            ];
            return (details);
        }

        if(currentView === "premiumSchedule"){
            return (
                <div className="premiumScheduleWrapper f bR tO g" onScroll={(e)=>{handlePremiumScheduleScroll(e)}}>
                    <PremiumSchedule
                        sessionUser={props.sessionUser}
                        updatePage={props.updatePage}
                        premiumScheduleData={premiumScheduleData}
                        visiblePremiumCount={visiblePremiumCount}
                        policyData={policyData}
                        setShowPremiumChart={setShowPremiumChart}
                        sessionVars={props.sessionVars}
                        session={props?.session}
                    />
                </div>
            )
        }
    }

    function originationTimelineTopBar(){
        var returningTopBar = [
            <div key="progressBar" className="singlePolicyTimelineBar">
                {loadSinglePolicyTimelineBar()}
            </div>,
            <div key="timelineHeader" className="activityTimelineHeader cL s e">
                <div className="requestCounterPrompt">
                    {requestCountPrompt()}
                </div>
                <div className="activityTimelineHeaderBtn" onClick={()=>{toggleActivityTimeline()}}>
                    {activityTimelineType === "default" ? "Show Requests Only" : "Show All Activities" }
                    {activityTimelineType === "default" ? <RuleIcon/> : <MergeTypeIcon/> }
                </div>
            </div>
        ]

        return returningTopBar;
    }

    function downloadBidReport(btnStatus){
        setBidsDownloadStatus(true);
        if(btnStatus){
            btnStatus(true);
        }

        const paramVals = {
            "caseID" : policyData?.recordID,
            "lsID" : params.relatedPolicyID
        };

        props.session?.env?.functions?.buildFetchRequest("downloadBidReport", paramVals)
            .then(response => response.json())
            .then(resData => {
                setBidsDownloadStatus(false);
                if(resData.status === 200){
                    if(btnStatus){
                        btnStatus(false);
                    }
                    downloadReport("pdf", resData.bitFile, "Abacus Life Bid Report - " + policyData?.lastName + " " + policyData?.policyNumber);
                }else{
                    if(btnStatus){
                        btnStatus("Failed");
                    }
                }
            }, (error) => {
                if (error) {
                 console.log(error);
                }
            });
    }

    function displayTabs(){
        let messageAlert = '';
        if(props.notifications[policyData?.policyNumber]?.message?.length > 0){
            messageAlert = " alert";
        }

        if(!currentUrl.includes("servicing")){
            var returningTabs = [
                <div
                    key="bidsBtn"
                    className={`switchViewBtn f g cC s e fR${notificationCounter?.bids > 0 ? " alert" : ''}` + currentActivity("bids")}
                    onClick={() => {updateActivityView("bids")}}
                >
                    {notificationCounter?.bids > 0 &&
                        <div className="switchViewBtnOption notificationBubble cC">
                            <FiberNewIcon/>
                        </div>
                    }
                    <div className="switchViewBtnOption cC">
                        <div className="option">
                            Bids
                        </div>
                    </div>
                    <div className="inlineBtnIcon cC"><ThumbsUpDownIcon/></div>
                </div>,
                <div
                    key="documentsBtn"
                    className={"switchViewBtn f g cC s e fR" + currentActivity("documents")}
                    onClick={() => {updateActivityView("documents")}}
                >
                    <div className="switchViewBtnOption cC">
                        <div className="option">
                            Documents
                        </div>
                    </div>
                    <div className="inlineBtnIcon cC"><HistoryEduIcon/></div>
                </div>,
                <div
                    key="messagesBtn"
                    className={`switchViewBtn f g cC s e fR${notificationCounter?.messages > 0 ? " alert" : ''}` + currentActivity("messages")}
                    onClick={() => {updateActivityView("messages")}}
                >
                    {notificationCounter?.messages > 0 &&
                        <div className="switchViewBtnOption notificationBubble cC">
                            <FiberNewIcon/>
                        </div>
                    }
                    <div className="switchViewBtnOption cC">
                        <div className="option">
                            Messages
                        </div>
                    </div>
                    <div className="inlineBtnIcon cC"><ForumIcon/></div>
                </div>];
                if(closingTabEligable){
                    returningTabs.push(
                        <div
                            key="closingBtn"
                            className={"switchViewBtn f g cC s e fR" + currentActivity("closing")} onClick={() => {updateActivityView("closing")}}
                        >
                            <div className="switchViewBtnOption cC">
                                <div className="option">
                                    Closing
                                </div>
                            </div>
                            <div className="inlineBtnIcon cC"><VerifiedIcon/></div>
                        </div>
                    )
                }

            return returningTabs;
        }

        if(currentUrl.includes("servicing")){
            let messageAlert = '';
            if(props.notifications[policyData?.policyNumber]?.message?.length > 0){
                messageAlert = ' alert';
            }

            returningTabs = [
                <div key="premiums" className={"switchViewBtn f g cC s e fR" + currentActivity("premiumSchedule")} onClick={() => {updateActivityView("premiumSchedule")}}>
                    <div className="switchViewBtnOption cC">
                        <div className="option">
                            Premiums
                        </div>
                    </div>
                    <div className="inlineBtnIcon cC">
                        <HistoryIcon/>
                    </div>
                </div>,
                <div key="documentsBtn" className={"switchViewBtn f g cC s e fR" + currentActivity("documents")} onClick={() => {updateActivityView("documents")}}>
                    <div className="switchViewBtnOption cC">
                        <div className="option">
                            Documents
                        </div>
                    </div>
                    <div className="inlineBtnIcon cC">
                        <HistoryEduIcon/>
                    </div>
                </div>,
                <div key="messagesBtn" className={"switchViewBtn f g cC s e fR" + messageAlert + currentActivity("messages")} onClick={() => {updateActivityView("messages")}}>
                    {messageAlert === " alert" &&
                        <div className="notificationBubble">
                            <FiberNewIcon/>
                        </div>
                    }
                    <div className="switchViewBtnOption cC">
                        <div className="option">
                            Messages
                        </div>
                    </div>
                    <div className="inlineBtnIcon cC">
                        <ForumIcon/>
                    </div>
                </div>
            ];

            return ( returningTabs )
        }
    }

    function downloadReport(MIMEType, bitFile, fileName){
        const linkSource = "data:application/pdf;base64," + bitFile;
        const downloadLink = document.createElement("a");
        var fileNameString = null;
            fileNameString = fileName + "." + MIMEType;

        downloadLink.href = linkSource;
        downloadLink.download = fileNameString;
        downloadLink.click();
    }

    function switchViewBtnContainer(tabCount){
        let className = "switchViewBtnContainer g f tabCount" + tabCount;
        // if(policyData?.fundID){
        //     className += " fund";
        // }

        return className;
    }

    function printTabs(){
        let returningTabBar = [];
        let tabCount = 0;
        let timelineClass = "switchViewBtn f g cC s e";
        if(notificationCounter?.timeline > 0){
            timelineClass += " alert";
        }

        if(props.mobileDevice){
            returningTabBar.push(
                <div key="detailsTab" className={"switchViewBtn f g cC s e" + currentActivity("details")} onClick={() => {updateActivityView("details")}}>
                    <div className="switchViewBtnOption">
                        Details
                    </div>
                    <div className="inlineBtnIcon"><FolderIcon/></div>
                </div>
            )
            tabCount++;
        }

        returningTabBar.push(
            <div key="timeline" className={timelineClass + currentActivity("timeline")} onClick={() => {updateActivityView("timeline")}}>
                {notificationCounter?.timeline > 0 &&
                    <div className="notificationBubble cC">
                        <FiberNewIcon/>
                    </div>
                }
                <div className="switchViewBtnOption">
                    Timeline
                </div>
                <div className="inlineBtnIcon"><TimelineIcon/></div>
            </div>
        )

        tabCount++;

        if(!currentUrl.includes("servicing")){ 
            returningTabBar.push(displayTabs("Originator"));
            tabCount = tabCount + 3;
            if(closingTabEligable){
                tabCount++;
            }
        }

        if(currentUrl.includes("servicing")){
            returningTabBar.push(displayTabs("Fund"));
            tabCount = tabCount + 3;
        }

        return (
            <div className={switchViewBtnContainer(tabCount)}>
                {returningTabBar}
            </div>
        )
    }

    function handleClose(evt){
        if (!evt.target.classList.contains('timelinePostDetails') && !evt.target.closest('.timelinePostDetails')) {
            setShowStatusDetail(false);
        }
    }

    function markPolicyRowAsRead(newData) {
        const foundPolicyIndex = props?.policiesProps?.policies.findIndex(policy => policy.hasOwnProperty(policyKey));
        
        if (foundPolicyIndex !== -1) {
            props.policiesProps.policies[foundPolicyIndex][policyKey] = {
                ...props.policiesProps.policies[foundPolicyIndex][policyKey],
                readStatus : Object.values(notificationCounter).every(value => value === 0),
                ...newData
            };
        }
    }

    useEffect(() => {
        if (policyData && Object.keys(policyTimelineData).length > 0) {
            const data = {
                numActivitiesUnread: 0,
                // ...(policyType === "origination" ? { numUnreadBids: 0 } : {})
            };
            markPolicyRowAsRead(data);
        }
    }, [policyData, policyTimelineData]);

    useEffect(() => {
        loadSinglePolicyTimeline();
        loadSinglePolicyBids();
        props.setPolicyNumber();
    }, [params.relatedPolicyID]);

    useEffect(() => {
        if(props?.policiesProps?.policies){
            loadPolicyData();
        }
    }, [props?.policiesProps?.policies]);

    useEffect(() => {
        if(approvedTabs.includes(hash) && hash.substring(1) !== currentView){
            updateActivityView(hash);
        }

        if(!approvedTabs.includes(hash) && hash !== ''){
            updateActivityView("timeline");
        }
    
        if(hash.substring(1) !== "documents"){
            setShareLink(caseShareLink);
        }
    }, [hash]);

    useEffect(() => {
        props.setPolicyNumber(policyData?.policyNumber);
        props.navigation.setCurrentPolicy(policyData);

        setNotificationCounter(prev => ({
            ...prev,
            bids: policyData?.numUnreadBids || prev.bids,
            messages: policyData?.numMessagesUnread || prev.messages,
        }));
    
    }, [policyData?.policyNumber]);

    useEffect(() => {
        if(props.singlePolicyHash !== ''){
            updateActivityView(props.singlePolicyHash);
            props.setSinglePolicyHash('');
        }else{
            if(hash === ''){
                if(props.mobileDevice){
                    updateActivityView("details");
                }else{
                    updateActivityView("timeline");
                }
            }else{
                if(!props.mobileDevice && hash === "#details"){
                    updateActivityView("timeline");
                }else{
                    updateActivityView(hash);
                }
            }
        }
    }, []);

    useEffect(() => {
        if(props.mobileDevice){
            if(hash === ''){
                updateActivityView("details");
            }else{
                updateActivityView(hash);
            }
        }else{
            if(props.singlePolicyHash !== ''){
                updateActivityView(props.singlePolicyHash);
                props.setSinglePolicyHash('');
            }else{
                if(hash === ''){
                    updateActivityView("timeline");
                }else{
                    if(!props.mobileDevice && hash === "#details"){
                        updateActivityView("timeline");
                    }else{
                        updateActivityView(hash);
                    }
                }
            }
        }
    }, [props.mobileDevice]);

   useEffect(() => {
        if(props.singlePolicyHash !== ''){
            updateActivityView(props.singlePolicyHash);
            props.setSinglePolicyHash('');
        }else{
            if(hash === ''){
                if(props.mobileDevice){
                    updateActivityView("details");
                }else{
                    updateActivityView("timeline");
                }
                props.changeActivityView("singlePolicy");
            }
        }
    }, [props.singlePolicyHash]);

    useEffect(() => {
        if(singlePolicyActivityView === "timeline"){
            updateReadStatus();
        }
    }, [singlePolicyActivityView]);

    useEffect(() => {
        if(props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.type){
            if(!currentUrl.includes("servicing")){
                setAccountType("origination");
            }

            if(currentUrl.includes("servicing")){
                setAccountType("fund");
                loadSinglePolicyPremiums()
            }
        }
    }, [props.sessionUser]);

    useEffect(() => {
        // console.log(policyData);
        if (policyData?.policyNumber !== '' && premiumScheduleData) {
            const graphData = {
                [policyData?.relatedFundID]: premiumScheduleData.map(data => ({...data}))
            };
            setPremiumChartData(graphData);
          }
    }, [premiumScheduleData, policyData]);

    return (
        <div className="policiesOverview">
            {showStatusDetail && Object.keys(policyTimelineData).length !== 0 && currentTimelinePost &&
                <div className="overlay f" onClick={(e)=>{handleClose(e)}}>
                    <TimelinePostDetails
                        data={currentTimelinePost}
                        firstVOCDate={firstVOCDate}
                        soldDate={policyData?.soldDate ? policyData?.soldDate : null}
                        setShowStatusDetail={setShowStatusDetail}
                        sessionVars={props.sessionVars}
                        session={props?.session}
                    />
                </div>
            }
            {showPremiumChart && premiumChartData !== null &&
                <div className="singlePolicyGraph">
                    <LineChart
                        graphData={premiumChartData}
                        policyNumber={props.policyNumber}
                        closeGraph={setShowPremiumChart}
                        singlePremium={true}
                        session={props?.session}
                    />
                </div>
            }
            <div className={"policiesDetails g " + policyType} >
                <div className="policiesDetailsBlock moduleBlock g">
                    <div className="singlePolicyBlockOptionBar g gR1 cC fR">
                        <div className="cL f">
                            <Link
                                to="/policies"
                                className="inlineNav g cC"
                                onClick={ () => {
                                    //props.changeActivityView("dashboard")
                                }}
                            >
                                <div className="goBackBtn">
                                    <div className="goBackBtnInner cC g f fR fC dP">
                                        <ArrowBackIosNewIcon/>
                                    </div>
                                </div>
                                <div className="inlineNavDirection">
                                    All Policies
                                </div>
                            </Link>
                        </div>
                        <div className="cR g fR fC f switchViewBtnContainerWrap">
                            {printTabs()}
                            {/* <div className="f cC gC2 s e p">
                                <div className="moreBtn cC fMP">
                                    <MoreHorizIcon/>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="singlePolicysDetailsBoardWrap gR2 g">
                        { props.mobileDevice ? null :
                        <div className="policyDetailsCard dP g dG">
                            <div className="policyDetailsCardTitle g cC fC dG">
                                <div className="overViewDataBlock g cC fC f dG">
                                    {currentUrl.includes("servicing") &&
                                        printSummaryStatement("fund")
                                    }
                                    {!currentUrl.includes("servicing") &&
                                        printSummaryStatement("broker")
                                    }
                                </div>
                                <div className="policyInfoBar g f cC">
                                    <div className="policyDetailsHolderTag">
                                        {displayHolderTag()}
                                    </div>
                                    {policyData?.qbLink && props?.sessionUser?.accountData[props?.sessionUser?.accountID]?.teamList?.find((teamMember) => teamMember.userID === props?.sessionUser?.userID)?.isAbacusEmployee &&
                                        <div className="f cL">
                                            <a className="cR link" target="_blank" rel="noopener noreferrer" href={policyData?.qbLink}>
                                                View on Quickbase
                                            </a>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="policyDetails">
                                <div className="profileDetails g dG">
                                    {loadPolicyHolderData()}
                                </div>
                            </div>
                        </div> }
                        <div className="currentActivity bR dP">
                            {currentViewActivity()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SinglePolicy;