import React, { useEffect, useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import TableRowsIcon from '@mui/icons-material/TableRows';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FileOpenIcon from '@mui/icons-material/FileOpen';

import Dropdown from '../Dropdown.js';

function ReportOptions(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const selectedReport = reporting?.data?.selectedReport;
    const updateReporting = reporting?.setData;
    const currentPreferences = reporting?.data?.specialColumns;
    const viewableReport = selectedReport?.query && selectedReport?.columns?.length > 0;

    const [dropdownHandler, setDropdownHandler] = useState({ showMenu : undefined });

    const [preferences, setPreferences] = useState({
        ...currentPreferences,
    });

    function downloadSelectedRowStatus(){
        if(preferences?.select && reporting?.data?.selectionState?.selectedRows?.length > 0){
            return true
        }

        return false;
    }

    function ToggleOption(key, label, Icon){
        return (
            <div className="option g dG cC">
                <div
                    className={`toggle s e p${preferences?.[key] ? " active cL" : " cR"}`}
                    onClick={() => updateReporting("specialColumns", key, !reporting?.data?.specialColumns?.[key])}
                >
                    <div className="status">
                    </div>
                </div>
                <div className="f cL g">
                    <div className="prompt f g dG">
                        <div className="f cC">
                            <Icon />
                        </div>
                        <div className="f cL bold">
                            {label}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const resetDropdowns = () => {
        if(dropdownHandler?.showMenu){
            resetShowMenu(setDropdownHandler);
        }
    };

    const resetShowMenu = (setDropdownState) => {
        setDropdownState((prev) => {
            if (prev?.showMenu) {
                return { ...prev, showMenu: false };
            }

            return prev;
        });
    };

    useEffect(() => {
        setPreferences(reporting?.data?.specialColumns);
    }, [reporting?.data?.specialColumns]);

    return (
        <div className="preferences g f dP nS tC fC" onClick={()=>{resetDropdowns()}}>
            <div className="downloads f g dG">
                <div className="header f cL bold">
                    Downloads
                </div>
                <div className="option g dG">
                    <div
                        className={`btnWIcon bold bR f s e cC ns${viewableReport && selectedReport?.list?.length ? " p" : " inactive"}`}
                        onClick={viewableReport && selectedReport?.list?.length ? ()=>{reporting?.functions?.downloadReport(session)} : null}
                    >
                        <div className="f cC lH">
                            Download
                        </div>
                        <DownloadIcon/>
                    </div>
                    <div className="f cC">
                        <ListAltIcon/>
                    </div>
                    <div className="f cL bold">
                        Report Sheet
                    </div>
                </div>
                <div className="option g dG">
                    <div
                        className={`btnWIcon bold bR f s e cC ns p${!downloadSelectedRowStatus() ? " inactive" : ''}`}
                        // onClick={() => downloadSelectedRowStatus() && session?.reporting?.functions?.downloadSelectedFiles(
                        //     session?.reporting?.data?.selectionState?.selectedRows, session
                        // )}
                    >
                        <div className="f cC lH">
                            Download
                        </div>
                        <DownloadIcon/>
                    </div>
                    <div className="f cC">
                        <FolderZipIcon/>
                    </div>
                    <div className="f cL bold">
                        {`Files of Selected Rows`}
                    </div>
                    <div className="f cR">
                        {`${preferences?.select ? " ( " + reporting?.data?.selectionState?.selectedRows?.length + " ) Rows Selected" : ''}`}
                    </div>
                </div>
                <div className="option g dG" onClick={()=>{
                    console.log(selectedReport?.subReport?.name, selectedReport?.referenceBranch !== "summary", selectedReport?.branch !== "summary")}}>
                    <Dropdown
                        key={`downloadFileType`}
                        setShowMenu={(input) => {
                            const newState = input ?? (dropdownHandler?.showMenu ? undefined : `downloadFileType`);
                            setDropdownHandler({
                                ...dropdownHandler,
                                showMenu: newState
                            });
                        }}
                        showMenu={dropdownHandler?.showMenu === `downloadFileType`}
                        default={reporting?.data?.downloadFileType ?? "XLSX"}
                        list={["XLSX", "CSV", "TXT"]}
                        onClick={(value) => {
                            updateReporting("downloadFileType", null, value);
                            window.localStorage.removeItem('downloadFileType');
                            window.localStorage.setItem('downloadFileType', value);
                        }}
                        reset={resetDropdowns}
                    />
                    <div className="f cC">
                        <FileOpenIcon/>
                    </div>
                    <div className="f cL bold">
                        Download File Type
                    </div>
                </div>
            </div>
            {selectedReport?.recordID && (selectedReport?.subReport?.name ? selectedReport?.referenceBranch !== "summary" : selectedReport?.branch !== "summary") &&
                <div className="toggles f g dG">
                    <div className="header f cL bold" onClick={()=>{console.log(
                        reporting?.data?.reportDataHandler?.[selectedReport?.stem],
                        selectedReport?.stem,
                    )}}>
                        Toggles
                    </div>
                    {ToggleOption("rowIndex" ,"Row Index", TableRowsIcon)}
                    {reporting?.data?.reportDataHandler?.[selectedReport?.subReport?.name ? selectedReport?.referenceStem : selectedReport?.stem]?.folderName
                        && ToggleOption("select" ,"Row Select", HighlightAltIcon)}
                    {!reporting?.data?.reportDataHandler?.[selectedReport?.subReport?.name ? selectedReport?.referenceStem : selectedReport?.stem]?.hasPath
                        && ToggleOption("view" ,"View Row", VisibilityIcon)}
                </div>
            }
        </div>
    )
};

export default ReportOptions;