import CloseIcon from '@mui/icons-material/Close';
import StatusIcon from '../../Components/StatusIcons';
import HtmlReactParser from 'html-react-parser';
import xss from 'xss';

import { timeStamp, convertIntToCurrency, dateFormat} from '../../envFunctions.js';

import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import ReplyIcon from '@mui/icons-material/Reply';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import { useState, useEffect } from 'react';

function TimelinePostDetails(props){
    let currentStatus = props.data?.status;
    const [loadingStatus, setLoadingStatus] = useState(false);
    //const [bidVerbiage, setBidVerbiage] = useState("Bid");

    if(props.data?.objectType === "Lead" && props.data?.status === "Lead"){
        currentStatus = "Initial Lead";
    }

    if(props.data?.taskType === "Shipping"){
        currentStatus = props.data?.taskShippingStatus;
        if(props.data?.taskShippingStatus === "Shipped"){
            currentStatus = "Shipped - Bid";
        }

        if(props.data?.taskShippingStatus === "Bidding"){
            if(props?.bidVerbiage === "Interest"){
                currentStatus = "Interested";
            }else{
                currentStatus = "Bidding";
            }
        }

        if(props.data?.taskShippingStatus === "Lost with bid"){
            currentStatus = "Lost with Bid";
        }

        if(props.data?.type === "Response - Request"){
            currentStatus = "Response - Request";
        }

        if(props.data?.type === "Response - Bid"){
            currentStatus = "Response - Bid";
        }
    }

    if(props.data?.taskShippingStatus === "Closing"){
        currentStatus = "Contracts";
    }

    const statusArray = {
        "On Hold" : "Processing and developments will be pausing until further notice.",
        "Pre-Priced" : "Preliminary stage where we internally estimate pricing and life expectancies.",
        "Lead" : "Policy was uploaded to the Marketplace.",
        "Initial Lead" : "This lead has been sent to your account to follow up with.",
        "Contact Attempted" : "Client was reached out to. Awaiting response.",
        "Failed to Contact" : "Client was unable to be reached after attempted contact.",
        "Client Not Interested" : "Client decided not to sell or continue working with us.",
        "Unqualified Closed" : "Policy has been deemed as unqualified to continue.",
        "Application Sent" : "An initial application has been sent out and awaiting its return.",
        "Meds/Illustration Ordered" : `Medical records, policy documents and illustrations have been ordered. Subsequent steps 
                                    follow when the requested documents have returned.`,
        "Underwriting (LE's)" : `The process of selecting risks for insurance and classifying them according
                                to their degrees of insurability so that the appropriate rates may be assigned.
                                The process also includes rejection of those risks that do not qualify.`,
        "Pricing / Valuation" : `Pricing is being decided on the basis of estimations, as well as, returned documents.`,
        "Shipped" : "Your policy information has been sent out to funders to find suitable interests.",
        "Shipped - Bid" : "This shipped policy has been presented to your party for further analysis and follow up.",
        "Bids" : "This is the period of which reviewing of offers and bids take place.",
        "Offer Letter Out / CRF" : "Offer Letter and Contract Request forms have been sent out and awaiting their signed return.",
        "Offer Letter In" : "Offer Letter and Contract Request forms have been returned.",
        "Contracts Out" : "Contracts and other documentation has been shipped out.",
        "Contracts Back (Digital)" : "Available digital contracts and documentation has been signed and returned.",
        "Contracts Back" : "Available physical contracts and documentation has been signed and returned.",
        "Escrow / At Carrier" : "Escrow funded; Waiting for carrier change forms to be processed.",
        "Rescission" : "This is the waiting period before everything gets finalized.",
        "Matured During Rescission" : "Policy has reached its maturity date and the insured party has passed away during a period of rescission.",
        "Invoicing" : "The buyer and seller have agreed on the terms of the sale and the seller has or plans to transfer ownership of the policy to the buyer.",
        "Closed Won" : "Congratulations! Everything has been completed.",
        "Closed Lost" : "Policy dismissed due to some underlying reason.",
        "Closed Lost to Competitor" : "Policy has been closed.",
        "Closed Lost with Bid" : "Policy has been closed. Any bid presented has not been accepted.",
        "Closed Lost no bid" : "No bid was provided and/or approved.",
        "Sent to 3rd Party" : "Policy has been sent to a 3rd party.",
        "Client Deceased" : "Client passed away prior to any finalization.",
        "Follow Up" : "A follow up reminder has been created with a designated date and time.",
        "Contact Attempted 1" : "An attempt of making contact to the client has not been successful.",
        "Contact Attempted 2" : "A 2nd attempt of making contact to the client has not been successful.",
        "Contact Attempted 3" : "A 3rd attempt of making contact to the client has not been successful.",
        "Application Received" : "The initial application has been received.",
        "Bidding" : "You've opted this case into the bidding stage by making a bid.",
        "Interested" : "You've opted this case into the Interested stage by providing an interested amount.",
        "Under Review" : "You've marked this shipped policy into the reviewing stage. At this time, you're notifying us that this policy is of potential interest.",
        "Does Not Fit Parameters" : "A notation that this shipped policy if of no interest due to not being aligned with your party's parameters.",
        "In Conflict" : "A notion that the policy was received from another source or this policy was received elsewhere prior to Abacus submission.",
        "No Bid" : "Update to close out this shipped with no presented bid.",
        "Lost with Bid" : "This shipped policy's bidding process has ended and the policy has been closed out. The client has decided on an alternative bid to the one your party has provided.",
        "Service Start" : "This policy is now being serviced by our staff. Timeline updates following this attached date are done so by the work of our employees.",
        "Sold" : "This policy has completed its sale to another entity and will no longer be provided with timeline updates.",
        "Matured" : "The related insured(s) to this policy are now deceased. Claim proceeds have been received.",
        "Lapsed" : "The state of the policy has been deemed unworthy to be further serviced from the party of ownership. The policy has lapsed, and further servicing has been halted.",
        "Owned" : "This policy is now apart of your party's fund. During this phase is where servicing by our staff takes place.",
        "Escrow" : "Waiting period for all needed and relative forms to be processed.",
        "Contracts" : "Contracts and document signing  are taking place to further the process of transitioning this policy into your portfolio."
    }

    const closingStatements = [
        "Lapsed",
        "Matured",
        "Sold"
    ];

    const [timelinePost, setTimelinePost] = useState(renderDetailBlock());
    const [postData, setPostData] = useState(props.data);
    const [readStatusButton, setReadStatusButton] = useState();

    useEffect(() => {
        setReadStatusButton(unreadButtonClass());
        //props.data.readStatus = false;
    }, [postData, loadingStatus]);

    useEffect(() => {
        //console.log(props);
        props.data.readStatus = true;
        setReadStatusButton(unreadButtonClass());
    }, []);

    // useEffect(() => {
    //     if(props?.sessionUser?.accountData[props?.sessionUser?.accountID]?.isNetworker){
    //         setBidVerbiage("Interest");
    //     }
    // }, [props?.sessionUser?.accountData[props?.sessionUser?.accountID]]);

    useEffect(() => {
        //console.log(props);
    }, []);

    function statusHandler(){
        if(props.data?.type === "Note"){
            if(props.data?.originator === "Customer" && props?.data?.objectType !== "Lead"){
                return "Transferred Note";
            }

            return "Note";
        }

        if(props.data?.objectType === "Case" && currentStatus === "Lead"){
            return "Sent to Marketplace"
        }

        if(currentStatus === "InitialLead"){
            return "Sent to Marketplace"
        }

        if(currentStatus === "Initial Lead"){
            return "Lead";
        }

        if(props.data?.objectType === "servicing"){
            if(props.data?.type === "Status"){
                return currentStatus;
            }

            return props.data?.type;
        }

        if(props.data?.objectType === "Lead"){
            if(props.data?.type === "Follow Up"){
                return "Follow Up";
            }
        }

        if(props.data?.taskType === "Shipping"){
            if(props.data?.type === "Response - Request"){
                return "Request Response";
            }

            if(props.data?.type === "Response - Bid"){
                return props?.bidVerbiage === "Interest" ? "Interest Response" : "Bid Response"
            }

            if(props.data?.type === "Bid"){
                return "Bid";
            }

            if(props.data?.type === "Request"){
                return "Requested Info";
            }

            if(props.data?.taskShippingStatus === "Lost with bid"){
                return "Lost with Bid";
            }

            if(props.data?.taskShippingStatus === "Bidding"){
                return currentStatus;
            }

            return props.data?.taskShippingStatus;
        }

        return currentStatus;

    }

    function contentHandler(){
        if(props.data?.type === "Note"){
            if(props.data?.taskType === "Shipping"){
                return props.data?.content;
            }
            return currentStatus;
        }

        if(props.data?.objectType === "Lead"){
            if(props.data?.type === "Follow Up"){
                return displayFollowUpDetails();
            }
        }

        if(props.data?.objectType === "servicing"){
            if(props.data?.type === "VOC"){
                return displayVOCDetails();
            }
            if(props.data?.type === "Payment"){
                return displayPaymentDetails();
            }
        }

        if(props.data?.taskType === "Shipping"){
            if(props.data?.type === "Bid"){
                return displayBidDetails();
            }

            if(props.data?.type === "Request"){
                return displayRequestDetails();
            }

            if(props?.data?.type === "Response - Request" || props?.data?.type === "Response - Bid"){
                return props.data?.content;
            }

            if(props.data?.taskShippingStatus === "Requested Info"){
                return props.data?.content;
            }

            return statusArray?.[currentStatus];
        }

        return statusArray?.[currentStatus];

    }

    function iconStatusHandler(){
        if(props.data?.type === "Note"){
            return <StatusIcon type={"Note"} originator={props.data?.originator} timelineType={props?.data?.objectType}/>
        }

        if(props.data?.objectType === "servicing"){
            if(props.data?.type === "Status"){
                return <StatusIcon currentStatus={currentStatus} originator={props.data?.originator}/>
            }
            return <StatusIcon type={props.data?.type} originator={props.data?.originator}/>
        }

        if(props.data?.taskType === "Shipping"){
            if(props.data?.type === "Bid"){
                if(props?.data?.checkStatus === "Completed"){
                    return <RecommendIcon sx={{color: "#43a047"}}/>
                }

                if(props?.data?.checkStatus === "Denied"){
                    return <ThumbDownIcon sx={{color: "#ef5350"}}/>
                }

                return <StatusIcon currentStatus={props.data?.taskShippingStatus} originator={"Customer"}/>
            }

            if(props.data?.type === "Request"){
                if(props?.data?.checkStatus === "Open"){
                    let parentColor;

                    if(props?.data?.taskShippingStatus === "Bidding"){
                        parentColor = "#6a1b9a";
                    }else{
                        parentColor = "#01579b";
                    }

                    return <PsychologyAltIcon sx={{color: parentColor}}/>

                }

                if(props?.data?.checkStatus === "Completed"){
                    return <FolderSharedIcon sx={{color: "#43a047"}}/>
                }
            }

            if(props.data?.type === "Response - Request"){
                return <TextSnippetIcon/>
            }

            if(props.data?.type === "Response - Bid"){
                return <TextSnippetIcon/>
            }

            if(props.data?.taskShippingStatus === "Shipped"){
                return <StatusIcon currentStatus="Shipped - Bid" originator={"Customer"}/>
            }

            return <StatusIcon currentStatus={currentStatus} originator={props.data?.originator}/>
        }

        if(props.data?.objectType === "Lead"){
            if(currentStatus === "Initial Lead"){
                return <StatusIcon currentStatus="Lead" originator={props.data?.originator}/>
            }

            if(props.data?.type === "Follow Up"){
                return <StatusIcon currentStatus="Follow Up" originator={props.data?.originator}/>
            }

            if(currentStatus === "Application Sent"){
                return <StatusIcon currentStatus="Application Sent - Lead" originator={props.data?.originator}/>
            }
        }


        return <StatusIcon currentStatus={currentStatus} originator={props.data?.originator}/>
    }

    function titleHandler(){
        if(props.data?.type === "Note"){
            return "Note Created";
        }

        if(props.data?.objectType === "Lead"){
            if(props.data?.type === "Follow Up"){
                return "Lead Alert";
            }
        }

        if(props.data?.taskType === "Shipping"){
            if(props.data?.type === "Bid" || props.data?.taskShippingStatus === "Requested Info"){
                return props?.bidVerbiage === "Interest" ? "Interest Alert" : "Bid Alert";
            }
        }

        if(props.data?.objectType === "servicing"){
            if(closingStatements.includes(props.data?.status)){
                return "Closing Statement";
            }

            if(props.data?.type === "VOC"){
                return "Verficiation";
            }

            if(props.data?.type === "Payment"){
                return "Payment Details";
            }
        }

        return "Status Update";
    }

    function timeStampHandler(){

        if(props.data?.objectType === "servicing"){
            if(props.data?.type === "VOC"){
                return props.data?.dateCreated;
            }

            if(props.data?.type === "Payment"){
                return props.data?.dateCreated;
            }
        }

        return props.data?.timeStamp;
    }

    function renderDetailBlock(){
        let postDetails = {};

        postDetails.type = titleHandler();
        postDetails.status = statusHandler();
        postDetails.content = contentHandler();
        postDetails.icon = iconStatusHandler();
        postDetails.timeStamp = timeStampHandler();

        return postDetails;
    }

    function displayVOCDetails(){
        return (
            <div className="vocDetails cL g f fC b">
                <div className="data g cL f fC">
                    <div className="cL f g">
                        <div className="cL e">
                            Cost of Insurance:
                        </div>
                        <div className="cL bold">
                            {convertIntToCurrency(props.data?.currentCOI)}
                        </div>
                    </div>
                    <div className="cL f g">
                        <div className="cL e">
                            Account Value:
                        </div>
                        <div className="cL bold">
                            {convertIntToCurrency(props.data?.accountValue)}
                        </div>
                    </div>
                    <div className="cL f g">
                        <div className="cL e">
                            Cash Surrender Value:
                        </div>
                        <div className="cL bold">
                            {convertIntToCurrency(props.data?.cashValue)}
                        </div>
                    </div>
                    {props.data?.loanAmount > 0 && 
                        <div className="cL f g">
                            <div className="cL e">
                                Loan Amount:
                            </div>
                            <div className="cL bold">
                                {convertIntToCurrency(props.data?.loanAmount)}
                            </div>
                        </div>
                    }
                    {props.data?.netDeathBenefit > 0 && 
                        <div className="cL f g">
                            <div className="cL e">
                                Net Death Benefit:
                            </div>
                            <div className="cL bold">
                                {convertIntToCurrency(props.data?.netDeathBenefit)}
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

    function displayPaymentDetails(){
        return (
            <div className="paymentDetails cL g f fC">
                <div className="data g cL">
                    <div className="cL f g">
                        <div className="cL e">
                            Amount:
                        </div>
                        <div className="cL bold">
                            {convertIntToCurrency(props.data?.amountPaid)}
                        </div>
                    </div>
                    <div className="cL f g">
                        <div className="cL e">
                            Policy:
                        </div>
                        <div className="cL bold">
                            {props.data?.policyNumber}
                        </div>
                    </div>
                    <div className="cL f g">
                        <div className="cL e">
                            Fund:
                        </div>
                        <div className="cL bold">
                            {props.data?.fundName}
                        </div>
                    </div>
                    <div className="cL f g">
                        <div className="cL e">
                            Insured:
                        </div>
                        <div className="cL bold">
                            {props.data?.insuredFullName}
                        </div>
                    </div>
                </div>
                <div className="appliedInfo g f cC">
                    <div className="cC">
                        Date Applied
                    </div>
                    <div className="cC">
                        {gatherPaymentInfo().paymentIcon}
                    </div>
                    <div className="cC">
                        {gatherPaymentInfo().paymentPrompt}
                    </div>
                </div>
            </div>
        );
    }

    function displayBidDetails(){
        let bidInfo = gatherBid();
        return (
            <div className="bidDetails f g">
                <div className="cL">
                    <div>
                        {props?.bidVerbiage === "Interest" ? "Interest Status:" : "Bid Status:" }
                    </div>
                    <div className="cL s bold">
                        {props?.data?.checkStatus === "Completed" && "Accepted"}
                        {props?.data?.checkStatus === "Open" && "Pending Review"}
                        {props?.data?.checkStatus === "Denied" && "Declined"}
                    </div>
                </div>
                <div className="cL">
                    <div>
                        Amount:
                    </div>
                    <div className="cL s bold">
                        {convertIntToCurrency(bidInfo.bidAmount)}
                    </div>
                </div>
                <div className="cL">
                    <div>
                        RDB:
                    </div>
                    <div className="cL s bold">
                        {convertIntToCurrency(bidInfo.bidRDB)}
                    </div>
                </div>
                <div className="tL t">
                    {"Note: " + bidInfo.bidNote}
                </div>
            </div>
        )
    }

    function gatherBid(){
        if(props.data?.type !== "Bid" && props.taskType !== "Shipping"){
            return false;
        }

        const bidData = props.data?.content.split(',');
        const bidAmount = bidData[0];
        const bidRDB = bidData[1];
        const bidNote = bidData.slice(2).join(',');

        return ({
            bidAmount : bidAmount,
            bidRDB : bidRDB,
            bidNote : bidNote
        });
    }

    function displayRequestDetails(){
        return props?.data?.content;
    }

    function gatherPaymentInfo(){

        if(props.data?.datePremiumApplied){
            return {
                paymentIcon : <PlaylistAddCheckCircleIcon/>,
                paymentPrompt : dateFormat(props.data?.datePremiumApplied)
            }
        }
        if(!props.data.datePremiumApplied && props.firstVOCDate > props.data?.dateCreated){
            return {
                paymentIcon : <LowPriorityIcon/>,
                paymentPrompt : "Prior to Servicing"
            }
        }
        if(!props.data?.datePremiumApplied && props.soldDate && props.firstVOCDate < props.data?.dateCreated){
            return {
                paymentIcon : <CheckCircleOutlineIcon/>,
                paymentPrompt : "Policy Closed"
            }
        }
        if(!props.data?.datePremiumApplied && props.soldDate && !props.firstVOCDate){
            return {
                paymentIcon : <CheckCircleOutlineIcon/>,
                paymentPrompt : "Policy Closed"
            }
        }
        
        if(props.data?.datePremiumApplied && props.soldDate && !props.firstVOCDate){
            return {
                paymentIcon : <CheckCircleOutlineIcon/>,
                paymentPrompt : "Policy Closed"
            }
        }
        if(!props.data?.datePremiumApplied && !props.soldDate && props.firstVOCDate < props.data?.dateCreated){
            return {
                paymentIcon : <VerifiedUserIcon/>,
                paymentPrompt : "Pending VOC"
            }
        }
        if(!props.data?.datePremiumApplied && !props.soldDate && !props.firstVOCDate){
            return {
                paymentIcon : <VerifiedUserIcon/>,
                paymentPrompt : "Pending VOC"
            }
        }

        return {
            paymentIcon : <VerifiedUserIcon/>,
            paymentPrompt : "Pending VOC"
        }
    }

    function displayFollowUpDetails(){
        return (
            <div className="followUpDetails fC g">
                <div className="cL">
                {props.data?.originator === "internal" && "Customer created Follow Up"}
                {props.data?.originator === "external" && "Follow Up created by you"}
                </div>
                <div className="tL fC">
                    { !props.data?.status &&
                        "This Follow Up was created without any notes attached."
                    }
                    {props.data?.status}
                </div>
            </div>
        )
    }

    function markUnread(){
        let requestLink, key, keyValue;
        setLoadingStatus(true);

        if(props.data?.objectType === "Case"){
            requestLink = "updateReadStatus";
            key = "activityID";
            keyValue = props.data?.recordID;
        }

        if(props.data?.objectType === "Lead"){
            requestLink = "updateReadStatus";
            key = "activityID";
            keyValue = props.data?.recordID;
        }

        if(props.data?.objectType === "servicing"){
            requestLink = "servicing/updateReadStatus";
            key = "activityID";
            keyValue = props.data?.recordID;
        }

        if(props.data?.objectType === "marketplace"){
            requestLink = "marketplace/updateReadStatus";
            key = "activityID";
            keyValue = props.data?.recordID;
        }

        const paramVals = {
            "type" : "single",
            [key] : keyValue,
            "unread" : true,
        };

        props.data.readStatus = false;

        props.session?.env?.functions?.buildFetchRequest(requestLink, paramVals)
        .then(response => response.json())
        .then(resData => {
            if(resData.status === 200){
                setPostData((prevState) => ({
                    ...prevState,
                    readStatus: false
                }));

                if(props?.updateKanbanAlert){
                    let updateCount = 0;
                    if(props?.data?.type === "Response - Request"){
                        updateCount++;
                        props?.updateKanbanAlert("numOfUnreadRequestResponses");
                    }
                    if(props?.data?.type === "Response - Bid"){
                        updateCount++;
                        props?.updateKanbanAlert("numOfUnreadBidResponses");
                    }
                    
                    if(updateCount === 0){
                        props?.updateKanbanAlert("unread");
                    }
                }    
                //props.loadNotifications();
            }
            setLoadingStatus(false);

        },
        (error) => {
            if (error) {
                console.log(error);
            }
            }).catch((error) => {
            console.log(error);
        });
    }

    function unreadButtonClass(){
        if(loadingStatus){
            return (
                <div className="btn default loading cC f bR gC3 s e">
                    <CircularProgress/>
                </div>
            )
        }

        if(postData?.originator === "Customer" || postData?.type === "VOC" || postData?.type === "Payment" || postData?.objectType === "Lead"){
            return null;
        }

        if(postData?.readStatus === false){
            return (
                <div className="btn default inactive cC f bR gC3 s e p oH">
                    <MarkunreadMailboxIcon/>
                    <div className="s gCW">
                        Mark as Unread
                    </div>
                </div>
            )
        }

        if(postData?.originator === "Internal"){
            return(
                <div className="btn default cC f bR gC3 s e p oH" onClick={()=>{markUnread(postData)}}>
                    <MarkunreadMailboxIcon/>
                    <div className="s gCW">
                        Mark as Unread
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="timelinePostDetails moduleBlock g bR">
            <div className="header g cC fR ns">
                <div className="cC gC2">
                    {timelinePost.type}
                </div>
                <div className="btn close cC p" onClick={()=> {props.setShowStatusDetail(false)}}>
                    <CloseIcon/>
                </div>
            </div>
            <div className="body f g cC fR">
                <div className={gatherBid() ? "iconDisplay bold alt g f fR s e" : "iconDisplay bold g f fR s e"}>
                    <div className={gatherBid() ? "bC" : "cC"}>
                        {gatherBid() ?
                         props?.bidVerbiage === "Interest" ?
                            "Interested Offer"
                            :
                            timelinePost.status
                            :
                            timelinePost.status
                        }
                    </div>
                    <div className="divider">
                    </div>
                    <div className="icon f cC">
                        {timelinePost.icon}
                    </div>
                </div>
                <div className="content g f s b e">
                    <div className="details cR f g s">
                        {props.data?.nameOfSender && 
                            <div className="cR f">
                                <div className="cR">
                                    From:
                                </div>
                                <div className="s cR bold">
                                    {props.data?.nameOfSender}
                                </div>
                            </div>
                        }
                        <div>
                            {new Date(timelinePost.timeStamp).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}
                        </div>
                        <div>
                            {new Date(timelinePost.timeStamp).toLocaleTimeString("en-US")}
                        </div>
                    </div>
                    <div className="tL fC">
                        {timelinePost.content}
                    </div>
                    <div className="actionBar g cR fR ns">
                        {readStatusButton}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TimelinePostDetails;