import React from 'react';
import { useState, useEffect, useRef } from 'react';

import KanbanFilters from '../Components/Kanban/KanbanFilters';
import KanbanBlock from '../Components/Kanban/KanbanBlock';
import BidsListAll from '../BidsDashboard/BidsListAll';
import BidsKanbanHeader from './BidsKanbanHeader';

import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import ListAltIcon from '@mui/icons-material/ListAlt';
import InboxIcon from '@mui/icons-material/Inbox';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import CampaignIcon from '@mui/icons-material/Campaign';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CircularProgress from '@mui/material/CircularProgress';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ReorderIcon from '@mui/icons-material/Reorder';

import { convertIntToCurrency, toggleFilters } from '../envFunctions.js';

function BidCenter(props){
    const session = props?.session;
    const bidsData = session?.bids?.data;
    const setBidsData = session?.bids?.setData;
    const kanbanColumnStage1 = props.bidProps?.columnHandler?.columnRefs?.stage1;
    const kanbanColumnStage2 = props.bidProps?.columnHandler?.columnRefs?.stage2;
    const kanbanColumnStage3 = props.bidProps?.columnHandler?.columnRefs?.stage3;
    const [bidVerbiage, setBidVerbiage] = useState("Bid");
    const [showFilters, setShowFilters] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);

    function handleScroll(event, kanbanColumn) {
        const element = event.target;
        const { bidProps } = props;

        if (kanbanColumn.startsWith("stage") && bidProps?.setYScrollBidKanban) {
          bidProps.setYScrollBidKanban(prevState => ({
            ...prevState,
            [kanbanColumn]: element.scrollTop
          }));
        }

        const stageCount = bidProps?.bidBlockKanbanData?.[kanbanColumn + "Count"];
        const stageVisibleThreshold = bidProps?.["bidsKanban" + kanbanColumn[0].toUpperCase() + kanbanColumn.slice(1) + "VisibleThreshold"];

        if (stageVisibleThreshold > stageCount || element.scrollTop === 0) {
            return;
        }

        const isScrollHeightDouble = element.scrollHeight >= 2 * element.offsetHeight;

        if (element.scrollHeight - element.scrollTop <= element.clientHeight + 100 && isScrollHeightDouble) {
          bidProps?.[`setBidsKanbanColumn${kanbanColumn[0].toUpperCase() + kanbanColumn.slice(1)}VisibleThreshold`](
            bidProps[`bidsKanbanColumn${kanbanColumn[0].toUpperCase() + kanbanColumn.slice(1)}VisibleThreshold`] + 48
          );
        }
    }

    function currentActivityView(view){
        if(view === props.bidProps?.activityView){
            return " selected";
        }

        return '';
    }

    function notificationStatus(branch, notification, stage){
        if(props?.sessionVars?.envVars?.filterHandler?.[branch]?.[notification]?.[stage]){
            return " active";
        }

        return '';
    }

    function renderCategoryTopBar(category, label, count, totalBids, total) {
        const isActive = props.bidProps?.currentColumnCheck(category) !== " inactive";
        const isSelected = isActive && !props.mobileDevice;
        const sortValue = props.bidProps.columnHandler.sort.kanban[category];
        const altSort = sortValue === "reverse" ? "default" : "reverse";
        let totalVar = category;
        if(category === "closed"){
            totalVar = "completed";
        }

        const handleSortToggle = () => {
          const updatedSortConfig = {
            ...props.bidProps?.columnHandler.sort.kanban,
            [category]: altSort
          };
          const updatedColumnHandler = {
            ...props.bidProps?.columnHandler,
            sort: {
              ...props.bidProps?.columnHandler.sort,
              kanban: updatedSortConfig
            }
          };

          props.bidProps?.setColumnHandler(updatedColumnHandler);
        };

        return (
          <div className={`category ${category} s${isActive ? " selected" : ""}${isSelected ? "" : " inactive"}`}>
            <div className="cL f oH">
              <div className="gCW">{count ? `${count} ${label}` : `0 ${label}`}</div>
            </div>
            <div className={category === "closed" ? "columnAttr g e" : "columnAttr g"}>
                {props?.bidProps?.bidBlockKanbanData?.[totalVar + "TotalBids"] > 0 &&
                    <div className="total bids cR f">
                        <div className="cR e s bR">{convertIntToCurrency(totalBids, true)}</div>
                    </div>
                }
                {total > 0 &&
                    <div className="total db cR f gC2">
                        <div className="cR e s bR">{convertIntToCurrency(total, true)}</div>
                    </div>
                }
            </div>
            {category !== "closed" && (
                <div className="f cC p gC3" onClick={handleSortToggle}>
                    {sortValue === "default" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                </div>
            )}
          </div>
        );
    }

    function renderClosedColumn(){
        const closedBlocks = [
            {
                stage: "won",
                completedData: props.bidProps?.bidBlockKanbanData,
                closedCount: props.bidProps?.bidBlockKanbanData?.wonCount,
                setActivityView: props.bidProps?.setActivityView,
                totalDeathBenefit: props.bidProps?.bidBlockKanbanData?.wonTotal,
                changeActivityView: props.bidProps?.changeActivityView,
                kanbanBlockType: "closed",
                closedType: "WON",
                onClick: props.bidProps?.bidsCategoryToggle,
                type : "bid",
                totalBidValue : props.bidProps?.bidBlockKanbanData?.wonTotalBids,
                sessionUser : props?.sessionUser
            },
            {
                stage: "closing",
                completedData: props.bidProps?.bidBlockKanbanData,
                closedCount: props.bidProps?.bidBlockKanbanData?.closingCount,
                setActivityView: props.bidProps?.setActivityView,
                totalDeathBenefit: props.bidProps?.bidBlockKanbanData?.closingTotal,
                changeActivityView: props.bidProps?.changeActivityView,
                kanbanBlockType: "closed",
                closedType: "CONTRACTS",
                onClick: props.bidProps?.bidsCategoryToggle,
                type : "bid",
                totalBidValue: props.bidProps?.bidBlockKanbanData?.closingTotalBids,
                sessionUser : props?.sessionUser
            },
            {
                stage: "closed",
                completedData: props.bidProps?.bidBlockKanbanData,
                setActivityView: props.bidProps?.setActivityView,
                changeActivityView: props.bidProps?.changeActivityView,
                kanbanBlockType: "closed",
                closedType: "CLOSED",
                onClick: props.bidProps?.bidsCategoryToggle,
                type : "bid",
                totalBidValue: props.bidProps?.bidBlockKanbanData?.closedTotalBids,
                sessionUser : props?.sessionUser
            },
          ];

          return (
            <>
              {(!props.mobileDevice || props.bidProps?.currentColumnCheck("closed") === " selected") && (
                <div className="column closed">
                  {closedBlocks.map((block, index) => (
                    <KanbanBlock key={`closedBlock-${index}`} {...block} />
                  ))}
                </div>
              )}
            </>
          );
    }

    function renderKanbanColumn(visibleCount, columnKey, categoryList, emptyMessage) {
        const isSelected = !props.mobileDevice || (props.mobileDevice && props.bidProps?.currentColumnCheck(columnKey) === " selected");
        const kanbanColumnRefs = {
            stage1 : kanbanColumnStage1,
            stage2 : kanbanColumnStage2,
            stage3 : kanbanColumnStage3
        };
        const keyNumbers = Object.keys(kanbanColumnRefs);
        const keyIndex = keyNumbers.indexOf(columnKey);
        const keyNumber = keyIndex !== -1 ? keyIndex + 1 : null;

        return (
          <div
            className={`${visibleCount >= 3 ? "column stage" + keyNumber : "column alt stage" + keyNumber}${props.bidProps?.currentColumnCheck(columnKey)}`}
            ref={kanbanColumnRefs[columnKey]}
            onScroll={(evt) => handleScroll(evt, columnKey)}
          >
            {isSelected && (
              categoryList.length === 0 ? (
                <div className="emptyKanbanCategory ns">
                  <div className="emptyKanbanMessage">
                    {emptyMessage}
                  </div>
                </div>
              ) : (
                categoryList
              )
            )}
          </div>
        );
    }

    function handleClose(evt) {
        const className = evt?.target?.className?.toString();
        if (className && className.includes("overlay")) {
          setShowFilters(false);
        }
    }

    function filterBubbles(branch, stem){
        const filters = props?.sessionVars?.envVars?.filterHandler?.[branch]?.[stem];
        const filterFunctions = props?.sessionVars?.envVars?.filterHandler?.functions;
        let returningBubbles = [];
        let size = "count";
        let count = 0;

        function printPrompt(currentFilter, key){
            if(filterFunctions.groupData(key).type === "range"){
                if( filterFunctions.groupData(key).intType === "currency"){
                    return filterFunctions.groupData(key).prompt + " " + convertIntToCurrency(currentFilter.minVal, true) + " - " + convertIntToCurrency(currentFilter.maxVal, true)
                }
                if( filterFunctions.groupData(key).intType === "number"){
                    return filterFunctions.groupData(key).prompt + " " + currentFilter.minVal + " - " + currentFilter.maxVal
                }
            }

            return filterFunctions.groupData(key).prompt;
        }

        Object.keys(filters).forEach(key => {
            const currentFilter = filters[key];
            if(currentFilter.type === "binary"){
                if(currentFilter.activeCount !== currentFilter.maxCount){
                    count++;
                    returningBubbles.push(
                        <div className="filter cC p oH" onClick={()=>{props?.sessionVars?.envVars?.filterHandler?.functions?.resetGroup(branch, stem, key)}}>
                            <div className="cC gCW">
                                {printPrompt(currentFilter, key)}
                            </div>
                            <HighlightOffIcon/>
                        </div>
                    );
                }
            }

            if(currentFilter.type === "range"){
                if (!(currentFilter.minVal === currentFilter.fixedMinVal && currentFilter.maxVal === currentFilter.fixedMaxVal) && currentFilter.maxVal !== null) {
                    count++;
                    returningBubbles.push(
                        <div className="filter cC p oH" onClick={()=>{props?.sessionVars?.envVars?.filterHandler?.functions?.resetGroup(branch, stem, key)}}>
                            <div className="cC gCW">
                                {printPrompt(currentFilter, key)}
                            </div>
                            <HighlightOffIcon/>
                        </div>
                    );
                }
            }
        });

        return (
            <div className={"filterBubbles " + size + count}>
                {returningBubbles}
            </div>
        )
    }

    function downloadBidDatatape(){
        setLoadingStatus(true);
        const today = new Date();
        const dateData = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = today.toLocaleDateString('en-US', dateData);

        const paramVals = {
            accountID : props?.sessionUser?.accountID
        }

        props.session?.env?.functions?.buildFetchRequest("marketplace/downloadDataTape", paramVals).then((data) => {
            setLoadingStatus(false);
            return data.blob();
        })
        .then((blob) => {
            const objUrl = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = objUrl;
            link.download = "Policy Datatape " + formattedDate + ".xlsx";
            link.click();
        }); 
    }

    useEffect(() => {
        if(props?.sessionUser?.accountData?.[props?.sessionUser?.accountID]?.isNetworker){
            setBidVerbiage("Interest");
        }
    }, [props?.sessionUser?.accountData?.[props?.sessionUser?.accountID]]);

    useEffect(() => {
        if(props.bidProps.activityView === "kanban" && props.bidProps?.bidBlockKanbanData){
            kanbanColumnStage1.current.scrollTop = props.bidProps?.yScrollBidKanban?.stage1;
            kanbanColumnStage2.current.scrollTop = props.bidProps?.yScrollBidKanban?.stage2;
            kanbanColumnStage3.current.scrollTop = props.bidProps?.yScrollBidKanban?.stage3;
        }
    }, [props.bidProps?.activityView]);

    useEffect(() => {
        if(props.bidProps?.activityView === "kanban" && props.bidProps?.bidBlockKanbanData){
            if(props.bidProps?.yScrollBidKanban?.stage1 === 0 && kanbanColumnStage1?.current?.scrollTop !== 0){
                kanbanColumnStage1.current.scrollTop = 0;
                props.bidProps?.setBidsKanbanColumnStage1VisibleThreshold(24);
            }

            if(props.bidProps?.yScrollBidKanban?.stage2 === 0 && kanbanColumnStage2?.current?.scrollTop !== 0){
                kanbanColumnStage2.current.scrollTop = 0;
                props.bidProps?.setBidsKanbanColumnStage2VisibleThreshold(24);
            }

            if(props.bidProps?.yScrollBidKanban?.stage3 === 0 && kanbanColumnStage3?.current?.scrollTop !== 0){
                kanbanColumnStage3.current.scrollTop = 0;
                props.bidProps?.setBidsKanbanColumnStage3VisibleThreshold(24);
            }
        }
    }, [props.bidProps?.yScrollBidKanban]);

    useEffect(() => {
        props.bidProps?.printBidsKanbanBoard();
        props.bidProps?.printBidsListView();
    }, [props.bidProps?.bidsList,
        props.bidProps?.showBidOptions,
        props.bidProps?.listAllBidsVisibleThreshold,
        props.bidProps?.bidsKanbanColumnStage1VisibleThreshold,
        props.bidProps?.bidsKanbanColumnStage2VisibleThreshold,
        props.bidProps?.bidsKanbanColumnStage3VisibleThreshold]);

    useEffect(() => {
        //console.log(props.bidProps);
    }, []);

    return (
        <div className={props.bidProps?.bidBlockKanbanData ? "bidsOverview kanbanPage moduleBlock g cC fC" : "bidsOverview kanbanPage moduleBlock g cC fC loading"}>
            { showFilters &&
                <div className="overlay cC" onClick={(e)=>{handleClose(e)}}>
                    <KanbanFilters
                        setShowFilters={setShowFilters}
                        handleClose={handleClose}
                        filterBranchStem={props?.sessionVars?.envVars?.filterHandler?.["bids"]?.["kanban"]}
                        branch="bids"
                        stem="kanban"
                        sessionUser={props.sessionUser}
                        sessionVars={props.sessionVars}
                        bidProps={props.bidProps}
                        kanbanProps={props.bidProps?.bidBlockKanbanData}
                        session={props?.session}
                    />
                </div>
            }
            {!props.bidProps?.bidBlockKanbanData &&
                <div className="f cC loading">
                    <div className="loader cC g">
                        <div className="cC g fR">
                            <div className="icon cC">
                                <InboxIcon/>
                            </div>
                            <div className="icon cC">
                                <FlagCircleIcon/>
                            </div>
                            <div className="icon cC">
                                <CampaignIcon/>
                            </div>
                        </div>
                        <div className="cC">
                            Loading Case Data
                        </div>
                    </div>
                </div>
            }
            {props.bidProps?.bidBlockKanbanData &&
                <div className="actionBar f g ns">
                    <div className="navOptions cR g fR fC f">
                        <div className={"switchViewBtn f g cC s e" + currentActivityView("kanban")}
                            onClick={()=>{props.bidProps?.changeActivityView("kanban")}}>
                            <div className="switchViewBtnOption">
                                Flow Chart
                            </div>
                            <div className="inlineBtnIcon">
                                <ViewKanbanIcon/>
                            </div>
                        </div>
                        <div className={"switchViewBtn f g cC s e" + currentActivityView("list")}
                            onClick={()=>{props.bidProps?.changeActivityView("list")}}>
                            <div className="switchViewBtnOption">
                                List All
                            </div>
                            <div className="inlineBtnIcon"><ListAltIcon/></div>
                        </div>
                    </div>
                    <div className="searchBar g cC">
                        <div className="f cC">
                            <div className="btnWIcon bold bR f cC p ns" onClick={()=>{props?.sessionVars?.envVars?.filterHandler?.functions?.updateFilterAttr(["assigned", "all"], "bids", null, null, "viewType")}}>
                                <div className="f cC">
                                    {props?.sessionVars?.envVars?.filterHandler?.bids?.viewType === "assigned" ? "My Cases": "All Cases"}
                                </div>
                                <ArrowDropDownIcon/>
                            </div>
                        </div>
                        <div className="inputField">
                            <input
                                className="bidsSearchBar"
                                placeholder="Search by Insured Name or Policy Number"
                                onChange={(evt) => {props.bidProps?.updateBidSearchVal(evt)}}
                                value={props.bidProps?.bidsSearchVal}
                            />
                        </div>
                        {filterBubbles("bids", "kanban")}
                    </div>
                    { props.bidProps?.activityView === "kanban" && 
                        <div className="filterOptions cC g fR fC f">
                            {props?.sessionUser?.accountID === 25 &&
                                <div className={`btnWIcon${loadingStatus ? " progress" : ''} bold bR fMP cC p ns`} onClick={loadingStatus ? null : ()=>{ downloadBidDatatape()}}>
                                    {loadingStatus ?
                                        <CircularProgress/>
                                    :
                                        "Download"
                                    }
                                    
                                </div>
                            }
                            <div className="btnWIcon bold bR fMP cC p ns gC2" onClick={()=>{setShowFilters(true)}}>
                                <div className="f cC">
                                    Filters
                                </div>
                                <FilterAltIcon/>
                            </div>
                        </div>
                    }
                    { props.bidProps?.activityView === "list" &&
                        <div className="filterOptions cC g fR fC f">
                            <div
                                className="btnWIcon bold bR fMP cC p ns gC2 e"
                                onClick={()=>{setBidsData("list", "listType", bidsData?.list?.listType === "list" ? "block" : "list")}}
                            >
                                <div className="f cC">
                                    {bidsData?.list?.listType === "list" ? "List View" : "Card View"}
                                </div>
                                {bidsData?.list?.listType === "list" ? <ReorderIcon/> : <ViewCompactIcon/>}
                            </div>
                        </div>
                    }
                </div>
            }
            {props.bidProps?.activityView === "kanban" && props.bidProps?.bidBlockKanbanData && (
                <div className="kanbanBoard cC g f fC s e b">
                    <BidsKanbanHeader
                        notificationStatus={notificationStatus}
                        bidBlockKanbanData={props.bidProps?.bidBlockKanbanData}
                        bidProps={props.bidProps}
                        sessionVars={props?.sessionVars}
                        bidVerbiage={bidVerbiage}
                    />
                    <div className="categoryBar g cC fR">
                        {renderCategoryTopBar("stage1", "New Case(s)", props.bidProps?.bidBlockKanbanData?.stage1Count, props.bidProps?.bidBlockKanbanData?.stage1TotalBids, props.bidProps?.bidBlockKanbanData?.stage1Total)}
                        {renderCategoryTopBar("stage2", "Case(s) Under Review", props.bidProps?.bidBlockKanbanData?.stage2Count, props.bidProps?.bidBlockKanbanData?.stage2TotalBids, props.bidProps?.bidBlockKanbanData?.stage2Total)}
                        {renderCategoryTopBar("stage3", bidVerbiage === "Interest" ? "Current Interest(s)" : "Current Bid(s)", props.bidProps?.bidBlockKanbanData?.stage3Count, props.bidProps?.bidBlockKanbanData?.stage3TotalBids, props.bidProps?.bidBlockKanbanData?.stage3Total)}
                        {renderCategoryTopBar("closed", "Completed", props.bidProps?.bidBlockKanbanData?.completedCount, props.bidProps?.bidBlockKanbanData?.completedTotalBids, props.bidProps?.bidBlockKanbanData?.completedTotal )}
                    </div>
                    <div className="kanbanChart f">
                        {renderKanbanColumn(props.bidProps?.bidBlockKanbanData.stage1Visible, "stage1", props.bidProps?.bidsCategoryStage1List, "No new cases")}
                        {renderKanbanColumn(props.bidProps?.bidBlockKanbanData.stage2Visible, "stage2", props.bidProps?.bidsCategoryStage2List, "No cases under review")}
                        {renderKanbanColumn(props.bidProps?.bidBlockKanbanData.stage3Visible, "stage3", props.bidProps?.bidsCategoryStage3List, "No current bids")}
                        {renderClosedColumn()}
                    </div>
                </div>
            )}
            { props.bidProps?.activityView === "list" && props.bidProps?.bidBlockKanbanData &&
                <BidsListAll
                    rangeData = {props.bidProps?.leadsListPriceRange}
                    functionPointer={props.bidProps?.updateLeadsListPriceRange}
                    fixedMin={props.bidProps?.leadsListPriceRange?.fixedMinVal}
                    fixedMax={props.bidProps?.leadsListPriceRange?.fixedMaxVal}
                    setListToggles={props.bidProps?.setListBidToggles}
                    printBidsListView={props.bidProps?.printBidsListView}
                    listToggles={props.bidProps?.listBidToggles}
                    bidBlockListData={props.bidProps?.bidBlockListData}
                    viewAllList={props.bidProps?.viewAllBidsList}
                    listAllVisibleThreshold={props.bidProps?.listAllBidsVisibleThreshold}
                    setListAllVisibleThreshold={props.bidProps?.setListAllBidsVisibleThreshold}
                    leadsCategoryToggle={props.bidProps?.leadsCategoryToggle}
                    sessionUser={props.sessionUser}
                    bidProps={props.bidProps}
                    session={props?.session}
                />
            }
        </div>
    )
}

export default BidCenter;