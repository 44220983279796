import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LastPageIcon from '@mui/icons-material/LastPage';
import EditIcon from '@mui/icons-material/Edit';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import PublicIcon from '@mui/icons-material/Public';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';
import ShieldTwoToneIcon from '@mui/icons-material/ShieldTwoTone';
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import RingVolumeTwoToneIcon from '@mui/icons-material/RingVolumeTwoTone';

import InputField from "../Components/InputField.js";

import { CircularProgress } from '@mui/material';

function Reporting(props){
    // let requestingBatch = false;
    const [requestingBatch, setRequestingBatch] = useState(undefined);
    const [search, setSearch] = useState({
        searching : undefined,
        searchValue : ''
    });

    const [existingEdits, setExistingEdits] = useState(false);
    const [listResults, setListResults] = useState([]);
    const [sortedListResults, setSortedListResults] = useState();

    const reportTable = useRef(null);
    const columnContainerRef = useRef(null);
    const [columnWidth, setColumnWidth] = useState(null);
    const [isScrollableX, setIsScrollableX] = useState(false);

    const session = props?.session;
    const envFunctions = props.session.env.functions;
    const analytics = session?.analytics;
    const allReports = props?.session?.analytics?.data?.allReports;
    const selectedReport = analytics?.data?.selectedReport;
    const updateAnalytics = session?.analytics?.setData;
    const viewableReport = selectedReport?.query && selectedReport?.columns?.length > 0;
    const newReportTemplate = analytics?.data?.reset;
    const hasBeenGenerated = session?.case?.data?.[selectedReport?.branch]?.[selectedReport?.stem]?.[0];
    const selectedReportStem = selectedReport?.stem;
    const userOpportunityTypes = session?.user?.data?.opportunityTypes;
    const initialReportCategory = selectedReportStem || (userOpportunityTypes && userOpportunityTypes[0]) || "deceased";
    const attributeData = props.session.case.data.attributeData;

    const [reportCategory, setReportCategory] = useState(initialReportCategory);
    const [existingColumnsCopy, setExistingColumnsCopy] = useState(selectedReport?.columns);
    // const [lastPageIndex, setLastPageIndex] = useState(1);

    const numCols = selectedReport?.columns?.length || 0; // Get the number of columns
    const headerColumns = {
        gridTemplateColumns: `repeat(${selectedReport?.columns?.length ?? 0}, 1fr)`,
        zIndex : selectedReport?.columns?.length + 1 // Set the number of columns dynamically
    };
    const rowColumns = {
        gridTemplateColumns: `repeat(${numCols}, 1fr)`, // Set the number of columns dynamically
    };

    const [infiniteScrollMax, setInfiniteScrollMax] = useState(100);
    const [pagination, setPagination] = useState({
        showMenu : false,
        rowMax : session?.storage?.reporting?.rowMax ?? 100,
        index : selectedReport?.lastPageIndex ?? 1,
    });

    const reportData = {
        "repeating" : {
            icon : <EventRepeatIcon/>,
            branchType : "repeat",
            prompt : "Repeating",
            stem : "repeat"
        },
        "origination" : {
            icon : <ShieldTwoToneIcon/>,
            branchType : "policies",
            prompt : "Origination Policies",
            stem : "origination"
        },
        "servicing" : {
            icon : <SecurityTwoToneIcon/>,
            branchType : "policies",
            prompt : "Serviced Policies",
            stem : "servicing"
        },
        "bids" : {
            icon : <StorefrontTwoToneIcon/>,
            branchType : "bids",
            prompt : "Bids",
            stem : "bids"
        },
        "lead" : {
            icon : <RingVolumeTwoToneIcon/>,
            branchType : "leads",
            prompt : "Leads",
            stem : "leads"
        },
    }

    function calculateColumnWidth(){
        const measureColumnWidth = () => {
            if (columnContainerRef.current) {
              const gridColumnGap = parseFloat(
                getComputedStyle(columnContainerRef.current)?.gridColumnGap
              );
              const gridContainerWidth = columnContainerRef.current.clientWidth;
      
              const numColumns = selectedReport?.columns?.length || 0; // Number of columns
              const columnWidthInPixels =
              (gridContainerWidth - (numColumns - 1) + 16) / numColumns;
              setColumnWidth(columnWidthInPixels);
            }
          };
      
          // Initial measurement
          measureColumnWidth();
      
          // Recalculate when the window is resized
          window.addEventListener('resize', measureColumnWidth);
      
          // Cleanup
          return () => {
            window.removeEventListener('resize', measureColumnWidth);
          };
    }

    function requestResultBlock(request){
        if (requestingBatch) {
            return;
        }

        const paramVals = {
            queryArray : analytics?.functions?.parse(request?.newQuery ?? selectedReport?.query) ?? undefined,
            offset : request?.newOffset ?? pagination?.index,
            limit : request?.newLimit ?? pagination?.rowMax,
            columns : request?.newColumns ?? selectedReport?.columns,
            sorting : request?.newSorting ?? analytics?.data?.sorting,
        };

        // if(JSON.stringify(selectedReport?.lastQuery) !== JSON.stringify(paramVals) || selectedReport?.lastPageIndex !== pagination?.index){
        setRequestingBatch(true);

            // props?.session?.analytics?.functions?.updateSelectedReport("lastQuery", paramVals);

        props?.session?.env?.functions?.buildFetchRequest("/individual/getMany", paramVals)
        .then(response => response.json())
        .then(resData => {
            if(resData.status === 200){
                props?.session?.analytics?.functions?.updateSelectedReport("list", resData?.case);
                props?.session?.analytics?.functions?.updateSelectedReport("totalFound", resData?.totalFound);
                setExistingColumnsCopy(request?.newColumns ?? selectedReport?.columns);
                props?.session?.analytics?.functions?.updateSelectedReport("lastPageIndex", pagination?.index);
            }
            setRequestingBatch(false);
        });
        // }
    }

    function printColumnHeaders(){
        let stickyCount = 0;
        let columnHeaders = [];
        let columnCount = selectedReport?.columns?.length || 0;

        const stickyPosition = (frozen) => {
            if(!frozen){
                return;
            }

            return { left: `${(stickyCount - 1) * columnWidth - (stickyCount > 1 && (stickyCount - 1))}px`, zIndex: columnCount}
        }

        if(selectedReport?.columns){
            //onst sorting = analytics?.data?.sorting;
            const sorting = analytics?.data?.sorting;

            function sortingSize() {
                let count = 0;

                for (const key in sorting) {
                    if (sorting?.hasOwnProperty(key) && sorting?.[key] !== undefined) {
                        count++;
                    }
                }

                return count;
            }

            selectedReport?.columns?.forEach((column, index) => {
                const frozen = column?.frozen;
                const currentIndex = sorting?.findIndex(obj => obj.attr === column?.columnName);
                const ascending = sorting?.[currentIndex]?.type === "asc";
                const descending = sorting?.[currentIndex]?.type === "desc";

                if (frozen){
                    stickyCount++;
                    columnCount--;
                }
                columnHeaders.push(
                    <div
                        key={index}
                        className={`columnBlock cC g fR s e${frozen && isScrollableX ? " frozen" : ''}`}
                        style={stickyPosition(frozen)}
                    >
                        {isScrollableX &&
                            <div
                                className="f cC p setFrozen"
                                onClick={()=>{
                                    updateAnalytics("selectedReport.columns." + index, "frozen", !frozen);
                                }}
                            >
                                <AcUnitIcon/>
                            </div>
                        }
                        <div className={`bold f cC gCW gC2`} title={column?.friendlyTerm}>
                            {column?.friendlyTerm}
                        </div>
                        <div className={`sort f g p gC3`}>
                            <div
                                className={`icon${ascending ? " active" : ''}`}
                                onClick={()=>{
                                    updateAnalytics(
                                        ascending ? "sorting" : "sorting[" + (currentIndex !== -1 ? currentIndex : sortingSize()) + "]",
                                        ascending ? sorting.filter((_, index) => index !== currentIndex) : {"type" : "asc", "attr" : column?.columnName}
                                    )
                                    if(selectedReport?.stem === "individuals"){
                                        requestResultBlock(
                                            {newSorting : [ascending ? sorting.filter((_, index) => index !== currentIndex) : {"type" : "asc", "attr" : column?.columnName}]}
                                        )
                                    }
                                }}
                            >
                                <ArrowDropUpIcon/>
                            </div>
                            <div
                                className={`icon${descending ? " active" : ''}`}
                                onClick={()=>{
                                    updateAnalytics(
                                        descending ? "sorting" : "sorting[" + (currentIndex !== -1 ? currentIndex : sortingSize()) + "]",
                                        descending ? sorting.filter((_, index) => index !== currentIndex) : {"type" : "desc", "attr" : column?.columnName}
                                    )
                                    if(selectedReport?.stem === "individuals"){
                                        requestResultBlock(
                                            {newSorting : [descending ? sorting.filter((_, index) => index !== currentIndex) : {"type" : "desc", "attr" : column?.columnName}]}
                                        )
                                    }
                                }}
                            >
                                <ArrowDropDownIcon/>
                            </div>
                        </div>
                    </div>
                );
            });
        }

        return (
            columnHeaders?.length > 0 ?
                columnHeaders
            :
                <div className="f cC">
                    No columns selected
                </div>
        );
    }

    // const isEqualWithoutAttributes = (objA, objB, attributesToIgnore) => {
    //     const copyA = { ...objA };
    //     const copyB = { ...objB };
      
    //     attributesToIgnore.forEach((attribute) => {
    //       delete copyA[attribute];
    //       delete copyB[attribute];
    //     });
      
    //     return JSON.stringify(copyA) === JSON.stringify(copyB);
    // };

    const isEqualWithoutAttributes = (objA, objB, attributesToIgnore = []) => {
        const deepEqual = (a, b) => {
          if (typeof a !== typeof b) {
            return false;
          }
          
          if (typeof a !== 'object') {
            return a === b;
          }
          
          if (Array.isArray(a) && Array.isArray(b)) {
            if (a.length !== b.length) {
              return false;
            }
            for (let i = 0; i < a.length; i++) {
              if (!deepEqual(a[i], b[i])) {
                return false;
              }
            }
            return true;
          }
          
          const keysA = Object.keys(a).filter((key) => !attributesToIgnore.includes(key));
          const keysB = Object.keys(b).filter((key) => !attributesToIgnore.includes(key));
          
          if (keysA.length !== keysB.length) {
            return false;
          }
          
          for (const key of keysA) {
            if (!deepEqual(a[key], b[key])) {
              return false;
            }
          }
          
          return true;
        };
      
        return deepEqual(objA, objB);
    };

    const unsavedChanges = (reportID) => {
        if (!selectedReport || Object?.keys(analytics?.data?.allReports)?.length === 0) return false;

        const index = analytics?.data?.allReports?.findIndex(
            (report) => report.recordID === selectedReport.recordID
        );

        if (index === -1) return false;

        if(reportID === true){
            return !isEqualWithoutAttributes(
                // analytics?.data?.allReports[index], selectedReport, ["list", "totalFound", "lastRecordID", "lastQuery", "lastPageIndex"]
                analytics?.data?.allReports[index], selectedReport, ["list", "totalFound", "lastRecordID", "lastPageIndex"]
            );
        }else if (selectedReport.recordID) {
            const index = analytics?.data?.allReports.findIndex(
                (report) => report.recordID === selectedReport.recordID
            );

            return !isEqualWithoutAttributes(
                // analytics?.data?.allReports[index], selectedReport, ["list", "totalFound", "lastRecordID", "lastQuery", "lastPageIndex"]
                analytics?.data?.allReports[index], selectedReport, ["list", "totalFound", "lastRecordID", "lastPageIndex"]
            ) && selectedReport?.recordID === reportID;
        }
    };

    function printReportsList(){
        let existingReports = [];

        const currentlyEditing = !selectedReport?.recordID && JSON.stringify(selectedReport) !== JSON.stringify(newReportTemplate);
        const listedReports = allReports.sort((a, b) => b.recordID - a.recordID);

        for (const key in listedReports) {
            if (Object.prototype.hasOwnProperty.call(allReports, key)) {
                const report = allReports[key];
                if(report && (report?.stem === reportCategory || (report?.scheduled && reportCategory === "scheduled"))){
                    existingReports.push(
                        <div
                            key={report?.recordID}
                            className={`criterionBlock cC g p e${
                                selectedReport?.recordID === report?.recordID ? " active" : ''}${
                                    reportCategory === "scheduled" ? " scheduled" : ''}`}
                            onClick={()=> {
                                if(currentlyEditing){
                                    props?.session?.env?.setOverlay("reportErrorHandler");
                                    updateAnalytics("tempReport", null, report);
                                }else if(report?.recordID !== selectedReport?.recordID){
                                    if(unsavedChanges(true)){
                                        props?.session?.env?.setOverlay("reportErrorHandler");
                                        updateAnalytics("tempReport", null, report);
                                    }else{
                                        updateAnalytics("selectedReport", null, report);
                                    }
                                }else{
                                    updateAnalytics("selectedReport", null, selectedReport);
                                }

                                if(report?.stem === "individuals" && selectedReport?.recordID !== report?.recordID){
                                    requestResultBlock({newQuery : report?.query, newColumns : report?.columns});
                                }
                            }}
                        >
                            {reportCategory === "scheduled" &&
                                reportData[report?.stem]?.icon
                            }
                            <div className="bold f cL gCW s">
                                {unsavedChanges(report?.recordID) && "*"}
                                {report?.details?.name ?? "Unnamed Report"}
                            </div>
                            <div
                                className="icon f cC"
                                onClick={(e) => {
                                    e.stopPropagation();

                                    if (currentlyEditing) {
                                        props?.session?.env?.setOverlay("reportErrorHandler");
                                        updateAnalytics("tempReport", null, report);
                                    }else if(unsavedChanges(true) && report?.recordID !== selectedReport?.recordID){
                                        props?.session?.env?.setOverlay("reportErrorHandler");
                                        updateAnalytics("tempReport", null, report);
                                    }else{
                                        if(report?.recordID !== selectedReport?.recordID){
                                            updateAnalytics("selectedReport", null, report);
                                        }
                                        props?.session?.env?.setOverlay("criteriaHandler");
                                    }
                                }}
                            >
                                <EditIcon/>
                            </div>
                        </div>
                    );
                };
            }
        }

        return (
            <>
                <div
                    className={`criterionBlock create cC g p e${currentlyEditing ? " active" : ''}`}
                    onClick={() => {
                        props?.session?.env?.setOverlay("criteriaHandler");
                        if (selectedReport?.recordID && !unsavedChanges(true)) {
                            updateAnalytics("selectedReport", null, {...newReportTemplate});
                        }else if(unsavedChanges(true)){
                            props?.session?.env?.setOverlay("reportErrorHandler");
                            updateAnalytics("tempReport", null, {...newReportTemplate});
                        }
                      }}
                >
                    <div className="bold f cC">
                        {currentlyEditing ?
                            "Continue Editing"
                        :
                            "Create New Report"
                        }
                    </div>
                    <div className="f cC gC2 zTPC">
                        {currentlyEditing ?
                            <EditIcon/>
                        :
                            <AddCircleIcon/>
                        }
                    </div>
                </div>
                {existingReports}
            </>
        )
    }

    function formulatePath(row){
        const pathData = {
            branch : selectedReport?.branch,
            stem : selectedReport?.stem,
            pointer : undefined
        }

        if(pathData?.stem === "origination"){
            pathData.pointer = row?.relatedPolicyID;
        }else if(pathData?.stem === "servicing"){
            pathData.branch += "/servicing";
            pathData.pointer = row?.recordID;
        }else if(pathData?.stem === "bids"){
            pathData.pointer = row?.recordID;
        }

        return (`/${pathData?.branch}/${pathData?.pointer}`)
    }

    const boldSearchText = (text, searchStrings) => {
        if(!text || searchStrings?.length === 0){
            return text;
        }

        const lowercaseText = { value: text?.toLowerCase() };
        const result = [];
        searchStrings.sort((a, b) => b.length - a.length);

        searchStrings
        ?.filter(str => str?.trim())
        ?.forEach(searchString => {
            const lowercaseSearchString = searchString?.toLowerCase();
            let startIndex = lowercaseText?.value?.indexOf(lowercaseSearchString);

            while (startIndex !== -1) {
                const endIndex = startIndex + searchString?.length;
                result.push({ string: text?.substring(0, startIndex), bold: false });
                result.push({ string: text?.substring(startIndex, endIndex), bold: true });

                text = text?.substring(endIndex);
                lowercaseText.value = lowercaseText?.value?.substring(endIndex);
                startIndex = lowercaseText?.value?.indexOf(lowercaseSearchString);
            }
        });

        if (text?.length > 0) {
            result.push({ string: text, bold: false });
        }

        return (
            <React.Fragment>
                {result.map((portion, index) => (
                    <span className={portion.bold ? 'bold' : ''} key={index}>
                        {portion?.string}
                    </span>
                ))}
            </React.Fragment>
        );
    }

    const stickyPosition = (frozen, stickyCount, columnCount) => {
        if(!frozen){
            return;
        }

        return { left: `${(stickyCount - 1) * columnWidth - (stickyCount > 1 && (stickyCount - 1))}px`, zIndex: columnCount}
    }

    function printListResults(){
        let rowCount = 0;
        let stickyCount = 0;
        let columnCount = selectedReport?.columns?.length || 0;

        const results = [];
        const numCols = selectedReport?.columns?.length || 0; // Get the number of columns

        const colStyle = {
            gridTemplateColumns: `repeat(${numCols}, 1fr)`, // Set the number of columns dynamically
        };

        const cachedFormattedDates = new Map();

        const memoize = (func) => {
            const cache = new Map();
            return (...args) => {
              const key = JSON.stringify(args);
              if (cache.has(key)) {
                return cache.get(key);
              }
              const result = func(...args);
              cache.set(key, result);
              return result;
            };
        };

        const renderFormattedColumnValue = (columnValue, returnType, searchData) => {
            const stringOnly = searchData === "stringOnly";

            switch (returnType) {
                case "currency":
                    return stringOnly ? 
                        envFunctions.convertIntToCurrency(columnValue).toString()
                    :
                        boldSearchText(envFunctions.convertIntToCurrency(columnValue).toString(), searchData, stringOnly);
                case "date":
                    if (cachedFormattedDates.has(columnValue)) {
                        const formattedDate = cachedFormattedDates.get(columnValue);
                        return stringOnly ?
                            formattedDate
                        :
                            boldSearchText(formattedDate, searchData, stringOnly);
                    }

                    const reformattedDate = envFunctions.reformatDate(columnValue);
                    // const reformattedDate = columnValue;
                    cachedFormattedDates.set(columnValue, reformattedDate);

                    if (reformattedDate === "Invalid Date") {
                        return null;
                    }

                    return stringOnly ?
                        reformattedDate?.toString()
                    :
                        boldSearchText(reformattedDate?.toString(), searchData, stringOnly);

                case "link":
                    return (
                        <a className="link" target="_blank" href={columnValue}>
                            <div className="f cC g">
                                <PublicIcon />
                                <div className="f gCW">
                                    { stringOnly ?
                                        columnValue?.toString()
                                    :
                                        boldSearchText(columnValue?.toString(), searchData, stringOnly)}
                                </div>
                            </div>
                        </a>
                    );
                default:
                    return stringOnly ?
                        columnValue?.toString()
                    :
                        boldSearchText(columnValue?.toString(), searchData, stringOnly);
            }
        };

        const memoizedRenderFormattedColumnValue = memoize(renderFormattedColumnValue);

        if (!selectedReport || !selectedReport?.columns || !selectedReport?.list) {
            setListResults([]);
            return null; // Return null or some indication of an issue
        }

        const sortList = (a, b) => {
            for (const sortCriteria of analytics?.data?.sorting) {
                const { attr, type } = sortCriteria;
                const valueA = a[attr];
                const valueB = b[attr];
          
                if (type === 'asc') {
                    if (valueA < valueB) return -1;
                    if (valueA > valueB) return 1;
                } else if (type === 'desc') {
                    if (valueA < valueB) return 1;
                    if (valueA > valueB) return -1;
                }
            }

            return 0;
        };

        // const sortedList =  [...selectedReport?.list].sort(sortList);
        const sortedData = {
            list : [...selectedReport?.list].sort(sortList)
        }

        if (selectedReport?.branch !== "individuals" && search?.searchValue) {
            const searchTerms = search?.searchValue?.toLowerCase()?.split(' ');

            sortedData.list = sortedData.list.filter((row) => {
                return searchTerms.every((term) => {
                    return selectedReport?.columns?.some((column) => {
                        const columnName = column?.columnName;
                        const columnValueLower = row?.[columnName]?.toString()?.toLowerCase();

                        // Check if the individual's attribute value contains a space
                        if (columnValueLower?.includes(' ')) {
                            // Split the attribute value into words
                            const attributeWords = columnValueLower?.split(' ');

                            // Check if any of the words match the search term
                            return attributeWords?.some((word) => word?.includes(term));
                        } else {
                            // Treat it as a normal match if there's no space
                            return memoizedRenderFormattedColumnValue(columnValueLower, attributeData[columnName]?.returnType, "stringOnly")?.includes(term);
                        }
                    });
                });
            });
        }

        setSortedListResults(sortedData?.list);
        sortedData?.list.forEach((row, index) => {
            const printRow = () => {
                const rowCells = selectedReport?.columns.map((column, colIndex) => {
                    const frozen = column?.frozen;

                    if(colIndex === 0){
                        stickyCount = 0;
                        columnCount = selectedReport?.columns.length;
                    }

                    if (frozen){
                        stickyCount++;
                        columnCount--;
                    }

                    const columnValue = row?.[column?.columnName];
                    const searchData = search?.searchValue?.toLowerCase()?.split(' ');

                    return (
                        <div
                            key={colIndex + index}
                            className={`f g cC zT fR s e${frozen && isScrollableX ? " frozen" : ''}`}
                            style={stickyPosition(frozen, stickyCount, columnCount)}
                        >
                            <div className="gCW" title={columnValue}>
                                <span>
                                    {memoizedRenderFormattedColumnValue(
                                        columnValue,
                                        attributeData[column.columnName]?.returnType,
                                        searchData
                                    )}
                                </span>
                            </div>
                        </div>
                    );
                });

                // Create a row div with the cells
                results.push(
                    <Link
                        key={index}
                        className={`row f cC g p`} style={colStyle}
                        to={formulatePath(row)}
                        onClick={()=>{props.navigation.setCurrentActivity("singleBid");}}
                    >
                        {rowCells}
                    </Link>
                );

                rowCount++;
            }

            if(selectedReport?.details?.scrollType === "infinite" && rowCount < infiniteScrollMax){
                printRow();
            }

            if(selectedReport?.details?.scrollType === "pagination" && index >= ( selectedReport?.stem === "individuals" ? true : (pagination.index - 1) * pagination?.rowMax ?? 0 ) && rowCount < pagination?.rowMax){
                printRow();
            }
          // Create an array to hold cells for each column

        });

        setListResults(results);
        //return results;
    }

    function paginationHandler() {
        const totalRecords =
            (selectedReport?.branch !== "individuals" && search?.searchValue) ?
                sortedListResults?.length :
                selectedReport?.totalFound ?? selectedReport?.list?.length;

        if(totalRecords === 0 || !totalRecords || totalRecords < pagination.rowMax){
            return;
        }

        const totalPages = Math.ceil(totalRecords / pagination?.rowMax);

        const handlePageChange = (newOffset) => {
          if (newOffset >= 1 && newOffset <= totalPages) {
            setPagination((prev) => ({
                ...prev,
                index: newOffset,
            }));
          }

          if(selectedReport?.stem === "individuals"){
            requestResultBlock({newOffset : newOffset});
          }
        };

        const maxVisiblePages = 5;
        const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);
        const firstPage = Math.max(1, pagination.index - halfMaxVisiblePages);
        const lastPage = Math.min(totalPages, firstPage + maxVisiblePages - 1);

        const showFirstPageButton = pagination.index > halfMaxVisiblePages + 1;
        const showLastPageButton = totalPages > maxVisiblePages && lastPage < totalPages;

        const numColumns = maxVisiblePages;
        const paginationCols = { gridTemplateColumns: `repeat(${numColumns + 6}, 1fr)` };        

        return (
            <div className={`pagination f g cR nS bold${requestingBatch ? " inactive" : ''}`} style={paginationCols}>
                {showFirstPageButton ?
                    <div
                        className="btn f cC bR p"
                        onClick={() => handlePageChange(1)}
                    >
                        <FirstPageIcon/>
                    </div>
                :
                    <div className="spacer">
                    </div>
                }

                {pagination.index !== 1 ?
                    <div
                        className="btn f cC bR p"
                        onClick={() => handlePageChange(pagination.index - 1)}
                    >
                        <NavigateBeforeIcon/>
                    </div>
                :
                    <div className="spacer">
                    </div>
                }

                {showFirstPageButton &&
                    <div className="f cC">
                        <span>...</span>
                    </div>
                }

                {Array.from({ length: lastPage - firstPage + 1 }, (_, index) => (
                    <div
                        key={firstPage + index}
                        onClick={() => handlePageChange(firstPage + index)}
                        className={`btn f cC bR p${pagination.index === firstPage + index ? ' active' : ''}`}
                    >
                        {firstPage + index}
                    </div>
                ))}

                {showLastPageButton &&
                    <div className="f cC">
                        <span>...</span>
                    </div>
                }

                {totalPages !== pagination.index &&
                    <div
                        className="btn f cC bR p gC10"
                        onClick={() => handlePageChange(pagination.index + 1)}
                    >
                        <NavigateNextIcon/>
                    </div>
                }

                {showLastPageButton && (
                    <div
                    className="btn f cC bR p gC11"
                        onClick={() => handlePageChange(totalPages)}
                    >
                        <LastPageIcon/>
                    </div>
                )}
            </div>
        );
    }

    function reportFolders() {
        const reportablePages = ["origination", "servicing", "bids",];
        // const opportunityTypes = ["origination", "servicing", "bids"];
        const opportunityTypes = props?.session?.user?.data?.opportunityTypes;
        let exclude = ["lead", "marketing"];

        return (
            <div
                className="categories g f cC fR"
                style={props?.session?.user?.data?.opportunityTypes ? {gridTemplateColumns : `repeat(${props?.session?.user?.data?.opportunityTypes.filter(item => !exclude.includes(item)).length + 1}, 1fr)`} : null}    
            >
                {opportunityTypes?.map((reportType, index) => {
                    const reportTypeInfo = reportData?.[reportType];
                    if (reportablePages.includes(reportType)) {
                        return (
                            <div
                                key={index}
                                className={`option g p cC dP f fC bold${reportCategory === reportType ? " active" : ''}`}
                                onClick={() => {
                                    setReportCategory(reportType);
                                }}
                            >
                                {reportTypeInfo?.icon}
                            </div>
                        );
                    } else {
                        return null; // Return null for individuals not handled
                    }
                })}
                <div
                    key="scheduled"
                    className={`option g p cC dP f fC bold${reportCategory === "scheduled" ? " active" : ''}`}
                    onClick={() => {
                        setReportCategory("scheduled");
                    }}
                >
                    <EventRepeatIcon/>
                </div>
            </div>
        );
    }

    function printPaginationRowOptions(){
        // Check if the specified dataType exists in criterionForm
        const rowMaxOptions = [100, 250, 500, 1000];

        // Map the criteria to JSX elements
        const options = rowMaxOptions.map((count) => (
            <div
                key={count} // Add a unique key
                className={`option cC bR dP p g bold`}
                onClick={(e) => {
                    e.stopPropagation();
                    const currentStorage = JSON.parse(localStorage.getItem("storage")) || {};
                    if(currentStorage?.reporting?.rowMax !== count){
                        currentStorage.reporting = {
                            ...currentStorage.reporting,
                            rowMax: count, // Update rowMax to the desired value
                        };

                        setPagination((prev) => ({
                            ...prev,
                            rowMax: count,
                            showMenu: !prev.showMenu,
                        }));
    
                        localStorage.setItem("storage", JSON.stringify(currentStorage));
    
                        if(selectedReport?.stem === "individuals"){
                            requestResultBlock({newLimit : count, newOffset : 1})
                        }
                    }else{
                        setPagination((prev) => ({
                            ...prev,
                            showMenu: !prev.showMenu,
                        }));
                    }
                }}
            >
                <div className="f cL gCW">
                    {(count).toLocaleString()}
                </div>
            </div>
        ));

        return options;
    }

    const handleScroll = (e) => {
        const element = e.target;

        const isNearBottom = element.scrollHeight - element.scrollTop - element.clientHeight < 25;
        if (isNearBottom && infiniteScrollMax < selectedReport?.list?.length) {
            setInfiniteScrollMax(prevMax => prevMax + 100);
        }
    };

    const maintainScrollStatus = () =>{
        const checkScrollable = () => {
            const el = reportTable.current;
            if (el) {
                const scrollable = el.scrollWidth > el.clientWidth;
                setIsScrollableX(scrollable);
            }
        };
    
        checkScrollable();
        // Optionally, add a resize event listener if the container's size might change
        window.addEventListener('resize', checkScrollable);
    
        return () => window.removeEventListener('resize', checkScrollable);
    }

    useEffect(() => {
        if(selectedReport?.list){
            printListResults();
            calculateColumnWidth();
            reportTable.current.scrollTop = 0;
            setInfiniteScrollMax(100);
        }
    }, [selectedReport?.list, pagination?.index, pagination?.rowMax, isScrollableX]);

    useEffect(() => {
        if(selectedReport?.stem && reportCategory !== selectedReport?.stem){
            setReportCategory(selectedReport?.stem);
        }
    }, [selectedReport?.stem]);

    useEffect(() => {
        printListResults();
        maintainScrollStatus();
    }, [infiniteScrollMax, selectedReport?.list]);

    useEffect(() => {
        setPagination((prev) => ({
            ...prev,
            index: 1,
        }));
    }, [pagination?.rowMax]);

    useEffect(() => {
        if(
            selectedReport?.query
            && selectedReport?.stem === "individuals"
            && !isEqualWithoutAttributes(
                existingColumnsCopy, selectedReport.columns, ["frozen"]
            )
        ){
            requestResultBlock();
        }
    }, [selectedReport?.columns]);

    useEffect(() => {
        if(selectedReport?.query){
            // if(selectedReport?.stem === "deceased" || selectedReport?.stem === "scheduled"){
                const items = props?.session?.case?.data?.[selectedReport?.branch]?.[selectedReport?.stem];
                const filteredResults = analytics?.functions?.filterItemsWithQuery(selectedReport?.query, items);
                updateAnalytics("selectedReport", "list", filteredResults);
            // }
        }

        if(selectedReport?.lastRecordID !== selectedReport?.recordID){
            updateAnalytics("sorting", []);
            setExistingColumnsCopy(selectedReport?.columns);
            props?.session?.analytics?.functions?.updateSelectedReport("lastPageIndex", undefined);    
            setPagination((prev) => ({
                ...prev,
                index: 1,
            }));
        }

        setSearch({
            searching: false,
            searchValue: '',
        });

        props?.session?.analytics?.functions?.updateSelectedReport("lastRecordID", selectedReport?.recordID);
        if (reportTable.current) {
            reportTable.current.scrollLeft = 0;
        }

    }, [selectedReport?.recordID, props?.session?.case?.data]);

    useEffect(() => {
        setPagination((prev) => ({
            ...prev,
            index: 1,
        }));

        printListResults();

    }, [search?.searchValue]);

    useEffect(() => {
        if(search?.searching === true){
            document?.getElementById('searchBarInput')?.focus();
        }else{
            setSearch((prevSearch) => ({
                ...prevSearch,
                searchValue: '',
            }));
        }
    }, [search?.searching]);

    useEffect(() => {
        maintainScrollStatus();
    }, [], props?.viewport?.width);

    return (
        <div className="analyticsPage g bR" onClick={()=>{ setPagination((prev) => ({ ...prev, showMenu: false, }));}}>
            <div className="analyticsHub g oH">
                <div className={`actionBar g fR cC s${search?.searching ? " searching" : ''}`}>
                    <div className="bold">
                        {reportCategory ? `${reportCategory?.charAt(0)?.toUpperCase() + reportCategory?.slice(1)} Reports` : "Select Category"}
                    </div>
                    {
                        selectedReport?.stem !== "individuals" && 
                        <div className={`searchBtn f s p cL gC2 fR lH${search?.searching ? " active" : ''}`}>
                            <div
                                className="btn cC"
                                onClick={() => {
                                    setSearch((prevSearch) => ({
                                        ...prevSearch,
                                        searching: !search?.searching,
                                    }));
                                }}
                            >
                                {search?.searching ?
                                    <SearchOffIcon/>
                                :
                                    <SearchIcon/>
                                }
                            </div>
                        </div>
                    }

                    <div className="searchBar g f bold gC3 cC fR fC">
                        {search?.searching ? 
                            <InputField
                                search={true}
                                value={search?.searchValue ?? ''}
                                placeholder={"Search Individuals"}
                                name={"searchValue"}
                                readOnly={false}
                                functionPointer={session?.env?.functions?.updateInput}
                                setter={setSearch}
                                autoComplete={"off"}
                                id={"searchBarInput"}
                            />
                        :
                            selectedReport?.details?.name ?? "Unnamed Report"
                        }
                    </div>
                    <div className="btnOptions cC g fR fC f">
                        {/* <div className="btnWIcon bold bR fMP cC p ns">
                            <div className="f cC">
                                Share
                            </div>
                            <ShareIcon/>
                        </div> */}
                        {/* {selectedReport?.stem === "deceased" && */}
                            <div
                                className={`btnWIcon bold bR fMP cC ns${!viewableReport ? " inactive" : " p"}`}
                                onClick={viewableReport ? ()=>{ props?.session?.analytics?.functions?.downloadReport(selectedReport, sortedListResults)} : null}
                            >
                                <div className="f cC lH">
                                    Download
                                </div>
                                <DownloadIcon/>
                            </div>
                        {/* } */}
                        {/* <div className="moreBtn cC fMP p">
                            <MoreHorizIcon/>
                        </div> */}
                    </div>
                </div>
                <div className="reportBuilder f fR g s oH">
                    <div className="reportAttributes g fC b oH">
                        <div className="reportCategory bR g">
                            {reportFolders()}
                        </div>
                        <div className={`criteriaSection f g dP oA gR2${!allReports ? " idle" : ''}`}>
                            {allReports ? 
                                printReportsList()
                            :
                                <div className="content f cC bR oH">
                                    <div className="f loading cC gR2">
                                        <div className="cC f">
                                            <div className="g dG cC">
                                                <CircularProgress color="inherit"/>
                                                <div className="dG cC f">
                                                    <TableRowsTwoToneIcon color="inherit"/>
                                                    Loading all reports
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={`report s b e g zTPC${selectedReport?.ID && listResults?.length === 0 ? " gathering" : ''}`}>
                        <div ref={reportTable} onScroll={(e)=>{handleScroll(e)}} className={`list f g${(!viewableReport || listResults?.length === 0 || requestingBatch) ? " inactive" : ''}`}>
                            <div
                                className={`columnHeader g${selectedReport?.columns?.length > 5 ? " expanded" : ''}${!viewableReport ? " loading" : ''}`}
                                style={selectedReport?.columns?.length ? headerColumns : {zIndex : (selectedReport?.columns?.length || 0) + 1}}
                                ref={columnContainerRef}
                            >
                                {printColumnHeaders()}
                            </div>
                            {!viewableReport ? (
                                <div className={`loading empty f cC g`}>
                                    Select a report or complete the following to get a new report started
                                    <div className="checkList thin g">
                                        <div className="g">
                                            <div className="f cL">
                                                Filters
                                            </div>
                                            {selectedReport?.query?.length > 0 ?
                                                <CheckCircleIcon/>
                                            :
                                                <HighlightOffIcon/>
                                            }
                                        </div>
                                        <div className="g">
                                            <div className="f cL">
                                                Columns
                                            </div>
                                            {selectedReport?.columns?.length > 0 ?
                                                <CheckCircleIcon/>
                                            :
                                                <HighlightOffIcon/>
                                            }
                                        </div>
                                    </div>
                                </div> )
                            :
                                <>
                                    {listResults?.length > 0 && !requestingBatch ? 
                                        listResults
                                    :
                                        hasBeenGenerated ?
                                            <div className="f cC empty loading">
                                                There is no results to show for provided requirements
                                            </div>
                                        :
                                        <div className="loading f cC g">
                                            <CircularProgress color="inherit"/>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                        <div className="detailBar bR g" style={{zIndex : selectedReport?.columns?.length || 3}}>
                            {selectedReport?.details?.scrollType === "pagination" &&
                                <div
                                    className={`rowMaxMenuDropdown btn s bR cC g nS p${(!viewableReport || !selectedReport?.list ) ? " inactive" : ''}`}
                                    onClick={(e) => {
                                        if (!viewableReport || !selectedReport?.list ) return;
                                        e.stopPropagation();
                                        setPagination((prev) => ({ ...prev, showMenu: !prev.showMenu }));
                                    }}
                                >
                                    <div className={`f cC gCW bold`}>
                                        {"Show " + (pagination?.rowMax).toLocaleString()}
                                    </div>
                                    {pagination?.showMenu ?
                                        <ArrowDropDownIcon/>
                                    :
                                        <ArrowDropUpIcon/>
                                    }
                                    {pagination?.showMenu &&
                                        <div className={`rowMaxMenu invert menu nS g bR dP`}>
                                            {printPaginationRowOptions()}
                                        </div>
                                    }
                                </div>                               
                            }
                            <div className="f cC gC2">
                                {(selectedReport?.list?.length === 1 || selectedReport?.totalFound === 1) ?
                                    "1 record matched"
                                :
                                    (selectedReport?.list?.length || selectedReport?.totalFound) ?
                                        ((selectedReport?.branch !== "individuals" && search?.searchValue) ?
                                            sortedListResults?.length : selectedReport?.totalFound ?? selectedReport?.list?.length) + " records matched"
                                    :
                                        "No records to show"
                                }
                            </div>
                            {selectedReport?.details?.scrollType === "pagination" &&
                                paginationHandler()
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reporting;