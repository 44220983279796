import React from 'react';
import { Link } from 'react-router-dom';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';

import InputField from '../../Components/InputField.js';
import BidTimelinePost from '../../Components/Timeline/BidTimelinePost.js';
import DefaultTimelinePost from '../../Components/Timeline/DefaultTimelinePost.js';
import TimelinePostDetails from '../../Components/Timeline/TimelinePostDetails.js';
import CaseOptions from '../../Components/CaseOptions/CaseOptions.js';

import PremiumSchedule from '../../PoliciesDashboard/SinglePolicy/PremiumSchedule.js';
import MessageBoard from '../../Components/MessageBoard/MessageBoard';
import { convertIntToCurrency, dateFormat, leadSourceTag } from '../../envFunctions.js';

import LineChart from "../../Components/Chart/LineChart.js";
import BidAlterPanel from "./BidAlterPanel.js";

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TimelineIcon from '@mui/icons-material/Timeline';
import CircularProgress from '@mui/material/CircularProgress';
import FolderIcon from '@mui/icons-material/Folder';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import ForumIcon from '@mui/icons-material/Forum';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import HistoryIcon from '@mui/icons-material/History';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import WcIcon from '@mui/icons-material/Wc';
import CloseIcon from '@mui/icons-material/Close';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import SearchIcon from '@mui/icons-material/Search';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FolderOffIcon from '@mui/icons-material/FolderOff';

function SingleBid(props){
    const params = useParams();
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [readOnlyStatus, setReadOnlyStatus] = useState(true);
    const [bidActivityTimeline, setBidActivityTimeline] = useState();
    const [showStatusDetail, setShowStatusDetail] = useState(false);
    const [showCaseOptions, setShowCaseOptions] = useState(false);
    const [secondary, setSecondary] = useState(false);
    const [singleBidActivityView, setSingleBidActivityView] = useState('');
    const { hash } = useLocation();
    const [currentView, setCurrentView] = useState(hash ? hash : "timeline");
    const [currentBidInfo, setCurrentBidInfo] = useState(props.bidsList?.find(bid=>{
                return bid.recordID == params.recordID }) || undefined);
    const [tempCurrentBidInfo, setTempCurrentBidInfo] = useState([]);
    const navigate = useNavigate();
    const [bidPremiumData, setBidPremiumData] = useState();
    const [premiumChartData, setPremiumChartData] = useState(null);
    const [showPremiumChart, setShowPremiumChart] = useState(false);
    const [showPremiumOptions, setShowPremiumOptions] = useState(false);
    const premiumScheduleRef = useRef(null);
    const [selectedPremiumSchedule, setSelectedPremiumSchedule] = useState(0);
    const [visiblePremiumCount, setVisiblePremiumCount] = useState(48);
    const approvedTabs = ["#timeline","#documents", "#messages", "#lifeExpectancies", "#details", "#premiumSchedule"];
    const [LEData, setLEData] = useState();
    const [showBidAlterPanel, setShowBidAlterPanel] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState();
    const [currentTimelinePost, setCurrentTimelinePost] = useState();
    const [timelineNotificationCount, setTimelineNotificationCount] = useState();
    const [shareLink, setShareLink] = useState();
    const [taskShareLink, setTaskShareLink] = useState();
    const [bidVerbiage, setBidVerbiage] = useState("Bid");

    function printTimeline(){
        return (
            <div className="singlePolicyTimelineTracker bid g f">
                <div className="policyDetailsCardTitle">
                    <span>Case Timeline</span>
                    <div className="groupIcon">
                        <TimelineIcon/>
                    </div>
                </div>

                <div className="policyDetailsCardFace bR tO">
                    {loadTimeline()}
                </div>
            </div>
        )
    }

    function loadBidPremiums(){
        props.session?.env?.functions?.buildFetchRequest("marketplace/getBidsBPs", {recordID : params.recordID})
          .then(response => response.json())
          .then(resData => {      
            if(resData.status === 200){
                setBidPremiumData(resData.premiumSchedules);
            }

            if(resData.status === 500){
              console.log("No activity found.");
            }
          }); 
    }

    function handlePremiumScheduleScroll(e) {
        const { scrollTop, scrollHeight, offsetHeight } = e.target;
        const hasScrollReachedBottom = scrollTop + offsetHeight >= scrollHeight;

        if (hasScrollReachedBottom && visiblePremiumCount < Object.keys(bidPremiumData[selectedPremiumSchedule].premiumSchedule).length) {
          setVisiblePremiumCount(visiblePremiumCount + 48);
        }
    }

    function printBidPremiums(){
        let returningData = "Loading";

        if(bidPremiumData){
            return (
                <div className="premiumScheduleWrapper f bR tO g" ref={premiumScheduleRef} onScroll={(e)=>{handlePremiumScheduleScroll(e)}}>
                    <PremiumSchedule
                        sessionUser={props.sessionUser}
                        updatePage={props.sessionVars.updatePage}
                        premiumScheduleData={bidPremiumData}
                        visiblePremiumCount={visiblePremiumCount}
                        policyData={currentBidInfo}
                        setShowPremiumChart={setShowPremiumChart}
                        displayType="shipping"
                        selectedSchedule={selectedPremiumSchedule}
                        showPremiumOptions={showPremiumOptions}
                        setShowPremiumOptions={setShowPremiumOptions}
                        session={props?.session}
                    />
                </div>
            )
        }

        returningData = 
            (<div className="f LEWrap cC bR">
                {returningData}
            </div>)

        return returningData;
    }

    function loadBidLEs(){
        props.session?.env?.functions?.buildFetchRequest("marketplace/getBidsBLEs", {recordID : params.recordID})
          .then(response => response.json())
          .then(resData => {      
            if(resData.status === 200){
                setLEData(resData.leList);
            }
      
            if(resData.status === 500){
              console.log("No activity found.");
            }
          }); 
    }

    function printLifeExpectancies(){
        let returningData = "Loading";

        if(LEData){
            returningData = [];
            Object.keys(LEData).forEach(function(key){
                returningData.push(
                    <div key={key + LEData.recordID} className="LETableRow g fR bR cC">
                        <div className="data g cC">
                            {!LEData[key].secondaryInsured ? <PersonIcon/> : <GroupIcon/> }
                            <div className="cL">
                                {LEData[key].insuredFullName ? LEData[key].insuredFullName : "N/A"}
                            </div>
                        </div>
                        <div className="data g cC">
                            {LEData[key].leMonths ? LEData[key].leMonths : "N/A"}
                        </div>
                        <div className="data g cC">
                            {LEData[key].leSource ? LEData[key].leSource : "N/A"}
                        </div>
                        <div className="data g cC">
                            {LEData[key].leReportDate ? dateFormat(LEData[key].leReportDate) : "N/A"}
                        </div>
                    </div>
                );
            });

            returningData = [
                <div key="LETable" className="LETable g fC fMP">
                    <div className="LETableRow g fR bR cC">
                        <div className="data g cC bold">
                            <WcIcon/>
                            <div className="cL">
                                Name
                            </div>
                        </div>
                        <div className="data g cC bold">
                            LE Month
                        </div>
                        <div className="data g cC bold">
                            Source
                        </div>
                        <div className="data g cC bold">
                            Date
                        </div>
                    </div>
                    <div className="divider"></div>
                    {returningData}
                </div>
            ];
        }

        returningData = [
            <div key="LEWrap" className="f LEWrap cC bR">
                {returningData}
            </div>
        ];

        return returningData;
    }

    function loadBidActivity(){
        props.session?.env?.functions?.buildFetchRequest("marketplace/getBidActivities", {"taskID" : params.recordID})
          .then(response => response.json())
          .then(resData => {
            if(resData.status === 200){
                //console.log(resData);
                setBidActivityTimeline(resData.taskTimelineData);
                markReadStatus("timeline");
                //udateKanbanReadStatus();
            }

            if(resData.status === 500){
              console.log("No activity found.");
            }
            setLoadingStatus(false);
        });
    }

    function markReadStatus(){
        const paramVals = {
            "type":"task",
            "taskID": params?.recordID,
            "unread": false
        };

        props.session?.env?.functions?.buildFetchRequest("marketplace/updateReadStatus", paramVals)
        .then(response => response.json())
        .then(resData => {
            if(resData.status === 200){
            //console.log(resData);
            }
    
            if(resData.status === 500){
            console.log("No activities read.");
            }
        });
    }

    function showAlterPanel(status){
        setShowBidAlterPanel(true);
        setSelectedStatus(status)
    }

    function loadTimeline(){
        let timelineBlock = [];
        let notificationCount = 0;

        if(!bidActivityTimeline || loadingStatus){
            return (
                <div className="singlePolicyEmptyTimeline">
                    <div className="singlePolicyTimelineInner loading loadingDisplay">
                        <div>
                            <CircularProgress color="inherit"/>
                        </div>
                    </div>
                </div>
            )
        }

        if(Object.keys(bidActivityTimeline)?.length === 0){
            return (
                <div className="singleTimeline g b bR t b fC cC">
                    {printTimelineActions()}
                    <div className="cC">
                        There is no current activity to show.
                    </div>
                    <div className="idleStamp">
                        <MoreVertIcon/>
                    </div>
                    <div className="timelineIssueDate">
                        {currentBidInfo?.dateCreated ? "Submission Date: " + timeConverter(currentBidInfo?.submissionDate) : null }
                    </div>
                </div>
            )
        }

        let bidActivityArray = Object.values(bidActivityTimeline);
        let sortedTimeline = bidActivityArray.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));

        for (var timelinePost of sortedTimeline){
            if(timelinePost.type === "Status" && timelinePost.taskShippingStatus === "Requested Info"){
                
            }else{
                timelinePost.objectType = "marketplace";

                timelineBlock.push(
                    <BidTimelinePost
                        key={timelinePost.recordID}
                        status={timelinePost.taskShippingStatus}
                        timeStamp={timelinePost.timeStamp}
                        type={timelinePost.type}
                        data={timelinePost}
                        setShowDetails={setShowStatusDetail}
                        setCurrentTimelinePost={setCurrentTimelinePost}
                        sessionUser={props?.sessionUser}
                        session={props?.session}
                    />
                );

    
                if(timelinePost.readStatus === false && timelinePost.originator === "Internal"){
                    notificationCount++;
                }
            }

        };

        if(timelineNotificationCount !== notificationCount){
            setTimelineNotificationCount(notificationCount);
        }

        return (
            <div className="singleTimeline g b bR b fC cC">
                {printTimelineActions()}
                {timelineBlock}
                <div className="timelineIssueDate">
                    <div className="idleStamp">
                        <MoreVertIcon/>
                    </div>
                    {currentBidInfo?.dateCreated ? "Submission Date: " + timeConverter(currentBidInfo?.dateCreated) : null }
                </div>
            </div>
        )
    }

    function printTimelineActions(){
        return (
            [<div key="actionBar" className="bidTimelineActionBar g dP cC fR ns">
                <div className="btn g cC fMP bR p" onClick={()=>{showAlterPanel("Requested Info")}}>
                    <div>
                        Request Info
                    </div>
                    <PendingActionsIcon/>
                </div>
                <div className="vDivider">
                </div>
                <div className="btn g cC fMP bR p" onClick={()=>{showAlterPanel("Under Review");}}>
                    <div>
                        Under Review
                    </div>
                    <SearchIcon/>
                </div>
                <div className="btn g cC fMP bR p" onClick={()=>{showAlterPanel("Bidding")}}>
                    <div>
                        {bidVerbiage === "Interest" ? "Show Interest" : "Make Bid"}
                    </div>
                    <ThumbsUpDownIcon/>
                </div>
                <div className="btn g cC fMP bR p" onClick={()=>{showAlterPanel("Declined")}}>
                    <div>
                        Decline
                    </div>
                    <DoDisturbOnIcon/>
                </div>
            </div>,
            <div key="divider" className="divider"></div>]
        )

    }

    function timeConverter(currentTime){
        if(!currentTime){
            return "Unknown";
        }

        let timeStamp =parseInt(Date.parse(currentTime) / 1000);
        timeStamp = new Date(timeStamp * 1000);
        timeStamp = (timeStamp.getUTCMonth()+1) + "/" + timeStamp.getUTCDate() + "/" + timeStamp.getUTCFullYear();

        return timeStamp;
    }

    function currentValue(attr){
        if(readOnlyStatus){
            if(attr === "deathBenefit"){
                return convertIntToCurrency(currentBidInfo?.[attr], props.mobileDevice);
            }
            if(attr === "primaryAge"){
                const birthdate = new Date(currentBidInfo?.primaryBirthDate);
                const ageInMs = Date.now() - birthdate.getTime();
                const ageInYears = ~~(ageInMs / (31557600000));
                return ageInYears;
            }
            if(attr === "secondaryAge"){
                const birthdate = new Date(currentBidInfo?.secondaryBirthDate);
                const ageInMs = Date.now() - birthdate.getTime();
                const ageInYears = ~~(ageInMs / (31557600000));
                return ageInYears;
            }

            if(currentBidInfo?.[attr] === "Bidding" && bidVerbiage === "Interest"){
                return "Interested"
            }
            return currentBidInfo?.[attr];
        }
        return tempCurrentBidInfo[attr];
    }

    function displayTabs(){
        return [
            <div
                key="premiums"
                className={"switchViewBtn f g cC s e" + currentActivity("premiumSchedule")}
                onClick={() => {updateActivityView("premiumSchedule")}}>
                <div className="switchViewBtnOption">
                    Premiums
                </div>
                <div className="inlineBtnIcon cC"><HistoryIcon/></div>
            </div>,
            <div
                key="LEsBtn"
                className={"switchViewBtn f g cC s e" + currentActivity("lifeExpectancies")}
                onClick={() => {updateActivityView("lifeExpectancies")}}>
                <div className="switchViewBtnOption">
                    LEs
                </div>
                <div className="inlineBtnIcon cC"><LineAxisIcon/></div>
            </div>,
            <div
                key="documentsBtn"
                className={"switchViewBtn f g cC s e" + currentActivity("documents")}
                onClick={() => {updateActivityView("documents")}}>
                <div className="switchViewBtnOption">
                    Documents
                </div>
                <div className="inlineBtnIcon cC"><HistoryEduIcon/></div>
            </div>,
            <div
                key="messagesBtn"
                className={"switchViewBtn f g cC s e" + currentActivity("messages")}
                onClick={() => {
                    updateActivityView("messages")}}>
                {props.notifications?.[currentBidInfo?.recordID] && props.notifications?.[currentBidInfo?.recordID].message?.length > 0 ?
                    <div className="notificationBubble">
                        <FiberNewIcon/>
                    </div>
                : null }
                <div className="switchViewBtnOption">
                    Messages
                </div>
                <div className="inlineBtnIcon cC"><ForumIcon/></div>
            </div>
        ];
    }

    function currentActivity(activityView){
        if(singleBidActivityView === activityView){
            return " selected";
        }
        return '';
    }

    function updateActivityView(activityView){
        var activityViewString = activityView.replace('#', '');
        if (window.location.hash !== '#' + activityViewString && props?.session?.env?.functions?.eligablePath() && props?.session?.user?.data?.currentPage === "bids") {
            navigate("#" + activityViewString);
        }
        setCurrentView(activityViewString);
        setSingleBidActivityView(activityViewString);
    }

    function loadPolicyHolderData(){
        return (
            <div key="policyDetailCard" className="policyDetailsCard bR">
                <div className="profileDetails g dG">
                    <div className="policyDetailsHeader bold cL">
                        Insured
                    </div>
                    <div className="formCanvas">
                        {secondary &&
                            <div className="policyDetailsSplitHeader">
                                <div className="primaryTitle">
                                    Primary
                                </div>
                                <div className="secondaryTitle">
                                    Secondary
                                </div>
                            </div>
                        }
                        <div className={secondary ? "quadFormFieldRow" : "doubleFormFieldRow"}>
                            <InputField loadingStatus={loadingStatus} value={currentValue("primaryFullName")} placeholder={"Full Name"} name={"primaryInsuredFullName"} readOnly={readOnlyStatus}/>
                            <InputField loadingStatus={loadingStatus} value={currentValue("primaryGender")} placeholder={"Gender"} name={"primaryInsuredGender"} readOnly={readOnlyStatus}/>
                            {secondary &&
                                <InputField loadingStatus={loadingStatus} value={currentValue("secondaryFullName")} placeholder={"Full Name"} name={"secondaryInsuredFullName"} readOnly={readOnlyStatus}/>
                            }
                            {secondary && 
                                <InputField loadingStatus={loadingStatus} value={currentValue("secondaryGender")} placeholder={"Gender"} name={"secondaryInsuredGender"} readOnly={readOnlyStatus}/>
                            }
                        </div>

                        <div className={secondary ? "quadFormFieldRow" : "doubleFormFieldRow"}>
                            <InputField loadingStatus={loadingStatus} value={dateFormat(currentValue("primaryBirthDate"))} placeholder={"Date of Birth"} name={"primaryInsuredDOB"} readOnly={readOnlyStatus}/>
                            <InputField loadingStatus={loadingStatus} value={currentValue("primaryAge")} placeholder={"Age"} name={"primaryAge"} readOnly={readOnlyStatus}/>
                            {secondary &&
                                <InputField loadingStatus={loadingStatus} value={dateFormat(currentValue("secondaryBirthDate"))} placeholder={"Date of Birth"} name={"secondaryInsuredDOB"} readOnly={readOnlyStatus}/>
                            }
                            {secondary &&
                                <InputField loadingStatus={loadingStatus} value={currentValue("secondaryAge")} placeholder={"Age"} name={"secondaryAge"} readOnly={readOnlyStatus}/>
                            }
                        </div>
                    </div>
                    <div className="policyDetailsHeader g bold cL fR">
                        <div className="cC">
                            Policy
                        </div>
                        {currentBidInfo?.policyNumber &&
                        <div className="f cC">
                            {currentBidInfo?.policyNumber}
                        </div>}
                        {currentBidInfo?.leadSource && props?.session?.user?.data?.accountID !== 25 && 
                            <div className={"cL g f bR ns"}>
                                {leadSourceTag(currentBidInfo?.leadSource)}
                            </div>
                        }
                        {currentBidInfo?.sourceName && props?.session?.user?.data?.accountID !== 25 && 
                            <div className={"cL g f bR ns"}>
                                {leadSourceTag(currentBidInfo?.sourceName)}
                            </div>
                        }
                    </div>
                    <div key="form2" className="formCanvas">
                        <div className="doubleFormFieldRow">
                            <InputField loadingStatus={loadingStatus} value={currentValue("shippingStatus")} placeholder={"Status"} name={"shippingStatus"} readOnly={readOnlyStatus} phoneNumber={true}/>
                            <InputField loadingStatus={loadingStatus} value={currentValue("policyType")} placeholder={"Policy Type"} name={"policyType"} readOnly={readOnlyStatus}/>
                        </div>

                        <div className="doubleFormFieldRow">
                        <InputField loadingStatus={loadingStatus} value={currentValue("insuranceCompany")} placeholder={"Insurance Company"} name={"policyInsuranceCompany"} readOnly={readOnlyStatus}/>
                        <InputField loadingStatus={loadingStatus} value={dateFormat(currentValue("issueDate"))} placeholder={"Issue Date"} name={"policyIssueDate"} readOnly={readOnlyStatus}/>
                        </div>
                        <div className="doubleFormFieldRow">
                            <InputField loadingStatus={loadingStatus} value={currentValue("clarinetRecordID")} placeholder={"Policy ID"} name={"clarinetRecordID"} readOnly={readOnlyStatus}/>
                            {props?.session?.user?.data?.accountID !== 25 ?
                                <InputField loadingStatus={loadingStatus} value={currentValue("leadSource")} placeholder={"Source Name"} name={"policyLeadSourceName"} readOnly={readOnlyStatus}/>
                            :
                                <InputField loadingStatus={loadingStatus} value={currentValue("deathBenefit")} placeholder={"Death Benefit"} name={"deathBenefit"} readOnly={readOnlyStatus}/>
                            }
                        </div>
                        {props?.session?.user?.data?.accountID !== 25 &&
                            <div className="doubleFormFieldRow">
                                <InputField loadingStatus={loadingStatus} value={currentValue("deathBenefit")} placeholder={"Death Benefit"} name={"deathBenefit"} readOnly={readOnlyStatus}/>
                            </div>         
                        }

                    </div>
                    {props?.sessionUser?.accountData?.[props?.sessionUser?.accountID]?.teamList?.find((teamMember) => teamMember.userID === props?.sessionUser?.userID)?.isAbacusEmployee &&
                        (
                            <div className="policyDetailsHeader bold cL">
                                <span className="e">
                                    Case Assistance
                                </span>
                            </div>
                        )
                    }
                    {props?.sessionUser?.accountData?.[props?.sessionUser?.accountID]?.teamList?.find((teamMember) => teamMember.userID === props?.sessionUser?.userID)?.isAbacusEmployee && (
                        <div key={6} className="formCanvas">
                            <div className="trioFormFieldRow">
                                <InputField loadingStatus={loadingStatus} value={currentValue("accountManager")} placeholder={"Account Manager"} name={ "accountManager"} readOnly={ true }/>
                                <InputField loadingStatus={loadingStatus} value={currentValue("caseProcessor")} placeholder={"Case Processor"} name={ "caseProcessor"} readOnly={ true }/>
                                <InputField loadingStatus={loadingStatus} value={currentValue("pam")} placeholder={"Case Owner"} name={ "pam"} readOnly={ true }/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    function currentViewActivity(){
        if(currentView === "timeline"){
            return printTimeline();
        }
        if(currentView === "premiumSchedule"){
            return printBidPremiums();
        }
        if(currentView === "lifeExpectancies"){
            return printLifeExpectancies();
        }
        if(currentView === "documents"){
            return (
                <div className="documentsBlock f">
                    <div className="documentsBlockInner">
                        {!shareLink &&
                            <div className="loadingDrive">
                                <div className="loadingDrivePrompt">Loading Drive</div>
                                <DriveFolderUploadIcon/>
                            </div>
                        }
                        {shareLink === "empty" &&
                            <div className="loadingDrive">
                                <div className="loadingDrivePrompt">
                                    No directory found. If you would like to create the folders, please contact us.
                                </div>
                                <FolderOffIcon/>
                            </div>
                        }
                        {shareLink !== "empty" &&
                            <iframe className="singlePolicyDocuments" security="restricted" src={shareLink}/>
                        }
                    </div>
                </div>
            )
        }
        if(currentView === "messages"){
            return (
                <div className="communicationWrapper g f pR">
                    <div className="messageInfoHeader g cC">
                        <div className="policyDetailsHolderTag">
                            <QuestionAnswerIcon/>
                        </div>
                        <span>{ currentBidInfo?.policyNumber + " Messages" }</span>
                    </div>
                    <MessageBoard
                        relatedPolicyID={params.recordID}
                        sessionUser={props.sessionUser}
                        loadNotifications={props.loadNotifications}
                        markSessionPolicyAsRead={props.markSessionPolicyAsRead}
                        policyData={currentBidInfo}
                        loadingStatus={loadingStatus}
                        pullType="shipping"
                        sessionVars={props.sessionVars}
                        session={props?.session}
                    />
                </div>
            )
        }

        if(currentView === "details" && props.mobileDevice){
            return printDetails();
        }

    }

    function displayHolderTag(){
        if(secondary){
            return (
                <div className="holderTag">
                    <GroupIcon/>
                </div>
            )
        }else{
            return (
                <div className="holderTag">
                    <PersonIcon/>
                </div>
            )
        }
    }

    function premiumOptionsPanel(){
        let returningData = [
            <div className="f g cC">
                <div className="cC">
                    No premium current available Premium Schedules
                </div>
            </div>
        ];

        if(bidPremiumData.length > 0){
            returningData = [];
            let x = 0;
            const handleClick = (value) => {
                return () => {
                  setSelectedPremiumSchedule(value);
                };
            };

            while(x < Object.keys(bidPremiumData).length){
                returningData.push(
                    <div key={x} className="data fR bR c p" onClick={handleClick(x)}>
                        <div className="f g cC">
                            {"Option " + (x + 1)}
                        </div>
                    </div>
                )
                x++;
            }
        }
        return (
            <div className="premiumOptionsPanelWrap f" onClick={(e)=>{handleClose(e)}}>
                <div className="premiumOptionsPanel moduleBlock g bR cC fC dP ns">
                    <div className="header g cC fR">
                        <div className="gC2">
                            Available Premium Schedules
                        </div>
                        <div className="f cR">
                            <div className="btn cC p" onClick={()=> {setShowPremiumOptions(false)}}>
                                <CloseIcon/>
                            </div>
                        </div>
                    </div>
                    {bidPremiumData.length > 0 ?
                        <div className="list g cC fC bR f t">
                            {returningData}
                        </div>
                        :
                        <div className="empty bold g cC fC bR f t">
                            {returningData}
                        </div>
                    }
                </div>
            </div>
        )
    }

    function printTabs(){
        let returningTabBar = [];
        let tabCount = 0;
        let timelineClass = "switchViewBtn f g cC s e";
        if(timelineNotificationCount > 0){
            timelineClass += " alert";
        }

        if(props.mobileDevice){
            returningTabBar.push(
                <div key="detailsTab" className={"switchViewBtn f g cC s e" + currentActivity("details")} onClick={() => {updateActivityView("details")}}>
                    <div className="switchViewBtnOption">
                        Details
                    </div>
                    <div className="inlineBtnIcon"><FolderIcon/></div>
                </div>
            )
            tabCount++;
        }

        returningTabBar.push(
            <div key="timeline" className={timelineClass + currentActivity("timeline")} onClick={() => {updateActivityView("timeline")}}>
                    {timelineNotificationCount > 0 &&
                        <div className="notificationBubble cC">
                            <FiberNewIcon/>
                        </div>
                    }
                <div className="switchViewBtnOption">
                    Timeline
                </div>
                <div className="inlineBtnIcon"><TimelineIcon/></div>
            </div>
        )

        tabCount++;

        returningTabBar.push(displayTabs());
        tabCount = tabCount + 4;

        return (
            <div className={switchViewBtnContainer(tabCount)}>
                {returningTabBar}
            </div>
        )
    }

    function switchViewBtnContainer(tabCount){
        let className = "switchViewBtnContainer g f tabCount" + tabCount;
        return className;
    }

    function handleClose(evt){
        if (!evt.target.classList.contains('caseOptions') && !evt.target.closest('.caseOptions')) {
            setShowCaseOptions(false);
        }

        if (!evt.target.classList.contains('timelinePostDetails') && !evt.target.closest('.timelinePostDetails')) {
            setShowStatusDetail(false);
        }

        if (!evt.target.classList.contains('bidAlterPanel') && !evt.target.closest('.bidAlterPanel')) {
            setShowBidAlterPanel(false);
        }

        if (!evt.target.classList.contains('premiumOptionsPanel') && !evt.target.closest('.premiumOptionsPanel')) {
            setShowPremiumOptions(false);
        }
    }

    function udateKanbanReadStatus(data){
        const currentBidIndex = props.bidProps?.bidsList.findIndex((bid) => {
            return bid.recordID === data.recordID;
        });

        const updatedBidsList = [...props.bidProps.bidsList];
        const currentBid = updatedBidsList[currentBidIndex];

        if (!currentBid) {
            return;
        }

        currentBid.numActivitiesUnread = 0;

        props.bidProps.setBidsList(updatedBidsList);
    }

    function updateKanbanAlert(attribute){
        const currentBidIndex = props.bidProps?.bidsList.findIndex(
            (bid) => bid.recordID === currentBidInfo?.recordID
        );

        const updatedBidsList = [...props?.bidProps?.bidsList];
        const currentBid = updatedBidsList?.[currentBidIndex];

        if(attribute === "clear"){
            currentBid.numOfUnreadRequestResponses = 0;
            currentBid.numOfUnreadBidResponses = 0;
        }else if(attribute === "unread"){
            currentBid.numActivitiesUnread += 1;
        }else{
            currentBid.numActivitiesUnread += 1;
            currentBid[attribute] = currentBid[attribute] + 1;
        }

        props.bidProps.setBidsList(updatedBidsList);
    }

    function printDetails(){
        return (
            [
                <div key="summaryStatement" className="policyDetailsCardTitle g cC fC dG">
                    <div className="bidSummary g cC fC fR f">
                        {!currentBidInfo &&
                            <div className="summaryStatement g load cC">
                                <div className="loadingProgress">
                                    <CircularProgress color="inherit"/>
                                </div>
                            </div>
                        }
                        {currentBidInfo &&
                            <div
                                key={"originatorStatement"}
                                className={`summaryStatement g cC${currentBidInfo?.marketPrice !== undefined ? " alt" : ''}`}
                            >
                                <div className="f g cC data">
                                    <div className="summaryStatementTitle">
                                        {currentBidInfo?.marketPrice !== undefined ?
                                            <div className="bold f cC">
                                                Market Price
                                            </div>
                                        :
                                            currentBidInfo?.bidDate ?
                                                "Your Current " + bidVerbiage
                                            :
                                                "No Current " + bidVerbiage
                                        }
                                    </div>
                                    <div className="summaryStatementBidValue cC s e">
                                        {currentBidInfo?.marketPrice !== undefined ?
                                            convertIntToCurrency(currentBidInfo?.marketPrice, props.mobileDevice)
                                        :
                                            <ThumbsUpDownIcon/>
                                        }
                                    </div>
                                </div>
                                {currentBidInfo?.marketPrice !== undefined &&
                                    <div className="vDivider">
                                    </div>
                                }
                                <div className="f g cC data">
                                    <div className="summaryStatementTitle">
                                        {`${currentBidInfo?.marketPrice !== undefined ? "Your " : ''}Date of ${bidVerbiage}`}
                                    </div>
                                    <div className="summaryStatementBidValue cC s e">
                                        {currentBidInfo?.bidDate ? dateFormat(currentBidInfo?.bidDate) : "-"}
                                    </div>
                                </div>
                                <div className="f g cC data">
                                    <div className="summaryStatementTitle">
                                        {`${currentBidInfo?.marketPrice !== undefined ? "Your " : ''}Amount`}
                                    </div>
                                    <div className="summaryStatementBidValue cC s e">
                                        {currentBidInfo?.grossBid ? convertIntToCurrency(currentBidInfo?.grossBid, props.mobileDevice) : "-"}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="policyInfoBar g f cC">
                        <div className="policyDetailsHolderTag">
                            {displayHolderTag()}
                        </div>
                        {props?.sessionUser?.accountData?.[props?.sessionUser?.accountID]?.teamList?.find((teamMember) => teamMember.userID === props?.sessionUser?.userID)?.isAbacusEmployee &&
                            <div className="f cL">
                                <a className="cR link" target="_blank" rel="noopener noreferrer" href={currentBidInfo?.qbLink}>
                                    View on Quickbase
                                </a>
                            </div>
                        }
                    </div>
                </div>,
                loadPolicyHolderData()
            ]
        )
    }

    function loadShareLinkData(){
        props?.sessionVars?.envVars?.caseHandler?.functions?.generateShareLinkData(
            props.session?.env?.functions?.buildFetchRequest,
            "secondary",
            params.recordID
        )
            .then(shareLink => {
                setShareLink(shareLink);
                setTaskShareLink(shareLink);
            })
            .catch(error => {
                console.error("Error generating share link data:", error);
            });
    }

    useEffect(() => {
        if(props?.bidsList && Object.keys(props?.bidsList)?.length > 0){
            let currentBid = props.bidsList?.find(bid=>{
                return bid.recordID == params.recordID }) || undefined
            setCurrentBidInfo(currentBid);
            setTempCurrentBidInfo(currentBid);
            loadShareLinkData()
            props.navigation?.setCurrentBid(currentBid);
            setLoadingStatus(false);
            if(currentBid?.secondaryInsuredFullName){
                setSecondary(true);
            }
        }
    }, [props?.bidsList]);

    useEffect(() => {
        if(props?.sessionUser?.accountData?.[props?.sessionUser?.accountID]?.isNetworker){
            setBidVerbiage("Interest");
        }
    }, [props?.sessionUser?.accountData?.[props?.sessionUser?.accountID]]);

    useEffect(() => {
        if(approvedTabs.includes(hash) && hash.substring(1) !== currentView){
            updateActivityView(hash);
        }

        if(!approvedTabs.includes(hash) && hash !== ''){
            updateActivityView("timeline");
        }
        // if(hash.substring(1) !== "documents"){
        //     setShareLink(caseShareLink);
        // }
    }, [hash]);

    useEffect(() => {
        if(props.mobileDevice){
            if(hash === ''){
                updateActivityView("details");
            }else{
                updateActivityView(hash);
            }
        }else{
            if(hash === ''){
                updateActivityView("timeline");
            }else{
                if(!props.mobileDevice && hash === "#details"){
                    updateActivityView("timeline");
                }else{
                    updateActivityView(hash);
                }
            }
        }
    }, [props.mobileDevice]);

    useEffect(() => {
        if (currentBidInfo && bidPremiumData) {
            const graphData = {
                [selectedPremiumSchedule]: bidPremiumData[selectedPremiumSchedule]?.premiumSchedule.map(data => ({...data}))
            };
            setPremiumChartData(graphData);
          }
    }, [bidPremiumData, currentBidInfo, selectedPremiumSchedule]);

    useEffect(() => {
        if(currentBidInfo){
            updateKanbanAlert("clear");
            udateKanbanReadStatus(currentBidInfo);
        }
    }, [currentBidInfo]);

    useEffect(() => {
        loadBidActivity();
        loadBidPremiums();
        loadBidLEs();

        if(hash === ''){
            if(props.mobileDevice){
                updateActivityView("details");
            }else{
                updateActivityView("timeline");
            }
        }else{
            if(!props.mobileDevice && hash === "#details"){
                updateActivityView("timeline");
            }else{
                updateActivityView(hash);
            }
        }
    }, []);

    return (
        <div className="policiesOverview moduleBlock">
            <div className="policiesDetails g">
                <div className="policiesDetailsBlock g">
                    {showCaseOptions &&
                        <div className="overlay f cC" onClick={(e)=>{handleClose(e)}}>
                            <CaseOptions
                                type="bid"
                                recordID={params.recordID}
                                recordKey={"recordID"}
                                closeFunction={setShowCaseOptions}
                                caseInfo={currentBidInfo}
                                caseList={props.bidProps.bidsList}
                                caseUpdater={props.bidProps.setBidsList}
                                sessionUser={props?.sessionUser}
                                sessionVars={props?.sessionVars}
                                session={props?.session}
                            />
                        </div>
                    }
                    {showPremiumOptions &&
                        premiumOptionsPanel()
                    }
                    {showPremiumChart && premiumChartData &&
                        <div className="singlePolicyGraph">
                            <LineChart
                                graphData={premiumChartData}
                                policyNumber={currentBidInfo?.policyNumber}
                                closeGraph={setShowPremiumChart}
                                singlePremium={true}
                                displayType="shipping"
                            />
                        </div>
                    }
                    {showBidAlterPanel &&
                        <div className="overlay f" onClick={(e)=>{handleClose(e)}}>
                            <BidAlterPanel
                                setShowBidAlterPanel={setShowBidAlterPanel}
                                selectedStatus={selectedStatus}
                                loadBidActivity={loadBidActivity}
                                currentBidInfo={currentBidInfo}
                                updatePage={props.sessionVars.updatePage}
                                sessionUser={props.sessionUser}
                                bidProps={props.bidProps}
                                bidVerbiage={bidVerbiage}
                                sessionVars={props?.sessionVars}
                                session={props?.session}
                            />
                        </div>
                    }
                    {showStatusDetail && Object.keys(bidActivityTimeline).length !== 0 && currentTimelinePost &&
                        <div className="overlay f" onClick={(e)=>{handleClose(e)}}>
                            <TimelinePostDetails
                                sessionVars={props.sessionVars}
                                sessionUser={props.sessionUser}
                                data={currentTimelinePost}
                                setShowStatusDetail={setShowStatusDetail}
                                updateKanbanAlert={updateKanbanAlert}
                                bidVerbiage={bidVerbiage}
                                session={props?.session}
                            />
                        </div>
                    }
                    <div className="singlePolicyBlockOptionBar g gR1 cC fR">
                        <div className="inlineActionBar g cL f">
                            <Link
                                to="/bids"
                                className="inlineNav g cC"
                                onClick={ () => {
                                    props.changeActivityView("dashboard")
                                }} >
                                <div className="goBackBtn">
                                    <div className="goBackBtnInner cC g f fR fC dP">
                                        <ArrowBackIosNewIcon/>
                                    </div>
                                </div>
                                <div className="inlineNavDirection">
                                    All Open Cases
                                </div>
                            </Link>
                        </div>
                        <div className="cR g fR fC f switchViewBtnContainerWrap">
                            { printTabs() }
                            <div className="f cC gC2 s e p" onClick={()=>{setShowCaseOptions(true)}}>
                                <div className="moreBtn cC fMP">
                                    <MoreHorizIcon/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="singlePolicysDetailsBoardWrap gR2 g">
                        { !props.mobileDevice &&
                            <div className="policyDetailsCard dP g dG">
                                { printDetails() }
                             </div>
                        }
                        <div className="currentActivity bR dP">
                            { currentViewActivity() }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleBid;
