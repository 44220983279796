import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import './envTemplate.css';
import './CSS/Overlays.css';
import './Components/Kanban/Kanban.css';
import './CSS/Reporting.css';
import './CSS/envTemplate.css';

import './dynamic.css';
import './dynamicMin.css';
import './dynamicMid.css';
import './dynamicMobile.css';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App/>
);
