import React, { useEffect, useState } from 'react';

function Initialization(props){
    const [loadHandler, setLoadHandler] = useState({
        reporting : false
    });

    function loadReportingData(session) {
        setLoadHandler(prevState => ({
            ...prevState,
            reporting: "pending"
        }));
    
        session?.env?.functions?.buildFetchRequest("user/reports")
        .then(response => response.json())
        .then(resData => {
            if (resData.status === 200) {
                // Process the reports to replace empty strings with undefined
                const processedReports = resData.reports.map(report => {
                    let newReport = {};
                    for (const key in report) {
                        newReport[key] = report[key] === '' ? undefined : report[key];
                    }
                    return newReport;
                });
    
                session?.reporting?.setData("allReports", null, processedReports);
                setLoadHandler(prevState => ({
                    ...prevState,
                    reporting: true
                }));
            } else {
                setLoadHandler(prevState => ({
                    ...prevState,
                    reporting: false
                }));
            }
        });
    }
    
    function resetLoaders(){
        setLoadHandler({
            reporting : false,
        });
    }

    const initialize = {
        loadHandler,
        setLoadHandler,
        loadReportingData,
        resetLoaders
    }

  return initialize;
};

export default Initialization;