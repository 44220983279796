import React, { useEffect, useState, useCallback } from 'react';

import ViewportHandler from "./Viewport.js";

const fetchBase = process.env.REACT_APP_FETCH_BASE;

function EnvVars(user){
  const sessionUser = user?.data;
  const userFunctions = user?.functions;

  let viewport = ViewportHandler();
  const [overlay, setOverlay] = useState();

  const [isTyping, setIsTyping] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedSetIsTypingFalse = useCallback(
    debounce(() => setIsTyping(false), 500), // Adjust the delay as needed
    []
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!['Shift', 'Control', 'Alt', 'Meta'].includes(event.key)) {
        setIsTyping(true);
        debouncedSetIsTypingFalse();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
        window.removeEventListener('keydown', handleKeyDown);
    };
}, [debouncedSetIsTypingFalse]);

  useEffect(() => {
    const handleDragStart = () => {
        setIsDragging(true);
    };

    const handleDragEnd = () => {
        setIsDragging(false);
    };

    document.addEventListener('dragstart', handleDragStart);
    document.addEventListener('dragend', handleDragEnd);

    return () => {
        document.removeEventListener('dragstart', handleDragStart);
        document.removeEventListener('dragend', handleDragEnd);
    };
  }, []);
  
  const functions = {
    convertFloatToPercentage,
    convertIntToCurrency,
    handleClose,
    updateInput,
    buildFetchRequest,
    reformatDate,
    calcAge,
    redirectLink,
    homepage,
    eligablePath
  }

  function convertFloatToPercentage(decimal) {
    return `${(decimal * 100).toFixed(2)}%`;
  }

  function convertIntToCurrency(value, mobileDevice, bold){
      if(isNaN(value)){
        return "$0";
      }
    
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
    
      let currencyValue = 'N/A';
    
      const condenseCurrencyValue = (n) => {
        if (n < 1e3) return n;
        if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
        if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
        if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
        if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
        return 0;
      };
    
      if (value !== null) {
        currencyValue = formatter.format(Math.floor(value));
      }
      
      if (bold && !/^(\$0|N\/A)$/.test(currencyValue)) {
        if(mobileDevice){
          return (
            <b key="cashValue">
              ${condenseCurrencyValue(Math.floor(value))}
            </b>
          );
        }else{
          return [
            <b key="cashValue">
              ${currencyValue}
            </b>
          ];
        }
      }else {
        return mobileDevice ? "$" + condenseCurrencyValue(Math.floor(value)) : currencyValue;
      }
  }

  function handleClose(evt, overlay, setter) {
    if (
      !evt.target.classList.contains(overlay) &&
      !evt.target.closest(overlay) &&
      !evt.target.closest(`.${overlay} *`)
    ) {
      setter();
    }
  }

  const handleFetch = (fetch) => {
    return async function(url, options) {
      const response = await fetch(url, options);
      const clone = response.clone();
      const data = await clone.json();

      if(data.status === 500){
        console.log(data);
      }
  
      if (response.status === 401 || data.status === 401 || data.status === 509 || data.error == "Unauthorized" || response?.statusText === "Unauthorized") {
        console.log("Session timed out.");
        userFunctions?.logout();
      }

      return response;
    };
  };


  function authorizeRequest(call) {
    const storedSessionUser = JSON.parse(window.localStorage.getItem('sessionUser'));

    if (!sessionUser || !sessionUser?.sessionToken) {
      userFunctions?.logout();

      return "Unauthorized";
    }

    const currentToken = parseJWT(sessionUser?.sessionToken);
    
    if (currentToken === "Unauthorized" || ["userID", "email", "accountID"].some(attrName =>
      currentToken[attrName] !== sessionUser?.[attrName] ||
      currentToken[attrName] !== storedSessionUser?.[attrName]
    )) {
      userFunctions?.logout();

      return "Unauthorized";
    }

    return sessionUser.sessionToken;
  }

  function buildRequestOptions(bodyParams, directHeaders, directContentType){
    let defaultContentType = directContentType || "application/json";

    return {
      method: "POST",
      headers: {
        ...(directHeaders || {}),
        "Content-Type": defaultContentType
      },
      body: JSON.stringify({
        ...(bodyParams || {}),
        sessionToken: authorizeRequest(sessionUser?.sessionToken)
      })
    };
  }

  // function buildFetchRequest(url, params, headers, contentType) {
  //   // Determine if the response is expected to be non-JSON (like application/pdf or text/csv)
  //   const isNonJsonResponse = headers?.Accept && ["text/csv", "application/pdf"].includes(headers.Accept);

  //   const fetchHandler = isNonJsonResponse ? window.fetch : handleFetch(window.fetch);
  //   const requestOptions = buildRequestOptions(params, headers, contentType);
  
  //   return fetchHandler(fetchBase + url, requestOptions)
  //       .then(response => {
  //         // Check the response status
  //         if (!response.ok) {
  //           // throw new Error('Network response was not ok');
  //           return response;
  //         }
  //         if (isNonJsonResponse) {
  //           return response.blob(); // If it's non-JSON, get the blob directly.
  //         }
  //         return response;  // Otherwise, return the original response (JSON is parsed in handleFetch).
  //       })
  //       .catch(error => {
  //         console.error('Fetch error:', error);
  //         throw error; // Re-throw the error to propagate it.
  //       });
  // }

  function buildFetchRequest(url, params, headers, contentType) {
    // Include 'application/zip' in the check for non-JSON response types
    const isNonJsonResponse = headers?.Accept && ["text/csv", "application/pdf", "application/zip"].includes(headers.Accept);
  
    const fetchHandler = isNonJsonResponse ? window.fetch : handleFetch(window.fetch);
    const requestOptions = buildRequestOptions(params, headers, contentType);
  
    return fetchHandler(fetchBase + url, requestOptions)
      .then(response => {
        if (!response.ok) {
          return response; // Return the response to handle errors outside this function
        }
        if (isNonJsonResponse) {
          return response.blob(); // Handle non-JSON responses by returning a blob
        }
        return response; // Return the original response for further handling
      })
      .catch(error => {
        console.error('Fetch error:', error);
        throw error; // Re-throw the error to allow further handling
      });
  }
  
  function parseJWT (sessionToken) {
    if(!sessionToken){
      return;
    }

    try {
        var base64Url = sessionToken?.split('.')[1];
        var base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window?.atob(base64)?.split('')?.map(function(c) {
            return '%' + ('00' + c?.charCodeAt(0)?.toString(16))?.slice(-2);
        })?.join(''));
    
        return JSON.parse(jsonPayload);
    } catch (e) {
        return "Unauthorized";
    }
  }

  function updateInput(evt, setter){
    setter(prev => ({...prev, [evt.target.name]: evt.target.value }));
  }

  function reformatDate(originalDate, type = false, viewType) {
    if(!originalDate){
      return null;
    }

    const dateObject = new Date(originalDate);

    // Check if the created Date object is valid
    if (isNaN(dateObject.getTime())) {
        return originalDate;  // Return the originalDate if it's not a valid date
    }

  
    if(!type){
      const [year, month, day] = originalDate?.split('-');

      const formattedDate = `${parseInt(month)}/${parseInt(day)}/${year}`;
    
      return formattedDate;
    }

    const now = new Date();
    const isMobile = viewType === "mobile";

    if(type === "timestamp") {
      return dateObject.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
    }else if(type === "friendly"){
      const diffInSeconds = Math.floor((now - dateObject) / 1000);
      const days = Math.floor(diffInSeconds / 86400);
      const months = Math.floor(days / 30);
      const years = Math.floor(days / 365);
  
      if (years > 0) return `${years} ${years > 1 ? 'years' : 'year'}${isMobile ? '' : " ago"}`;
      if (months > 0) return `${months} ${months > 1 ? 'months' : 'month'}${isMobile ? '' : " ago"}`;
      if (days > 1) return `${days} days${isMobile ? '' : " ago"}`;
      if (days === 1) return 'Yesterday';
      if (days === 0) return 'Today';
    }else{
      return dateObject.toLocaleDateString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    }
  }

  function calcAge(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
  
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
  
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age;
  }

  function redirectLink(link){
    sessionStorage.setItem('redirectLink', link);
  }

  function homepage(views = sessionUser?.views) {
    const safePath = "/profile";
    if(views === undefined){
      return safePath;
    }

    const prioritizedViews = ['Home', 'Policies', 'Leads', 'Bids', 'Upload', 'Reporting'];
    const orderedViews = prioritizedViews.filter(view => views.includes(view));

    const matchingView = orderedViews[0];
  
    if (matchingView) {
      const viewToRouteMapping = {
        Home: '/home',
        Policies : '/policies',
        Leads: '/leads',
        Bids: '/bids',
        Upload: '/upload',
        Reporting: '/reporting',
      };
  
      return viewToRouteMapping[matchingView];
    }
  
    return safePath;
  }

  function isPathEligible(path) {
    const eligablePages = ["home", "policies", "upload", "leads", "bids", "profile", "reporting"];
    const lowerCasePath = path?.toLowerCase();

    return eligablePages.some(page => page.toLowerCase() === lowerCasePath);
  }

  function isStringInUserViews(path) {
    if(path === "profile"){
      return true;
    }

    const userViews = sessionUser?.views || []; // Replace with actual user views array
    return userViews.some(view => view.toLowerCase() === path.toLowerCase());
  }

  function eligablePath(currentPath = window.location.pathname.match(/^\/([^/]+)/)?.[1] || null) {
    if(currentPath === "profile"){
      return true;
    }

    if (!isPathEligible(currentPath) ||
      !isStringInUserViews(currentPath) ||
      !userFunctions?.verifyAccess(currentPath)
    ){
      return false;
    }

    return true;
  }

  const envVars = {
      functions,
      overlay,
      setOverlay,
      viewport,
      isTyping,
      isDragging,
  }

  return envVars;
};

export default EnvVars;