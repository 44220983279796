import EnvVars from './EnvVars.js';
import SessionUser from './SessionUser.js';
import SessionCase from './SessionCase.js';
import Storage from './Storage.js';
import ShareLink from './ShareLink.js';
import Notifications from './Notifications.js';
import AnalyticVars from './AnalyticVars.js';
import BidVars from './BidVars.js';
import ReportingVars from './ReportingVars.js';
import DashboardVars from './DashboardVars.js';

function SessionVars(props){
    const sessionUser = SessionUser();
    const sessionCase = SessionCase();
    const storage = Storage();
    const shareLink = ShareLink();
    const notifications = Notifications();
    const analytics = AnalyticVars();
    const reporting = ReportingVars();
    const dashboard = DashboardVars();
    const bids = BidVars();
    const envVars = EnvVars(sessionUser);

    const sessionVars = {
        env : envVars,
        user : sessionUser,
        case : sessionCase,
        analytics,
        reporting,
        dashboard,
        bids,
        storage,
        shareLink,
        notifications
    }

    return sessionVars;
};

export default SessionVars;