import React, { useEffect, useState } from 'react';

function SessionCase(props){

  const attributeData = {
    accountManager: {
      formType: "generatedList",
      friendlyTerm: "Account Manager",
      list: {},
      stems: ["origination", "bids"],
      editable: true,
    },
    age: {
      formType: "int",
      friendlyTerm: "Age",
      stems: ["origination", "leads"],
      editable: true,
    },
    assignedUsersIDs: {
      formType: "int",
      friendlyTerm: "Assigned Users IDs",
      searchable: false,
      employeeOnly: true,
      stems: ["origination", "bids", "leads", "servicing"],
      editable: true,
    },
    amoName: {
      formType: "generatedList",
      friendlyTerm: "AMO Name",
      list: {},
      stems: ["origination"],
      editable: true,
    },
    averageLEMonths: {
      formType: "int",
      friendlyTerm: "Average LE Months",
      stems: ["bids"],
      editable: true,
    },
    assignedUsersList: {
      formType: "generatedList",
      friendlyTerm: "Assigned Users List",
      list: {},
      stems: ["origination"],
      editable: true,
    },
    bidDate: {
      formType: "date",
      friendlyTerm: "Bid Date",
      returnType: "date",
      stems: ["origination", "bids"],
      editable: true,
    },
    bookValue: {
      formType: "int",
      friendlyTerm: "Book Value",
      returnType: "currency",
      stems: ["servicing"],
      editable: true,
    },
    caseOwner: {
      formType: "generatedList",
      friendlyTerm: "Case Owner",
      list: {},
      stems: ["origination", "bids"],
      editable: true,
    },
    caseProcessor: {
      formType: "generatedList",
      friendlyTerm: "Case Processor",
      list: {},
      stems: ["origination"],
      editable: true,
    },
    clarinetRecordID: {
      formType: "string",
      friendlyTerm: "Policy ID",
      stems: ["bids"],
      employeeOnly: true,
      editable: true,
    },
    dateCreated: {
      formType: "date",
      friendlyTerm: "Date Created",
      returnType: "date",
      stems: ["bids"],
      editable: true,
    },
    deathBenefit: {
      formType: "int",
      friendlyTerm: "Death Benefit",
      returnType: "currency",
      stems: ["origination", "bids", "leads", "servicing"],
      editable: true,
    },
    deathBenefitType: {
      formType: "generatedList",
      friendlyTerm: "Death Benefit Type",
      list: {},
      stems: ["servicing"],
      editable: true,
    },
    earliestActivityDate: {
      formType: "date",
      friendlyTerm: "Earliest Activity Date",
      returnType: "date",
      stems: ["bids"],
      editable: true,
    },
    faceValue: {
      formType: "int",
      friendlyTerm: "Face Value",
      returnType: "currency",
      stems: ["origination", "leads"],
      editable: true,
    },
    grossBid: {
      formType: "int",
      friendlyTerm: "Gross Bid",
      returnType: "currency",
      stems: ["bids"],
      editable: true,
    },
    group: {
      formType: "string",
      friendlyTerm: "Group",
      stems: ["bids"],
      editable: true,
    },
    insuranceCompany: {
      formType: "generatedList",
      friendlyTerm: "Insurance Company",
      list: {},
      stems: ["origination", "bids", "servicing"],
      editable: true,
    },
    issueDate: {
      formType: "date",
      friendlyTerm: "Issue Date",
      returnType: "date",
      stems: ["origination", "bids", "servicing"],
      editable: true,
    },
    lastUpdate: {
      formType: "date",
      friendlyTerm: "Last Update",
      returnType: "date",
      stems: ["origination", "bids", "servicing"],
      editable: true,
    },
    leadSource: {
      formType: "generatedList",
      friendlyTerm: "Lead Source",
      list: {},
      stems: ["origination", "bids", "servicing"],
      editable: true,
    },
    marketBidCount: {
      formType: "int",
      friendlyTerm: "Market Bid Count",
      stems: ["origination"],
      editable: true,
    },
    marketValue: {
      formType: "int",
      friendlyTerm: "Market Value",
      returnType: "currency",
      stems: ["origination"],
      editable: true,
    },
    marketPrice: {
      formType: "int",
      friendlyTerm: "Market Price",
      returnType: "currency",
      stems: ["bids"],
      editable: true,
    },
    nationalAccountName: {
      formType: "generatedList",
      friendlyTerm: "Related Account Name",
      list: {},
      stems: ["origination"],
      editable: true,
    },
    numActivitiesUnread: {
      formType: "int",
      friendlyTerm: "Number of Activities Unread",
      stems: ["origination", "bids", "leads", "servicing"],
      editable: true,
    },
    numMessagesUnread: {
      formType: "int",
      friendlyTerm: "Number of Messages Unread",
      stems: ["origination", "bids", "servicing"],
      editable: true,
    },
    numOfOpenRequests: {
      formType: "int",
      friendlyTerm: "Number of Open Requests",
      stems: ["bids"],
      editable: true,
    },
    numOfRequestResponses: {
      formType: "int",
      friendlyTerm: "Number of Request Responses",
      stems: ["bids"],
      editable: true,
    },
    numOfUnreadBidResponses: {
      formType: "int",
      friendlyTerm: "Number of Unread Bid Responses",
      stems: ["bids"],
      editable: true,
    },
    numUnreadBids: {
      formType: "int",
      friendlyTerm: "Number of Unread Bids",
      stems: ["origination"],
      editable: true,
    },
    pam: {
      formType: "generatedList",
      friendlyTerm: "PAM",
      list: {},
      stems: ["origination", "bids"],
      editable: true,
    },
    percentOfSTOLI: {
      formType: "float",
      friendlyTerm: "% of STOLI",
      returnType: "percentage",
      stems: ["servicing"],
      accessList : [10],
      editable: true,
    },
    policyLoanAmount: {
      formType: "int",
      friendlyTerm: "Policy Loan Amount",
      returnType: "currency",
      stems: ["origination"],
      editable: true,
    },
    policyNumber: {
      formType: "string",
      friendlyTerm: "Policy Number",
      stems: ["origination", "bids", "servicing"],
      editable: true,
    },
    policyStatus: {
      formType: "generatedList",
      friendlyTerm: "Policy Status",
      stems: ["origination", "bids", "servicing"],
      editable: true,
      list: {},
    },
    policyType: {
      formType: "generatedList",
      list: {},
      friendlyTerm: "Policy Type",
      stems: ["origination", "bids", "leads", "servicing"],
      editable: true,
    },
    position: {
      formType: "dropdown",
      list: ["Active", "Closed", "Inactive"],
      friendlyTerm: "Position",
      stems: ["origination", "leads"],
      editable: true,
    },
    primaryBirthDate: {
      formType: "date",
      friendlyTerm: "Primary Birth Date",
      returnType: "date",
      stems: ["origination", "bids", "servicing"],
      editable: true,
    },
    primaryDateOfDeath: {
      formType: "date",
      friendlyTerm: "Primary Date of Death",
      returnType: "date",
      stems: ["servicing"],
      editable: true,
    },
    primaryEmail: {
      formType: "string",
      friendlyTerm: "Primary Email",
      stems: ["origination"],
      editable: true,
    },
    primaryFullName: {
      formType: "string",
      friendlyTerm: "Primary Full Name",
      stems: ["bids"],
      editable: true,
    },
    primaryGender: {
      formType: "dropdown",
      list: ["Male", "Female"],
      friendlyTerm: "Primary Gender",
      stems: ["origination", "bids", "servicing"],
      editable: true,
    },
    primaryPhoneNumber: {
      formType: "string",
      friendlyTerm: "Primary Phone Number",
      stems: ["origination"],
      editable: true,
    },
    purchaseIRR: {
      formType: "float",
      friendlyTerm: "Purchase IRR",
      returnType: "percentage",
      stems: ["servicing"],
      accessList : [8, 10],
      editable: true,
    },
    qbLink: {
      formType: "string",
      friendlyTerm: "QB Link",
      searchable: false,
      employeeOnly: true,
      returnType: "link",
      stems: ["origination", "bids", "leads", "servicing"],
      editable: true,
    },
    recordID: {
      formType: "int",
      friendlyTerm: "Record ID",
      stems: ["origination", "bids", "servicing"],
      editable: true,
      employeeOnly: true,
    },
    relatedAccountID: {
      formType: "int",
      friendlyTerm: "Related Account ID",
      searchable: false,
      employeeOnly: true,
      stems: ["origination", "bids", "servicing"],
      editable: true,
    },
    relatedAMOID: {
      formType: "generatedList",
      friendlyTerm: "Related AMO ID",
      searchable: false,
      employeeOnly: true,
      list: {},
      stems: ["origination"],
      editable: true,
    },
    relatedNationalAccountID: {
      formType: "generatedList",
      friendlyTerm: "Related National Account ID",
      searchable: false,
      employeeOnly: true,
      list: {},
      stems: ["origination"],
      editable: true,
    },
    relatedFundID: {
      formType: "int",
      friendlyTerm: "Related Fund ID",
      searchable: false,
      employeeOnly: true,
      stems: ["bids", "servicing"],
      editable: true,
    },
    relatedPolicyID: {
      formType: "int",
      friendlyTerm: "Related Policy ID",
      searchable: false,
      employeeOnly: true,
      stems: ["origination", "bids"],
      editable: true,
    },
    relatedPrimaryID: {
      formType: "int",
      friendlyTerm: "Related Primary ID",
      searchable: false,
      employeeOnly: true,
      stems: ["origination", "bids", "servicing"],
      editable: true,
    },
    relatedSecondaryID: {
      formType: "int",
      friendlyTerm: "Related Secondary ID",
      searchable: false,
      employeeOnly: true,
      stems: ["origination", "bids", "servicing"],
      editable: true,
    },
    relatedSourceID: {
      formType: "generatedList",
      friendlyTerm: "Related Source ID",
      searchable: false,
      employeeOnly: true,
      list: {},
      stems: ["origination"],
      editable: true,
    },
    secondaryBirthDate: {
      formType: "date",
      friendlyTerm: "Secondary Birth Date",
      returnType: "date",
      stems: ["origination", "bids", "servicing"],
      editable: true,
    },
    secondaryDateOfDeath: {
      formType: "date",
      friendlyTerm: "Secondary Date of Death",
      returnType: "date",
      stems: ["servicing"],
      editable: true,
    },
    secondaryFullName: {
      formType: "string",
      friendlyTerm: "Secondary Full Name",
      stems: ["bids"],
      editable: true,
    },
    secondaryGender: {
      formType: "string",
      friendlyTerm: "Secondary Gender",
      stems: ["origination", "bids", "servicing"],
      editable: true,
    },
    shippingStatus: {
      formType: "generatedList",
      friendlyTerm: "Shipping Status",
      list: {},
      stems: ["bids"],
      editable: true,
    },
    sourceName: {
      formType: "generatedList",
      friendlyTerm: "Source Name",
      list: {},
      stems: ["origination", "bids"],
      editable: true,
    },
    ownerName: {
      formType: "generatedList",
      friendlyTerm: "Owner Name",
      list: {},
      stems: ["servicing"],
      editable: true,
    },
    stage: {
      formType: "generatedList",
      friendlyTerm: "Stage",
      list: {},
      stems: ["bids"],
      editable: true,
    },
    winningBid: {
      formType: "int",
      friendlyTerm: "Winning Bid",
      returnType: "currency",
      stems: ["bids"],
      editable: true,
    },
    ownerSitusState: {
      formType: "dropdown",
      list: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
      friendlyTerm: "Owner Situs State",
      stems: ["origination"],
      editable: true,
    },
    ownerType: {
      formType: "generatedList",
      editable: true,
      list: {},
      friendlyTerm: "Owner Type",
      stems: ["origination"],
      editable: true,
    },
    nextStep: {
      formType: "string",
      friendlyTerm: "Next Step",
      stems: ["origination"],
      editable: true,
    },
    PVSID: {
      formType: "int",
      friendlyTerm: "PVSID",
      searchable: false,
      employeeOnly: true,
      stems: ["origination"],
      editable: true,
    },
    submissionDate: {
      formType: "date",
      friendlyTerm: "Submission Date",
      returnType: "date",
      stems: ["origination"],
      editable: true,
    },
    soldDate: {
      formType: "date",
      friendlyTerm: "Sold Date",
      returnType: "date",
      stems: ["servicing"],
      editable: true,
    },
    timeStamp: {
      formType: "date",
      friendlyTerm: "Time Stamp",
      returnType: "date",
      stems: ["origination"],
      editable: true,
    },
    totalAmountPaid: {
      formType: "int",
      friendlyTerm: "Total Amount Paid",
      returnType: "currency",
      stems: ["servicing"],
      editable: true,
    },
    numQualifiedFunds: {
      formType: "int",
      friendlyTerm: "Number of Qualified Funds",
      stems: ["origination"],
      editable: true,
    },
    numUnqualifiedFunds: {
      formType: "int",
      friendlyTerm: "Number of Unqualified Funds",
      stems: ["origination"],
      editable: true,
    },
    numActivitiesUnread: {
      formType: "int",
      friendlyTerm: "Number of Activities Unread",
      stems: ["origination", "bids", "leads", "servicing"],
      editable: true,
    },
    numMessagesUnread: {
      formType: "int",
      friendlyTerm: "Number of Messages Unread",
      stems: ["origination", "bids", "servicing"],
      editable: true,
    },
    numFundsShown: {
      formType: "int",
      friendlyTerm: "Number of Funds Shown",
      stems: ["origination"],
      editable: true,
    },
    readStatus: {
      formType: "dropdown",
      list: ["true", "false"],
      friendlyTerm: "Read Status",
      stems: ["origination", "servicing"],
      editable: true,
    },
    accountName: {
      formType: "generatedList",
      friendlyTerm: "Fund Name",
      list: {},
      stems: ["origination", "bids"],
      editable: true,
    },
    minimumVOCDate: {
      formType: "date",
      friendlyTerm: "Minimum VOC Date",
      returnType: "date",
      stems: ["servicing"],
      editable: true,
    },
    pricingMethod: {
      formType: "generatedList",
      list: {},
      friendlyTerm: "Pricing Method",
      stems: ["servicing"],
      editable: true,
    },
    servicingStartDate: {
      formType: "date",
      friendlyTerm: "Servicing Start Date",
      returnType: "date",
      stems: ["servicing"],
      editable: true,
    },
    loanAmount: {
      formType: "int",
      friendlyTerm: "Loan Amount",
      returnType: "currency",
      stems: ["origination"],
      editable: true,
    },
    type: {
      formType: "dropdown",
      list: ["Origination", "Fund"],
      friendlyTerm: "Type",
      searchable: false,
      employeeOnly: true,
      stems: ["origination", "servicing"],
      editable: true,
    },
    primaryFirstName: {
      formType: "string",
      friendlyTerm: "Primary First Name",
      stems: ["origination", "servicing"],
      editable: true,
    },
    primaryLastName: {
      formType: "string",
      friendlyTerm: "Primary Last Name",
      stems: ["origination", "servicing"],
      editable: true,
    },
    primaryBirthDate: {
      formType: "date",
      friendlyTerm: "Primary Birth Date",
      returnType: "date",
      stems: ["origination", "bids", "servicing"],
      editable: true,
    },
    purchaseDate: {
      formType: "date",
      friendlyTerm: "Purchase Date",
      returnType: "date",
      stems: ["servicing"],
      editable: true,
    },
    purchaseCost: {
      formType: "int",
      friendlyTerm: "Purchase Cost",
      returnType: "currency",
      stems: ["servicing"],
      editable: true,
    },
    secondaryFirstName: {
      formType: "string",
      friendlyTerm: "Secondary First Name",
      stems: ["origination", "servicing"],
      editable: true,
    },
    secondaryLastName: {
      formType: "string",
      friendlyTerm: "Secondary Last Name",
      stems: ["origination", "servicing"],
      editable: true,
    },
    secondaryGender: {
      formType: "string",
      friendlyTerm: "Secondary Gender",
      stems: ["origination", "bids", "servicing"],
      editable: true,
    },
    secondaryBirthDate: {
      formType: "date",
      friendlyTerm: "Secondary Birth Date",
      returnType: "date",
      stems: ["origination", "bids", "servicing"],
      editable: true,
    },
    soldPrice: {
      formType: "int",
      friendlyTerm: "Sold Price",
      returnType: "currency",
      stems: ["servicing"],
      editable: true,
    },
    lsInsuredIDPrimary: {
      formType: "int",
      friendlyTerm: "LS Insured ID Primary",
      searchable: false,
      employeeOnly: true,
      stems: ["origination"],
      editable: true,
    },
    lsInsuredIDSecondary: {
      formType: "int",
      friendlyTerm: "LS Insured ID Secondary",
      searchable: false,
      employeeOnly: true,
      stems: ["origination"],
      editable: true,
    },
  };

  const [data, setData] = useState({
    policies: {
      origination: undefined,
      fund: undefined,
    },
    bids: {
      secondary: undefined,
      tertiary: undefined,
    },
    leads: undefined,
    attributeData
  });

  const updateCase = (path, childObject) => {
    setData((prevData) => {
      const newData = { ...prevData };

      // Split the path into segments
      const pathSegments = path.split('.');

      // Traverse the path and create nested objects (arrays) as needed
      let current = newData;
      for (const segment of pathSegments) {
        if (!current[segment]) {
          // Create an empty array if the current segment is undefined
          current[segment] = [];
        }
        current = current[segment];
      }

      // Push the childObject into the final nested array
      current.push(childObject);

      return newData;
    });
  };

  const updateCases = (results, branch, stem) => {
    // Create a copy of the data state to work with
    const newData = { ...data };
    // Ensure the stem exists in your data structure
    if (newData?.[branch]?.[stem]) {
      // Map through the array to create a new array for immutability
      newData[branch][stem] = newData?.[branch]?.[stem].map(caseObj => {
        // Find the result that matches this caseObj by recordID
        const resultToUpdate = results.find(result => result?.recordID === caseObj?.recordID);

        // If a matching result is found, create a new object with updated properties
        if (resultToUpdate) {
          return {
            ...caseObj,
            ...Object.keys(resultToUpdate).reduce((acc, key) => {
              if (key !== 'recordID') { // Exclude recordID from updates
                acc[key] = resultToUpdate?.[key];
              }
              return acc;
            }, {})
          };
        }

        // If no matching result, return the caseObj unchanged
        return caseObj;
      });
    }

    // Set the new data state with the updated array
    setData(newData);
  };

  function updateAttributeData(obj, type) {
    // Create a shallow copy of the data object
    const newData = { ...data };
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        const targetType = obj.type ?? type;
        if (newData.attributeData?.[key]?.formType === "generatedList") {
          // Check if the list exists in attributeData
          if (!newData.attributeData[key].list[targetType]) {
            newData.attributeData[key].list[targetType] = {}; // Create an empty object for the targetType if it doesn't exist
          }
  
          if (!newData.attributeData?.[key]?.list?.[targetType]?.[value]) {
            newData.attributeData[key].list[targetType][value] = {
              count: 1,
              [type] : true,
            }; // Create an empty object if it doesn't exist
          } else {
            newData.attributeData[key].list[targetType][value].count++; // Increment the count if it exists
          }
        }
      }
    }
  
    // Update the entire data object with the modified attributeData
    setData(newData);
  }

  const functions = {
    updateAttributeData,
    updateCases
  }

  const sessionCase = {
    data,
    setData : updateCase,
    functions,
  }

  return sessionCase;
};

export default SessionCase;