import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom'

import Logo from '../Images/AbacusLifeLogo.png';

import './NavBar.css';

import SideBar from '../Template/SideBar.js';

import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';

import { detectHomePage } from '../envFunctions.js';

function NavBar(props){
  const navigate = useNavigate();
  //const currentURL = window.location.pathname.replace(/\//g, '');
  const currentPath = window.location.pathname.match(/^\/([^/]+)/)?.[1] || null;
  const [subPageData, setSubPageData] = useState([]);
  const [expandedPages, setExpandedPages] = useState(false);
  const [bidVerbiage, setBidVerbiage] = useState("Bid");
  let { id } = useParams();
  const location = useLocation();

  function displayCurrentPage(){
    let currentPage = props?.session?.user?.data?.currentPage;
    if(!currentPage){
      return;
    }

    let displayText = currentPage[0].toUpperCase() + currentPage.slice(1);

    if(bidVerbiage === "Interest" && displayText === "Bids"){
      displayText = "Cases";
    }

    return displayText;
  }

  // function currentPath(){
  //   let currentPage = location.pathname.split('/');
  //   return currentPage[1];
  // }

  function maintainSelectedPage(currentPage){
    if(currentPath === currentPage){
        return " selected";
    }else{
        return '';
    }
  }

  function displaySubPage(){
    let returnData = [];
    let subPageString;
    const path = window.location.pathname;
    const pathArray = path.split('/');

    if (pathArray.length < 3) {
      setSubPageData();
      if(props.navigation.currentPolicy){
        props.navigation.setCurrentPolicy();
      }
      if(props.navigation.currentLead){
        props.navigation.setCurrentLead();
      }
      if(props.navigation.currentBid){
        props.navigation.setCurrentBid();
      }

      return;
    }

    if(props.navigation.currentPolicy){
      subPageString = props.navigation.currentPolicy.policyNumber;

      returnData.push(
        <div key={0} className="currentPageHandlerPath p dP" onClick={(e)=>{copySubPageData(e)}}>
          { subPageString }
        </div>
      );

      setSubPageData(returnData);
      return;
    }

    if(props.navigation.currentLead){
      subPageString = props.navigation.currentLead.leadFirstName + " " + props.navigation.currentLead.leadLastName;

      returnData.push(
        <div key={0} className="currentPageHandlerPath p dP" onClick={(e)=>{copySubPageData(e)}}>
          { subPageString }
        </div>
      );

      setSubPageData(returnData);
      return;
    }

    if(props.navigation.currentBid){
      subPageString = props.navigation.currentBid.policyNumber;

      returnData.push(
        <div key={0} className="currentPageHandlerPath p dP" onClick={(e)=>{copySubPageData(e)}}>
          { subPageString }
        </div>
      );

      setSubPageData(returnData);
      return;
    }

    setSubPageData();
  }

  function copySubPageData(e){
    const subPageElement = e.target;
    const subPageText = subPageElement.innerText;
    navigator.clipboard.writeText(subPageText);
  }

  function navigateHome(){
    navigate(props?.session?.env?.functions?.homepage());
  }

  function notificationsBell(notificationCount){
    return;
    if(notificationCount > 0){
      let notificationsBell = [];
      notificationsBell.push(
        <NotificationsActiveTwoToneIcon
          key={0}
          onClick={() => { props.showNotifications ? props.setShowNotifications(false) : props.setShowNotifications(true) }}
        />
      );
      notificationsBell.push(
        <div key={2} className="notificationBubble">
          <div className="notificationBubbleCount">
            <div className="notificationBubbleCountInner">
              {notificationCount}
            </div>
          </div>
        </div>
      );

      return notificationsBell;
    }else{
      return (
        <NotificationsNoneTwoToneIcon
          onClick={() => { props.showNotifications ? props.setShowNotifications(false) : props.setShowNotifications(true) }}
        />
      )
    }
  }

  useEffect(() => {
    displaySubPage();
  }, [props?.navigation]);

  useEffect(() => {
    if (!props?.session?.env?.functions?.eligablePath(currentPath)){
      navigateHome();
      window.history.replaceState(null, '', props?.session?.env?.functions?.homepage());
      props?.session?.user?.functions?.updateUserData("currentPage", props?.session?.env?.functions?.homepage().substring(1));

    } else {
      props?.session?.user?.functions?.updateUserData("currentPage", currentPath);
      // Continue with the current page, no redirection
    }
  }, [currentPath]);

  useEffect(() => {
    if(props?.sessionUser?.accountData?.[props?.sessionUser?.accountID]?.isNetworker){
        setBidVerbiage("Interest");
    }
  }, [props?.sessionUser?.accountData?.[props?.sessionUser?.accountID]]);

  return (
    <div className="navBar g">
      {expandedPages &&
        <div className="expandedPages moduleBlock g bR dP">
          <div className="pages g cL">
            <Link
              to="/profile"
              className={"sideBarBtn f fC fR g cC dP" + maintainSelectedPage("profile")}
              onClick={() => {
                props.changeActivityView("dashboard");
                setExpandedPages(false) }}
            >
              <AccountCircleTwoToneIcon/>
              <div className="f cL">
                Profile Settings
              </div>
            </Link>
            {/* <Link to="/profile#admin" className={"sideBarBtn f fC fR g cC dP" + maintainSelectedPage("profile#admin")} onClick={() => { props.updatePage("profile"); props.changeActivityView("dashboard"); setExpandedPages(false) } }>
              <ManageAccountsTwoToneIcon/>
              <div className="f cL">
                Admin Board
              </div>
            </Link> */}
          </div>
          <div className="divider">
          </div>
          <div className="logout cC">
            <div className="sideBarBtn f fC fR g cC dP" onClick={() => {props.session?.user?.functions?.logout()}}>
                <CancelTwoToneIcon/>
                <div className="f cL">
                  Logout
                </div>
            </div>
          </div>
        </div>
      }
      <div className="navPathHandler g">
        <div className="logo cC">
          <Link
            className="f cC"
            to={props?.session?.env?.functions?.homepage()}
            // onClick={() => {props.updatePage(detectHomePage(props?.sessionUser?.views)); props.changeActivityView("dashboard"); }}
          >
            <img src={Logo} alt="Abacus Life Logo"></img>
          </Link>
        </div>
        <div className="path g f fR cL s">
            {!props.mobileDevice &&
              <div className="abacusHeader">
                {/* <div className="sizeMin">
                  min
                </div>
                <div className="sizeMid">
                  mid
                </div>
                <div className="sizeMobile">
                  mobile
                </div> */}
                  Abacus Marketplace
              </div>
            }
            <div className="currentPageHandlerPath dP">
              {displayCurrentPage()}
            </div>
            { subPageData }
        </div>
      </div>
      <div className="pageSelection g fR fC cC">
        <SideBar
          sessionUser={props.sessionUser}
          activityView={props.activityView}
          changeActivityView={props.changeActivityView}
          currentPath={props.currentPath}
          updatePage={props.updatePage}
          mobileDevice={props.mobileDevice}
          showMobileMore={props.showMobileMore}
          setShowMobileMore={props.setShowMobileMore}
          maintainSelectedPage={maintainSelectedPage}
          session={props?.session}
        />
      </div>
      <div className="navHardButtons g cR">
        {/* <div className={props?.notifications?.count > 0 ? "notificationsBtn f cC active" : "notificationsBtn f cC inactive"}>
              {notificationsBell(props?.notifications?.count)}
        </div> */}
        {/* <div className="sideBarBtn f cC" onClick={()=>{setExpandedPages(!expandedPages)}}> */}
        <div className="sideBarBtn f cC" onClick={()=>{props?.session?.env?.setOverlay("settingsMenu");}}>
          <SettingsIcon/>
        </div>
      </div>
    </div>
  );
}

export default NavBar;