import React, { useEffect, useState } from 'react';

import PaidIcon from '@mui/icons-material/Paid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SsidChartIcon from '@mui/icons-material/SsidChart';

import { red, purple, blue, blueGrey, green, grey, orange} from '@mui/material/colors';

const fetchBase = process.env.REACT_APP_FETCH_BASE;

function EnvVars(user){
  const sessionUser = user;

  const filterFunctions = {
    createBinaryHandler,
    createRangeHandler,
    buildFilterTracker,
    initializeFilterBranch,
    updateFilterAttr,
    updateRangeData,
    alterRange,
    resetGroup,
    reorderGroup,
    initializeOrder,
    groupData
  }

  const caseFunctions = {
    buildShareLinkData,
    generateShareLinkData,
    updateCaseReadStatus
  }

  const [adminHandler, setAdminHandler] = useState();

  const localStorage = JSON.parse(window.localStorage.getItem('storage'));
  const policiesFilters = localStorage?.filters?.policies;
  const bidFilters = localStorage?.filters?.bids;
  const leadsFilters = localStorage?.filters?.leads;

  const [filterHandler, setFilterHandler] = useState({
      home :  localStorage?.filters?.home || {},
      policies :  localStorage?.filters?.policies || {},
      leads :  localStorage?.filters?.leads || {},
      bids :  localStorage?.filters?.bids || {},
      functions : filterFunctions
  });

  const [caseHandler, setCaseHandler] = useState({
    policies : {},
    leads : {},
    bids : {},
    functions : caseFunctions
  })

  function createBinaryHandler(stem, group) {
    const binaryObject = {
        activeCount: 0,
        maxCount: 0,
        emptyCount: 0,
        sort: "value",
        nested : groupData(group)?.nested
    }

    if(stem === "kanban"){
        return {
            kanban : {
                ...binaryObject
            },
            list : {
                ...binaryObject
            },
            type: "binary"
        }
    }

    return {
        ...binaryObject,
        type: "binary"
    };
  };

  function createRangeHandler() {
      const rangeObject = {
          activeCount: 0,
          maxCount: 0,
          minVal: Infinity,
          maxVal: -Infinity,
          fixedMinVal: Infinity,
          fixedMaxVal: -Infinity,
          empty : {
              count : 0,
              maxCount : 0,
              active : true
          }
      }
      return {
          ...rangeObject,
          type: "range",
      };
  };

  function buildFilterTracker(data, branchType) {
      let filterTracker = {};

      const branchTypes = ["kanban", "list"];
      filterTracker = {
          viewType: "assigned",
          activeCount: 0,
          maxCount: 0
      };

      if (branchType === "kanban") {
          filterTracker = {
            viewType: bidFilters?.viewType ?? "assigned",
            includeUnassigned: bidFilters?.includeUnassigned ?? true,
            includeOpenRequests: bidFilters?.includeOpenRequests ?? true,
            notification1 : {
              stage1 : bidFilters?.notification1?.stage1 ?? false,
              stage2 : bidFilters?.notification1?.stage2 ?? false,
              stage3 : bidFilters?.notification1?.stage3 ?? false
            },
            notification2 : {
              stage1 : bidFilters?.notification2?.stage1 ?? false,
              stage2 : bidFilters?.notification2?.stage2 ?? false,
              stage3 : bidFilters?.notification2?.stage3 ?? false
            },
            kanban: {
                stage1MaxCount : 0,
                stage2MaxCount : 0,
                stage3MaxCount : 0
              },
              list: {
                stage1MaxCount : 0,
                stage2MaxCount : 0,
                stage3MaxCount : 0
              },
          };

          branchTypes.forEach((type) => {
            Object.keys(data).forEach((filterKey) => {
                if (data[filterKey] === "binary") {
                    filterTracker[type][filterKey] = filterTracker[filterKey] || {}; // Initialize filterTracker[filterKey] if it doesn't exist
                    filterTracker[type][filterKey] = createBinaryHandler(null, filterKey);
                }

                if (data[filterKey] === "range") {
                    filterTracker[type][filterKey] = filterTracker[filterKey] || {}; // Initialize filterTracker[filterKey] if it doesn't exist
                    filterTracker[type][filterKey] = createRangeHandler();
                }
            });
        });

    }else{
      Object.keys(data).forEach((filterKey) => {
        if (data[filterKey] === "binary") {
            filterTracker[filterKey] = filterTracker[filterKey] || {}; // Initialize filterTracker[filterKey] if it doesn't exist
            filterTracker[filterKey] = createBinaryHandler(null, filterKey);
        }

        if (data[filterKey] === "range") {
            filterTracker[filterKey] = filterTracker[filterKey] || {}; // Initialize filterTracker[filterKey] if it doesn't exist
            filterTracker[filterKey] = createRangeHandler();
        }
      });
    }

    return filterTracker;

  }

  function deepClone(obj) {
    function sanitize(obj) {
        if (Array.isArray(obj)) {
            return obj.map(sanitize);
        } else if (obj && typeof obj === 'object') {
            return Object.fromEntries(
                Object.entries(obj)
                    .filter(([_, value]) => value !== undefined)
                    .map(([key, value]) => [key, sanitize(value)])
            );
        }
        return obj;
    }

    try {
        return JSON?.parse(JSON?.stringify(sanitize(obj)));
    } catch (error) {
        // console.error("Failed to deep clone object:", error);
        return null;
    }
}
  function initializeFilterBranch(branch, data) {
    // console.log(branch,data);
    // return;
    setFilterHandler((prevFilterHandler) => {
      const updatedBranch = deepClone(prevFilterHandler?.[branch]);
      const excludedAttributes = ["includeUnassigned", "viewType", "active"];
  
      function updateObject(target, source) {
        for (const key in target) {
          if (!(key in source)) {
          // Key exists in filterHandler but not in incoming data, so delete it
            delete target[key];
          } else if (
            typeof source[key] === "object" &&
            source[key] !== null &&
            typeof target[key] === "object" &&
            target[key] !== null
          ) {
            updateObject(target[key], source[key]);
          } else if (key === "minVal" || key === "maxVal") {
            const currentMin = target.minVal;
            const currentMax = target.maxVal;
            const newMin = source.minVal; // Corrected here
            const newMax = source.maxVal; // Corrected here
            const fixedMinVal = target.fixedMinVal;
            const fixedMaxVal = target.fixedMaxVal;
            // console.log(currentMin, fixedMinVal, newMin, " | ", currentMax, fixedMaxVal, newMax);
            if ((key === "minVal" && currentMin === fixedMinVal && currentMin !== newMin) ||
              (key === "maxVal" && currentMax === fixedMaxVal && currentMax !== newMax)) {
              // console.log("Should update: " + key + " was set to its limit");
              target[key] = key === "minVal" ? newMin : newMax;
            } else {
              if (key === "minVal") {
                // console.log("No need to update " + key + " " + currentMin + " " + fixedMinVal + " " + newMin);
              } else {
                // console.log("No need to update " + key + " " + currentMax + " " + fixedMaxVal + " " + newMax);
              }
            }
          } else if (!excludedAttributes.includes(key)) {
            target[key] = source[key];
          }
        }
        
        // Add keys from source that are not present in target
        for (const key in source) {
          if (!(key in target)) {
            target[key] = deepClone(source?.[key]);
          }
        }
      }
  
      updateObject(updatedBranch, data);
  
      return {
        ...prevFilterHandler,
        [branch]: updatedBranch,
      };
    });
  }

  function updateFilterAttr(value = null, branch, stem, group, attr, key) {
    setFilterHandler((prev) => {
      const currentValue = (stem && prev[branch]?.[stem]?.[group]?.[attr]?.[key] !== undefined)
        ? prev[branch][stem][group][attr][key]
        : (stem && prev[branch]?.[stem]?.[group]?.[attr])
        ? prev[branch][stem][group][attr]
        : ((!stem && attr) && prev[branch]?.[attr])
        ? prev[branch][attr]
        : (stem && prev[branch]?.[stem]?.[group])
        ? prev[branch][stem][group]
        : false;

      const defaultValue = !(currentValue || false);
      const newValue = value !== null ? (currentValue === value[0] ? value[1] : value[0]) : defaultValue;
      const groupType = prev?.[branch]?.[stem]?.[group]?.type;

      if (groupType === "binary") {
        let activeCount = prev?.[branch]?.[stem]?.[group]?.activeCount || 0;
        const count = prev[branch][stem][group][attr]?.count || 0;
        activeCount = newValue ? activeCount + count : activeCount - count;

        return {
          ...prev,
          [branch]: {
            ...prev[branch],
            ...(stem ? {
              [stem]: {
                ...prev[branch][stem],
                [group]: {
                  ...(prev[branch][stem][group] || {}),
                  activeCount,
                  [attr]: {
                    ...(prev[branch][stem][group] && prev[branch][stem][group][attr] || {}),
                    [key]: newValue
                  }
                }
              }
            } : {
              [attr]: newValue
            })
          }
        };
      }

      return {
        ...prev,
        [branch]: {
          ...prev[branch],
          ...(stem ? {
            [stem]: {
              ...prev[branch][stem],
              [group]: 
                attr ? {
                  ...(prev[branch][stem][group] || {}),
                  [attr]: {
                    ...(prev[branch][stem][group] && prev[branch][stem][group][attr] || {}),
                    [key]: newValue
                  }
                }
                : newValue
              }
          } : {
            [attr]: newValue
          })
        }
      };
    });
  }

  function updateRangeData(currentData, value) {
      if (value) {
        const numValue = Number(value);
        currentData.minVal = Math.min(numValue, currentData.minVal);
        currentData.maxVal = Math.max(numValue, currentData.maxVal);
        currentData.fixedMinVal = Math.min(numValue, currentData.fixedMinVal);
        currentData.fixedMaxVal = Math.max(numValue, currentData.fixedMaxVal);
        currentData.activeCount++;
      } else {
        currentData.empty.count++;
        currentData.empty.maxCount++;
      }
  }

  function alterRange(branch, stem, category, newValues, reset) {
      setFilterHandler((prev) => {
        const currentData = prev?.[branch]?.[stem]?.[category];
        const newData = {
          ...currentData,
          minVal: reset ? currentData?.fixedMinVal : newValues[0],
          maxVal: reset ? currentData?.fixedMaxVal : newValues[1],
        };
    
        return {
          ...prev,
          [branch]: {
            ...prev?.[branch],
            [stem]: {
              ...prev?.[branch]?.[stem],
              [category]: newData,
            },
          },
        };
      });
  }

  function resetGroup(branch, stem, group, toggleAll = false) {
    setFilterHandler((prev) => {
      const groupData = prev[branch][stem][group];
      const { type } = groupData;
  
      // Handle binary type
      if (type === 'binary') {
        const updatedGroupData = { ...groupData };
  
        // Determine the toggle value based on current state and toggleAll parameter
        const toggleValue = toggleAll ? !groupData.activeCount : true;
  
        // Iterate through the children properties
        Object.keys(updatedGroupData).forEach((child) => {
          if (
            child !== 'type' &&
            child !== 'activeCount' &&
            child !== 'emptyCount' &&
            typeof updatedGroupData[child] === 'object' &&
            updatedGroupData[child] !== null
          ) {
            // Set individual children to toggleValue
            updatedGroupData[child].active = toggleValue;
          }
        });
  
        // Calculate the activeCount based on the state of children objects' counts
        const activeCount = Object.values(updatedGroupData)
          .filter((child) => typeof child === 'object' && child !== null)
          .reduce((count, child) => count + (child.active ? child.count : 0), 0);
  
        updatedGroupData.activeCount = activeCount === groupData.maxCount ? groupData.maxCount : 0;
  
        return {
          ...prev,
          [branch]: {
            ...prev[branch],
            [stem]: {
              ...prev[branch][stem],
              [group]: updatedGroupData,
            },
          },
        };
      }
  
      // Handle range type
      if (type === 'range') {
        const updatedGroupData = {
          ...groupData,
          minVal: groupData.fixedMinVal,
          maxVal: groupData.fixedMaxVal,
        };
  
        return {
          ...prev,
          [branch]: {
            ...prev[branch],
            [stem]: {
              ...prev[branch][stem],
              [group]: updatedGroupData,
            },
          },
        };
      }
  
      // Handle other types (no reset action)
      return prev;
    });
  }

  function reorderGroup(stateVariable, branch, stem, group, orderBy = 'value') {
    const sortingFunction = (a, b) => {
      const valueA = a[1][orderBy] || '';
      const valueB = b[1][orderBy] || '';

      if (orderBy === 'value') {
        return valueA.localeCompare(valueB);
      } else if (orderBy === 'count') {
        return b[1].count - a[1].count;
      }
  
      return 0;
    };
  
    const updatedGroup = Object.fromEntries(Object.entries(stateVariable).sort(sortingFunction));
    
    setFilterHandler(prev => ({
      ...prev,
      [branch]: {
        ...prev[branch],
          [stem]: {
            ...prev[branch][stem],
            [group]: {
              ...updatedGroup,
              sort: orderBy
            }
          }
      }
    }));
  }

  function initializeOrder(group, orderBy = 'value'){
    //This function is used for reorder prior to the data becoming a useState variable
    const sortingFunction = (a, b) => {
      const valueA = a[1][orderBy] || '';
      const valueB = b[1][orderBy] || '';

      if (orderBy === 'value') {
        return valueA.localeCompare(valueB);
      } else if (orderBy === 'count') {
        return b[1].count - a[1].count;
      }

      return 0;
    };

    group = Object.fromEntries(Object.entries(group).sort(sortingFunction));
    return group;
  };

  function groupData(group){
    const groups = {
      deathBenefit : {
        prompt : "Death Benefit",
        icon : <PaidIcon sx={{ color: green[500] }}/>,
        intType : "currency", 
        type : "range"
      },
      lifeExpectancy : {
        prompt: "LE Mo. Range",
        icon : <SsidChartIcon sx={{ color: orange[500] }}/>,
        intType : "number",
        type : "range"
      },
      age : {
        prompt : "Age",
        icon : <CalendarMonthIcon  sx={{ color: purple[500] }}/>,
        intType : "number",
        type : "range"
      },
      carrier : {
        prompt : "Carriers",
        icon : <ApartmentIcon sx={{ color: blueGrey[500] }}/>,
        type : "binary",
        nested : false
      },
      affiliate :{
        prompt : "Affiliates",
        icon : <ApartmentIcon sx={{ color: blueGrey[500] }}/>,
        type : "binary",
        nested : true
      },
      status : {
        prompt : "Status",
        icon : <ApartmentIcon sx={{ color: blueGrey[500] }}/>,
        type : "binary",
        nested : false
      },
      leadSource : {
        prompt : "Lead Source",
        icon : <ApartmentIcon sx={{ color: blueGrey[500] }}/>,
        type : "binary",
        nested : false
      }
    }

    return groups[group];
  }

  function logout(){
    window.localStorage.removeItem("sessionUser");
    window.localStorage.setItem('sessionUser', JSON.stringify({currentPage : "login" }));
  }

  function parseJWT (sessionToken) {
    if(!sessionToken){
      return;
    }

    try {
        var base64Url = sessionToken.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    } catch (e) {
        console.log(e);
        return "Unauthorized";
    }
  }

  function generateShareLinkData(buildFetchRequest, policyType, relatedPolicyID) {
    return buildShareLinkData(buildFetchRequest, policyType, relatedPolicyID)
      .then(shareLinkData => {
        const shareLink = shareLinkData?.[relatedPolicyID]?.shareLink;
        if (!shareLink || !shareLink.startsWith("https://")) {
          return "empty";
        }
  
        return shareLink;
      })
      .catch(error => {
        console.error("Error building share link data:", error);
      });
  }

  async function buildShareLinkData(buildFetchRequest, pullType, relatedID){
    const paramVals = {
      pullType: pullType,
      relatedID: relatedID
    };

    try {
      const response = await buildFetchRequest("getShareLinks", paramVals);
      const resData = await response.json();
  
      if (resData.status === 200) {
        return resData?.policyData;
      }

      if (resData.status === 500) {
        console.log("No Share Link data to return.");
        return null; // Or handle the absence of share link data in an appropriate way
      }
    } catch (error) {
      console.error(error);
      throw error; // Re-throw the error to be caught by the caller or handle it accordingly
    }
  }

  function updateCaseReadStatus(currentCaseInfo, caseList, setCaseList) {
    return;
    if (!Array.isArray(caseList)) {
      console.error('Invalid caseList:', caseList);
      return;
    }
  
    const caseIndex = caseList.findIndex((currCase) => currCase.recordID === currentCaseInfo.recordID);
  
    if (caseIndex === -1) {
      console.error('Case not found in the caseList:', currentCaseInfo.recordID);
      return;
    }
  
    const updatedCaseList = caseList.map((currCase, index) => {
      if (index === caseIndex) {
        return { ...currCase, numActivitiesUnread: 0 };
      }
      return currCase;
    });
  
    setCaseList(updatedCaseList);
  }

  function authorizeRequest(sessionUser) {
    const storedSessionUser = JSON.parse(window.localStorage.getItem('sessionUser'));

    if (!sessionUser || !sessionUser.sessionToken) {
      logout();
      return "Unauthorized";
    }

    const currentToken = parseJWT(sessionUser.sessionToken);

    if (currentToken === "Unauthorized" || ["userID", "email", "accountID"].some(attrName =>
      currentToken[attrName] !== sessionUser?.[attrName] ||
      currentToken[attrName] !== storedSessionUser?.[attrName]
    )) {
      logout();
      return "Unauthorized";
    }

    return sessionUser.sessionToken;
  }

  function buildRequestOptions(bodyParams, directContentType){
    let defaultContentType = directContentType || "application/json";

      return {
        method: "POST",
        headers: { "Content-Type": defaultContentType },
        body: JSON.stringify({
          ...(bodyParams || {}),
          sessionToken: authorizeRequest(sessionUser)
        })
      };
  }

  function buildFetchRequest(url, params) {
    const requestOptions = buildRequestOptions(params);
    return fetch(fetchBase + url, requestOptions);
  }
  
  const functions = {
    buildFetchRequest
  }

  const envVars = {
      adminHandler,
      setAdminHandler,
      filterHandler,
      setFilterHandler,
      caseHandler,
      setCaseHandler,
      functions
  }

  useEffect(() => {
    const storageData = JSON.parse(window.localStorage.getItem('storage')) || {};
    storageData.filters = filterHandler;
    window.localStorage.setItem('storage', JSON.stringify(storageData));
  }, [filterHandler]);

  return envVars;
};

export default EnvVars;