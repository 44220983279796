import React, { useEffect, useState } from 'react';

import { convertIntToCurrency } from '../../envFunctions.js';

import InputField from '../../Components/InputField.js';

import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import SearchIcon from '@mui/icons-material/Search';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CampaignIcon from '@mui/icons-material/Campaign';
import SaveIcon from '@mui/icons-material/Save';
import ThumbsUpDown from '@mui/icons-material/ThumbsUpDown';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import GradingIcon from '@mui/icons-material/Grading';

function BidAlterPanel(props){
    const [selectedStatus, setSelectedStatus] = useState(props.selectedStatus);
    const [content, setContent] = useState('');
    const [bidAmount, setBidAmount] = useState({
        bidAmount : 0,
        rdbAmount : 0
    });
    const [declineOption, setDeclineOption] = useState();
    const [loadingStatus, setLoadingStatus] = useState(false);
    const handleChange = (event) => {
        setContent(event.target.value);
    };

    function updateTempCurrentBid(evt){
          if (parseInt(evt.target.value.substring(1).replace(/,/g, ""))){
            setBidAmount((tempBidAmount) => ({
              ...tempBidAmount,
              [evt.target.name]: parseInt(evt.target.value.substring(1).replace(/,/g, "")),
            }));
          }
        
          if (evt.target.value.substring(1).replace(/,/g, "") === '') {
            setBidAmount((tempBidAmount) => ({
                ...tempBidAmount,
                [evt.target.name]: 0,
            }));
          }
    }

    useEffect(() => {
        // console.log(selectedStatus);
        // console.log(declineOption);
    }, [selectStatus]);

    function currentContentPrompt(){
        if(selectedStatus === "Requested Info"){
            return "What information are you requesting?";
        }

        if(selectedStatus === "Bidding"){
            if(props?.bidVerbiage === "Interest"){
                return "Attach a note with this expression of interest.";
            }

            return "Attach a note with this bid.";
        }

        if(selectedStatus === "Declined"){
            return "Reason for declining case?";
        }
    }

    function currentDeclineStatus(status){
        if(status === declineOption){
            return "btn selected g cC fMP bR p";
        }

        return "btn g cC fMP bR p";
    }

    function splitOptions(){
        if(selectedStatus === "Bidding"){
            return "split";
        }

        if(selectedStatus === "Declined"){
            return "split";
        }

        return "fC";
    }

    function saveEligibility(){
        //if(selectedStatus === "Under Review" && props.)
        if(content === '' && selectedStatus !== "Under Review"){
            return false;
        }

        if(selectedStatus === "Bidding"){
            if(bidAmount.bidAmount === 0){
                return false;
            }
        }

        if(selectedStatus === "Declined"){
            if(!declineOption){
                return false;
            }
        }

        return true;

    }

    function convertValues(){
        let conversionData = {};
        if(selectedStatus === "Requested Info"){
            if(props.currentBidInfo?.shippingStatus === "Bidding"){
                conversionData.stage = "BIDDING";
                conversionData.shippingStatus = "Bidding";
            }else{
                conversionData.stage = "UNDER REVIEW";
                conversionData.shippingStatus = "Under Review";
            }
        }

        if(selectedStatus === "Under Review"){
            conversionData.stage = "UNDER REVIEW";
            conversionData.shippingStatus = "Under Review";
        }

        if(selectedStatus === "Bidding"){
            conversionData.stage = "BIDDING";
            conversionData.shippingStatus = "Bidding";

        }

        if(selectedStatus === "Declined"){
            conversionData.stage = "CLOSED";
            conversionData.shippingStatus = declineOption;
        }

        return conversionData;
    }

    function updateBidStatus(){
    
        setLoadingStatus(true);
        let status = selectedStatus;
        if(selectedStatus === "Declined"){
            status = declineOption;
        }

        const paramVals = {
            "taskID" : props.currentBidInfo.recordID,
            "status" : status,
            "bid" : bidAmount.bidAmount,
            "rdb" : bidAmount.rdbAmount,
            "content" : selectedStatus === "Under Review" ? " " : content
        };

        props.session?.env?.functions?.buildFetchRequest("marketplace/updateBidStatus", paramVals)
        .then(response => response.json())
        .then(resData => {
            setLoadingStatus(false);
            if(resData.status === 200){
                props.setShowBidAlterPanel(false);
                props.loadBidActivity();

                if(Object.keys(props?.bidProps?.bidsList)?.length > 0){
                    const currentTimeStamp = new Date().toISOString();
                    const currentBidIndex = props.bidProps?.bidsList.findIndex(
                        (bid) => bid.recordID === props.currentBidInfo.recordID
                    );
        
                    const updatedBidsList = [...props.bidProps.bidsList]; // Create a copy of the bidsList array
                    const currentBid = updatedBidsList[currentBidIndex]; // Retrieve the current bid object from the copy

                    let conversionData = convertValues();
                    // Modify the properties of the currentBid object
        
                    currentBid.shippingStatus = conversionData.shippingStatus;
                    currentBid.stage = conversionData.stage;
                    currentBid.lastUpdate = currentTimeStamp;

                    props.bidProps.setBidsList(updatedBidsList); // Update the bidsList state with the modified array
                }else{
                    console.log("No current bids connection to alter.");
                    return;
                }
            }
    
            if(resData.status === 500){
                console.log("Unable to update Bid Status");
            }
        });
    }
    
    function selectStatus(e, status){
        setSelectedStatus(status);
        setContent('');
        if(status !== "Bidding"){
            setBidAmount(tempBidAmount => ({...tempBidAmount, bidAmount: 0, rdbAmount : 0}));
        }
        if(status !== "Declined"){
            setDeclineOption();
        }
        
        if(status === "Under Review"){
            setContent(" ");
        }
    }

    function selectDeclineOption(status){
        setDeclineOption(status);
    }

    function currentStatus(status){
        if(selectedStatus === status){
            return true;
        }

        return false;
    }

    return (
        <div className="bidAlterPanel moduleBlock g bR cC fC ns">
            <div className="header g cC fR">
                <div className="gC2">
                    Update Case Position
                </div>
                <div className="f cC">
                    <div className="btn cC p" onClick={()=> {props.setShowBidAlterPanel(false)}}>
                        <CloseIcon/>
                    </div>
                </div>
            </div>
            <div className="wrap g cC fC bR f">
                <div className="bidTimelineActionBar g cC fR f dP">
                    <div className={currentStatus("Requested Info") ? "btn selected g cC fMP bR p" : "btn g cC fMP bR p"} onClick={(e)=>{selectStatus(e, "Requested Info")}}>
                        <div>
                            Request Info
                        </div>
                        <PendingActionsIcon/>
                    </div>
                    <div className="vDivider">
                    </div>
                    <div className={currentStatus("Under Review") ? "btn selected g cC fMP bR p" : "btn g cC fMP bR p"} onClick={(e)=>{selectStatus(e, "Under Review")}}>
                        <div>
                            Under Review
                        </div>
                        <SearchIcon/>
                    </div>

                    <div className={currentStatus("Bidding") ? "btn selected g cC fMP bR p" : "btn g cC fMP bR p"} onClick={(e)=>{selectStatus(e, "Bidding")}}>
                        <div>
                            {props?.bidVerbiage === "Interest" ? "Show Interest" : "Make Bid"}
                        </div>
                        <CampaignIcon/>
                    </div>
                    <div className={currentStatus("Declined") ? "btn selected g cC fMP bR p" : "btn g cC fMP bR p"} onClick={(e)=>{selectStatus(e, "Declined")}}>
                        <div>
                            Decline
                        </div>
                        <DoDisturbOnIcon/>
                    </div>
                </div>
                <div className={"statusAlterBlock g f pR cC fR " + splitOptions()}>
                    { splitOptions() !== "fC" &&
                        <div className="cC f">
                            {selectedStatus === "Bidding" &&
                                <div className="bidding g tC fC fR f s">
                                    <div className="f cL">
                                        <InputField
                                            loadingStatus={false}
                                            value={convertIntToCurrency(bidAmount.bidAmount)}
                                            placeholder={props?.bidVerbiage + " Amount"}
                                            name={"bidAmount"}
                                            readOnly={false}
                                            functionPointer={updateTempCurrentBid}
                                        />
                                    </div>
                                    <div className="f cL">
                                        <InputField
                                            loadingStatus={false}
                                            value={convertIntToCurrency(bidAmount.rdbAmount)}
                                            placeholder={"RDB Amount"}
                                            name={"rdbAmount"}
                                            readOnly={false}
                                            functionPointer={updateTempCurrentBid}
                                        />
                                    </div>
                                    <div className="biddingIcon cC f">
                                        <ThumbsUpDown/>
                                    </div>
                                </div>
                            }
                            {selectedStatus === "Declined" &&
                                <div className="declined g f cC fC">
                                    <div className="g cC fMP bR">
                                        <div className="cC gC2">
                                            Decline Reasons
                                        </div>
                                        <div className="cL f">
                                            <ThumbDownAltIcon/>
                                        </div>
                                    </div>
                                    <div className="divider"></div>
                                    <div className={currentDeclineStatus("No Bid")} onClick={()=>{selectDeclineOption("No Bid")}}>
                                        {props?.bidVerbiage === "Interest" ? "No Interest" : "No Bid"}
                                    </div>
                                    <div className={currentDeclineStatus("In Conflict")} onClick={()=>{selectDeclineOption("In Conflict")}}>
                                        In Conflict
                                    </div>
                                    <div className={currentDeclineStatus("Does Not Fit Parameters")} onClick={()=>{selectDeclineOption("Does Not Fit Parameters")}}>
                                        Invalid Params
                                    </div>
                                    <div className={currentDeclineStatus("Lost with bid")} onClick={()=>{selectDeclineOption("Lost with bid")}}>
                                        {props?.bidVerbiage === "Interest" ? "Lost with Interest" : "Lost with Bid"}
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    { selectedStatus === "Under Review" &&
                        <div className="cC f">
                            <div className="underReview g cC fR f">
                                <div className="content g f cL dP fC">
                                    <div className="f bC bold">
                                        Under Review Period
                                    </div>
                                    <div className="f tL dP">
                                        By setting this shipped case to the Under Review status,
                                        it indicates that there is potential interest. At anytime,
                                        you can request information from us.
                                    </div>
                                </div>
                                <div className="icon dP cC">
                                    <GradingIcon/>
                                </div>
                            </div>
                        </div>
                    }
                    { selectedStatus && currentContentPrompt() &&
                        <textarea
                            className="contentBox bR"
                            placeholder={currentContentPrompt()}
                            onChange={handleChange}
                            rows={20}
                        />
                    }
                    { !selectedStatus &&
                        <div className="cC f empty">
                            Select a Bid Position
                        </div>
                    }
                </div>
            </div>
            <div className="bidTimelineActionBar g cC fR f dP">
                {loadingStatus &&
                    <div className="btn loading g cC fMP bR p gC5">
                        <CircularProgress/>
                    </div>
                }
                {!loadingStatus &&
                <div
                    className={!saveEligibility() ? "btn inactive g cC fMP bR p gC5" : "btn g cC fMP bR p gC5"}
                    onClick={(e)=>{saveEligibility() && !loadingStatus && updateBidStatus()}}>
                    <div>
                        Save
                    </div>
                    <SaveIcon/>
                </div>
                }
            </div>
        </div>
    );
};

export default BidAlterPanel;