import React, { useEffect, useState } from 'react';

function SessionUser(props){
    const [data, setData] = useState();

    const updateUserData = (propertyPaths, values) => {
        const paths = Array.isArray(propertyPaths) ? propertyPaths : [propertyPaths];
        const vals = Array.isArray(values) ? values : [values];   

        setData((prev) => {
            const newData = { ...prev };
            for (let i = 0; i < paths.length; i++) {
                const propertyPathParts = paths[i].split('.');
                let currentNested = newData;

                for (let j = 0; j < propertyPathParts.length - 1; j++) {
                    const propertyPart = propertyPathParts[j];
                    currentNested[propertyPart] = currentNested[propertyPart] || {};
                    currentNested = currentNested[propertyPart];
                }

                currentNested[propertyPathParts[propertyPathParts.length - 1]] = vals[i];
            }

            window.localStorage.removeItem("sessionUser");
            window.localStorage.setItem('sessionUser', JSON.stringify(newData));

            return newData;
        });

        window.scrollTo(0, 0);
    };

    const logout = () => {
        window.localStorage.removeItem("sessionUser");
        window.sessionStorage.setItem('userLoggedOut', 'true');

        setData();
    };

    function loadUser(sessionUser){
        window.localStorage.removeItem("sessionUser");
        window.localStorage.setItem('sessionUser', JSON.stringify(sessionUser));
        setData(sessionUser);
    }

    function verifyAccess(pageString) {
        const page = pageString.charAt(0).toUpperCase() + pageString.slice(1);
        const accountData = data?.accountData;

        if (data?.views?.includes(page)) {
            if (data?.managedAccounts) {
                for (const accountID in accountData) {
                    const child = accountData?.[accountID];
                    if (child.views && child?.views.includes(page)) {
                        return true;
                    }
                }
                return false;
            } else {
                return accountData?.[data?.accountID]?.views?.includes(page);
            }
        }

        return false;
    }

    const functions = {
        loadUser,
        updateUserData,
        logout,
        verifyAccess
    }

    const sessionUser = {
        data,
        functions
    }

    return sessionUser;
};

export default SessionUser;