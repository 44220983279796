import React from 'react';
import { useState, useEffect, useRef } from 'react';

import '../../index.css';
import './MessageBoard.css';
import { authorizeRequest } from '../../envHandler.js';

import MessageBubble from './MessageBubble';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import CircularProgress from '@mui/material/CircularProgress';

function MessageBoard(props) {
  const [rows, setRows] = useState(1);
  const [maxRow, setMaxRow] = useState(1);
  const [messageVal, setMessageVal] = useState("");
  const [activeStatus, setActiveStatus] = useState("");
  const scrollRef = useRef(null);
  const bottomHandle = useRef(null);
  const textBox = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScrollHeight, setMaxScrollHeight] = useState(0);
  const [adaptationTracker, setAdaptationTracker] = useState(0);
  const [policyType, setPolicyType] = useState('');
  
  const [transcript, setTranscript] = useState({});
  const [messageLoadingStatus, setMessageLoadingStatus] = useState(true);

  function sendMessage(){

    if(checkMessageEligibility(textBox.current.value)){
      return;
    }
  
    textBox.current.value = '';
    let int = Object.keys(transcript).length;
    let message = {};
    let tempMessage = {
      content: messageVal,
      originator : "Customer",
      loading : true,
      nameOfSender : props.sessionUser.firstName + " " + props.sessionUser.lastName,
      x : int
    }

    pullTranscript(tempMessage, policyType);

    resetMessageBox();

    let requestURL;
    let paramVals;

    if(policyType === "origination"){
      requestURL = 'sendMessage';
      paramVals = {
        "relatedPolicyID" : props.relatedPolicyID,
        "message" : messageVal,
        "nameOfSender" : props.sessionUser.firstName + " " + props.sessionUser.lastName,
        "emailOfSender" : props.sessionUser.email,
      };
    }
    if(policyType === "fund"){
      requestURL = 'servicing/sendMessage';
      paramVals = {
        "policyID" : props.relatedPolicyID,
        "message" : messageVal,
        "nameOfSender" : props.sessionUser.firstName + " " + props.sessionUser.lastName,
      };
    }
    if(policyType === "shipping"){
      requestURL = 'marketplace/sendMessage';
      paramVals = {
        "taskID" : props.relatedPolicyID,
        "content" : messageVal,
      };
    }

    props.session?.env?.functions?.buildFetchRequest(requestURL, paramVals)
      .then(response => response.json())
      .then(resData => {
        if (resData.status === 200) {
          const configs = {
              origination: ['messageData', 'originator'],
              fund: ['data', 'Customer', 'message', 'dateCreated', 'noteType'],
              shipping: ['messageData', 'Customer', 'content', 'timeStamp', 'firstName', 'noteType']
          };
      
          const [source, originator, ...fields] = configs[policyType] || [];
      
          if (source && resData[source]) {
              const [contentField, timeField, nameField, typeField] = fields;
              const message = {
                  content: resData[source][contentField],
                  timeStamp: resData[source][timeField],
                  nameOfSender: resData[source][nameField],
                  type: resData[source][typeField],
                  originator: originator || resData[source].originator,
                  loading: false,
                  x: int
              };
      
              pullTranscript(message, policyType);
              scrollToBottom();
          }
      }
    
      if(resData.status == 500){
          tempMessage.loading = "failed";
          setTranscript(transcript => ({
            ...transcript,
            [int] : tempMessage
          }));
          handleMessageBoard('');
      }
    });
  }

  function resetMessageBox(){
    setMessageVal('');
    setRows(1);
    setMaxRow(1);
    setActiveStatus("");
  }

  function pullTranscript(nativeMessage, pullType){
    if(props.loadingStatus || !props.policyData?.relatedAccount && !props.pullType){
      return;
    }

    var transcriptData = [];

    if(nativeMessage){
      let messageObj = {
        content : nativeMessage.content,
        originator : nativeMessage.originator,
        timeStamp : nativeMessage.timeStamp,
        nameOfSender : props.sessionUser.firstName + " " + props.sessionUser.lastName,
        loading : true,
        x : nativeMessage.x                         
      }
  
      transcriptData = {...transcript};

      if(nativeMessage.loading === false){
        messageObj.loading = false;
      }
  
      setTranscript(transcript => ({ ...transcript, [nativeMessage.x] : messageObj }));
    }else{
      var x = 0;
      let requestURL;
      let paramVals;
      if(pullType === "origination"){
        requestURL = 'pullTranscript';
        paramVals = {
          "relatedPolicyID" : props.relatedPolicyID,
          "accountID" : props.policyData.relatedAccount,
          "sessionToken" : authorizeRequest(props.sessionUser, props.updatePage)
        };
      }
      if(pullType === "fund"){
        requestURL = 'servicing/getPolicyMessages';
        paramVals = {
          "policyID" : props.relatedPolicyID,
          "sessionToken" : authorizeRequest(props.sessionUser, props.updatePage)
        }
      }
      if(pullType === "shipping"){
        requestURL = 'marketplace/getBidMessages';
        paramVals = {
          "taskID" : props.relatedPolicyID,
          "sessionToken" : authorizeRequest(props.sessionUser, props.updatePage)
        }
      }

      props.session?.env?.functions?.buildFetchRequest(requestURL, paramVals)
        .then(response => response.json())
        .then(resData => {
          //console.log(resData);

          setMessageLoadingStatus(false);
          if(resData.status === 200){
            let returningFormat;
            if(policyType === "origination" || pullType === "origination"){
              returningFormat = "policyTranscriptData";
            }

            if(policyType === "fund" || pullType === "fund"){
              returningFormat = "messageLog";
            }

            if(policyType === "shipping" || pullType === "shipping"){
              returningFormat = "data";
            }

            for (var currentMessage in resData[returningFormat]){
              let formatedMessage = resData[returningFormat][currentMessage];
              let message;
              if(policyType === "origination" || pullType === "origination"){
                message = {
                  content : formatedMessage.content,
                  nameOfSender : formatedMessage.nameOfSender,
                  type : formatedMessage.type,
                  originator : formatedMessage.originator,
                  timeStamp : formatedMessage.timeStamp,
                  loading : false,
                  x : currentMessage
                };
              }

              if(policyType === "fund" || pullType === "fund"){
                message = {
                  content : formatedMessage.message,
                  nameOfSender : formatedMessage.nameOfSender,
                  type : formatedMessage.noteType,
                  originator : formatedMessage.originator,
                  timeStamp : formatedMessage.dateCreated,
                  loading : false,
                  x : currentMessage
                };
              }

              if(policyType === "shipping" || pullType === "shipping"){
                message = {
                  content : formatedMessage.content,
                  nameOfSender : formatedMessage.nameOfSender,
                  type : formatedMessage.type,
                  originator : formatedMessage.originator,
                  timeStamp : formatedMessage.timeStamp,
                  loading : false,
                  x : currentMessage
                };
              }

              transcriptData[currentMessage] = message;
            }

            setTranscript(transcriptData);
            updateMessageReadStatus();
          }

          if(resData.status === 500){
            console.log("No transcript found.");
          }
      });
    }
  }

  function printTranscript(){
    if(props.loadingStatus || messageLoadingStatus){
      return(
        <div className="preTranscriptContainer">
            <div className="preTranscriptInnerWrap">
              <CircularProgress color="inherit"/>
            </div>
        </div>
      )
    }

    if(Object.keys(transcript).length === 0 && !props.loadingStatus && !messageLoadingStatus){
      return(
        <div className="preTranscriptContainer">
            <div className="preTranscriptInnerWrap">
                <ModeCommentIcon sx={{ fontSize: 50 }}/>
                <div>
                  <p>Have any questions?</p>
                  <p>Start a conversation.</p>
                </div>
            </div>
        </div>
      )
    }

    let transcriptMessages = [];
    let lastNameOfSender = null;

    for (var key of Object.keys(transcript)) {
      transcriptMessages.push(
        <MessageBubble
          key={key}
          messageKey={key}
          messageData={transcript[key]}
          lastDate={key > 0 ? transcript[key-1].timeStamp : null}
          lastNameOfSender={lastNameOfSender}
        />);
      lastNameOfSender = transcript[key].nameOfSender;
    }

    return (
      transcriptMessages
    )
  }

  function updateMessageReadStatus(){
    let requestLink = "updateReadStatus";
    let requestOptions;
    let paramVals;

    if(props.pullType === "origination"){
        paramVals = {
          "type" : "messages",
          "relatedID" : props.relatedPolicyID,
          "unread": false
        };
    }

    if(props.pullType === "servicing"){
      requestLink = "servicing/updateReadStatus";
      paramVals = {
        "type":"messages",
          "policyID":props.relatedPolicyID,
          "unread": false
      }
    }
    
    if(props.pullType === "shipping"){
      requestLink = "marketplace/updateReadStatus";
      paramVals = {
          "type":"messages",
          "taskID":props.relatedPolicyID,
          "unread": false
      };
    }

    props.session?.env?.functions?.buildFetchRequest(requestLink, paramVals)
      .then(response => response.json())
      .then(resData => {
        //console.log(resData);
        if(resData.status === 200){
            props.loadNotifications();
        }
        if(resData.readStatus !== undefined && !resData.readStatus){
          //props.markSessionPolicyAsRead(props.relatedPolicyID);
        }
        if(resData.status === 500){
            // console.log("No connection made.");
        }
    });
  }

  function handleScroll(scrollObj){
    const position = scrollObj.target.scrollTop;
    setScrollPosition(position);
    setMaxScrollHeight(scrollObj.target.clientHeight - ((maxRow * 16) + 38));
  };

  function maintainScrollPosition(adaptiveScrollTop){
    if(scrollRef.current.scrollTop > (maxScrollHeight / 2)){
      scrollRef.current.scrollTop = scrollPosition + adaptiveScrollTop;
    }
  }

  function handleMessageBoard(currentMessageVal){
    let rowCount = currentMessageVal.target.value.split("\n").length;
    let maxRowCount = rowCount;
    let adaptiveScroll = true;
    let adaption = 1;

    setMessageVal(currentMessageVal.target.value);
  
    if(currentMessageVal.target.value !== "" && activeStatus === "" && !checkMessageEligibility(currentMessageVal.target.value)){
      setActiveStatus(" active");
    }
    if(currentMessageVal.target.value === "" && activeStatus !== ""){
      setActiveStatus("");
    }

    if(rowCount > rows || rowCount < rows) {
      if(rowCount > 5){
        maxRowCount = 5;
        adaptiveScroll = false;
      }
      
      let rowTracker = rows;
      setRows(rowCount);
      setMaxRow(maxRowCount);

      if(rowTracker > maxRowCount){
        adaption = -1;
      }

      if(adaptiveScroll){
        setAdaptationTracker(adaption * 16);
      }
    }
  }

  function checkMessageEligibility(textVal){
    if(textVal === undefined){
      return true;
    }

    let messageText = textVal.replace(/^\s+/, '').replace(/\s+$/, '');
    if(messageText === ''){
      return true;
    }

    return false;
  }

  const scrollToBottom = () => {
    bottomHandle.current?.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    let transcriptPull = "origination";
    if(props?.policyData?.fundID){
      transcriptPull = "fund";
    }

    if(props?.pullType === "shipping"){
      transcriptPull = "shipping";
    }

    setPolicyType(transcriptPull);

    if(props.policyData && !props.loadingStatus){
      pullTranscript(null, transcriptPull);
    }

  }, [props.policyData, props.loadingStatus]);

  useEffect(() => {
    printTranscript();
    scrollToBottom();
  }, [transcript]);

  useEffect(() => {
    maintainScrollPosition(adaptationTracker);
  }, [maxRow]);

  var returningBlock = [];
      returningBlock[0] = [
        <div key="messageBoard" className={messageLoadingStatus ? "policyDetailsMessageBoard loading" : "policyDetailsMessageBoard"} id="messageTranscript" style={{"bottom" : (maxRow * 16 + 42) + "px"}} onScroll={handleScroll} ref={scrollRef}>
          { printTranscript() }
          <div ref={bottomHandle} className="messageBoardBottomHandle"/>       
        </div>];
      returningBlock[1] = [
        <div key="messageBoardBottom" className="messageBoardBottomBar">
          <div className="messageBoardMsgBox">
              <div className="messageBoardBottomBarInnerWrap">
                <textarea
                  className="messageBox"
                  placeholder="Message"
                  rows={rows}
                  onChange={handleMessageBoard}
                  ref={textBox}
                  onKeyPress={(e) => {
                    if(e.key === 'Enter' && !e.shiftKey){sendMessage(); }
                    if(e.key === 'Enter' && !e.shiftKey && checkMessageEligibility(e.value)){ e.preventDefault(); }
                  }}/>
              </div>
              <div className={"messageBoxSendBtn" + activeStatus} onClick={activeStatus !=="" ? () => {sendMessage()} : null}>
                <div className="messageBoxSendBtnInnerWrap">
                  <SendRoundedIcon/>
                </div>
              </div>
          </div>
        </div>
      ];
  return ( returningBlock );
  }

  export default MessageBoard;