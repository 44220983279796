import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import '../LeadsDashboard/Leads.css';
import '../BidsDashboard/BidCenter.css';

import SingleBid from './SingleBid/SingleBid.js';
import BidCenter from './BidCenter';

function BidsDashboard(props){
  return (
    <div className="bidsPage">
      <Routes>
        <Route
          exact
          path="/"
          element={
            <BidCenter
              updatePage={props.updatePage}
              sessionUser={props.sessionUser}
              bidProps={props.bidProps}
              mobileDevice={props.mobileDevice}
              navigation={props.navigation}
              sessionVars={props.sessionVars}
              session={props?.session}
            />
          }
        />
        <Route
          path="/:recordID"
          element={
            <SingleBid
              relatedPolicyID={props.relatedPolicyID}
              setRelatedPolicyID={props.setRelatedPolicyID}
              policyNumber={props.policyNumber}
              setPolicyNumber={props.setPolicyNumber}
              changeActivityView={props.changeActivityView}
              sessionUser={props.sessionUser}
              updatePage={props.updatePage}
              loadNotifications={props.loadNotifications}
              markSessionPolicyAsRead={props.markSessionPolicyAsRead}
              notifications={props.notifications}
              singlePolicyHash={props.singlePolicyHash}
              setSinglePolicyHash={props.setSinglePolicyHash}
              mobileDevice={props.mobileDevice}
              bidsList={props.bidProps.bidsList}
              setLeadsList={props.setLeadsList}
              setImportedUploadPolicy={props.setImportedUploadPolicy}
              bidProps={props.bidProps}
              navigation={props.navigation}
              sessionVars={props.sessionVars}
              session={props?.session}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default BidsDashboard;