import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Rerouter(props) {
  const currentPath = window.location.pathname.replace(/\//g, '');
  const currentSession = JSON.parse(localStorage.getItem('sessionUser') || '{}').sessionToken !== undefined;
  const homepage = props?.session?.env?.functions?.homepage;
  const eligablePages = ["home", "policies", "upload", "leads", "bids", "profile, reporting"]
  const navigate = useNavigate();

  useEffect(() => {
    if (currentSession) {
      // if (currentPath === '' || currentPath === "/" || !eligablePages.includes(currentPath)) {
        const redirectLink = sessionStorage.getItem('redirectLink');

        if(currentPath === "login"){
          props?.session?.user?.functions?.logout();
          navigate("/login");
        }else{
          window.history.replaceState(null, '', props?.session?.env?.functions?.homepage());
          navigate(redirectLink || homepage());
        }

        if (redirectLink) {
          sessionStorage.removeItem('redirectLink');
        }
      // } else {
      //   console.log("HERE");
      //   // Continue with the current page, no redirection
      // }
    } else {
      const rawRedirect = window.location.pathname + window.location.hash;

      if(!(sessionStorage.getItem('userLoggedOut') === 'true')){
        props?.session?.env?.functions?.redirectLink(rawRedirect);
      }

      navigate('/login');
    }
  }, [currentPath]);

  return null;
}

export default Rerouter;