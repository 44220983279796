import React, { useEffect, useState } from 'react';

function BidVars(props){
    const [data, setData] = useState({
        resultList : [],
        kanban : {

        },
        list : {
            listType : "list"
        }
    });

    const updateData = (path, attr, value) => {
        setData((prevState) => {
          const newState = JSON.parse(JSON.stringify(prevState)); // Deep copy
          const pathSegments = path.split('.');
          let current = newState;
    
          for (const segment of pathSegments) {
    
            if (segment.includes('[')) {
              // Handle array access within the path
              const [key, indexStr] = segment.split('[');
              const index = parseInt(indexStr.replace(']', ''), 10);
    
              if (!current[key]) {
                // Initialize an array if it doesn't exist
                current[key] = [];
              }
    
              if (!current[key][index]) {
                // Initialize an object within the array if it doesn't exist
                current[key][index] = {};
              }
    
              current = current[key][index];
            } else {
              // Handle regular object properties within the path
              if (!current[segment]) {
                // Initialize an object if it doesn't exist
                current[segment] = {};
              }
    
              current = current[segment];
            }
          }
    
          if (attr === null) {
            // marketValue, assignedUsersList, issueDate, accountManager, caseProcessor
            // Update the entire path's value to the new value
            newState[pathSegments[0]] = value;
          } else if (typeof attr === 'object' && !Array.isArray(attr)) {
            Object.assign(current, attr);
          } else {
            if(Array.isArray(attr)){
              newState[pathSegments[0]] = attr;
            }else{
              current[attr] = value;
            }
            // Update only the specified attribute
          }
    
          return newState;
        });
    };

    const functions = {

    }

    const bidVars = {
        data,
        setData : updateData,
        functions,
    }

    return bidVars;
};

export default BidVars;