import React from 'react';
import { useState, useEffect } from 'react';

import { convertIntToCurrency, timeStamp, useMobileDetect, leadSourceTag, parseJWT } from '../envFunctions.js';
import { returnStatusData } from '../Components/StatusIcons.js';
import StatusIcon from '../Components/StatusIcons';

import ToggleOption from '../Components/ToggleOption.js';
import RangeSlider from '../Components/FilterPanel/RangeSlider.js';
import CloseIcon from '@mui/icons-material/Close';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import ShieldIcon from '@mui/icons-material/Shield';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function PoliciesFilters(props){
    const [currentFilterList, setCurrentFilterList] = useState("filters");
    const [sectionBlock, setSectionBlock] = useState("policiesFiltersToggleSection");
    const [showSource, setShowSource] = useState({
        all : true,
        origination : true,
        servicing : true
    });
    const [showLeadSource, setShowLeadSource] = useState({
        all : true,
        origination : true,
        servicing : true
    });
    const [showPolicyStatus, setShowPolicyStatus] = useState({
        all : true,
        origination : true,
        servicing : true
    });

    function handleDoubleClick(data, keyValue, nested){
        props.toggleFiltersSubGroup(data.type, data.accountType, keyValue, nested);
    };

    function loadPoliciesFilters(){
        const policiesFilters = [];
        let affiliatesFilters = [];
        let statusFilters = [];
        let leadSourceFilters = [];
        const inactiveActive = {
            inactive: 0,
            active: 0,
        };

        let categoryData = {
            affiliates: {
              origination: { ...inactiveActive },
              servicing: {
                account: { ...inactiveActive },
                funds: { ...inactiveActive },
              },
            },
            leadSource: {
              origination: { ...inactiveActive },
              servicing: { ...inactiveActive },
            },
            status: {
              origination: { ...inactiveActive },
              servicing: { ...inactiveActive },
            },
        };

        Object.keys(props.filterHandler).forEach(function(data, y){
            if(currentFilterList === "filters" && ["affiliates", "leadSource", "status"].includes(data)){
                if(data === "status"){
                    Object.keys(props.filterHandler[data]).forEach(function(keyTag, i) {
                        if(typeof props.filterHandler[data][keyTag] === "object" && props.filterHandler?.[data]?.[keyTag]?.count !== 0){
                            if(!affiliatesFilters[props.filterHandler[data][keyTag].accountType]){
                                affiliatesFilters[props.filterHandler[data][keyTag].accountType] = [];
                            }

                            if(props.filterHandler[data][keyTag].type === "status"){
                                let statusData = returnStatusData(keyTag, null, true);
                                if(!statusFilters[props.filterHandler[data][keyTag].accountType]){
                                    statusFilters[props.filterHandler[data][keyTag].accountType] = [];
                                }

                                if(!props.filterHandler[data][keyTag].active){
                                    categoryData["status"][props.filterHandler[data][keyTag].accountType].inactive++;
                                }else{
                                    categoryData["status"][props.filterHandler[data][keyTag].accountType].active++;
                                }

                                statusFilters[props.filterHandler[data][keyTag].accountType].push(
                                    <div
                                        key={keyTag + i}
                                        className={props.filterHandler[data][keyTag].active ?
                                            "item cC g bR bold p on "
                                            :
                                            "item cC g bR bold p off "
                                        }
                                        onClick={()=>{props.updateFilters(props.filterHandler[data][keyTag], "status", keyTag)}}
                                        onDoubleClick={()=>{handleDoubleClick(props.filterHandler[data][keyTag], keyTag)}}
                                    >
                                        <div className="cC f"
                                            style={{color: statusData.color}}
                                        >
                                            {<StatusIcon currentStatus={keyTag}/>}
                                        </div>
                                        <div className="cL f">
                                            {keyTag}
                                        </div>
                                        <div className="cC f">
                                            {props.filterHandler[data][keyTag].count}
                                        </div>
                                        <div className="cC f">
                                            {props.filterHandler[data][keyTag].active ?
                                                <div className="toggleStatus on cC bR">
                                                    On
                                                </div>
                                                :
                                                <div className="toggleStatus off cC bR">
                                                    Off
                                                </div>
                                            }
                                        </div>
                                    </div>
                                );
                            }
                        }
                    });

                    policiesFilters.push(
                        <div key="statuses" className={showPolicyStatus["all"] ? "filterSection g bR active" : "filterSection g bR"}>
                            <div className="filterSectionHeader p">
                                <div className="cC">
                                    <div className="defaultBtn fMP cC bold" onClick={()=>{props.toggleFiltersGroup("status")}}>
                                        {categoryData["status"]["origination"].active === 0 && categoryData["status"]["servicing"].active === 0 ?
                                            "Show All"
                                            :
                                            "Hide All"
                                        }
                                    </div>
                                </div>
                                <div className="filterSectionTitle cC"
                                    onClick={()=>{setShowPolicyStatus(prevState => ({ ...prevState, all: !prevState.all }))}}>
                                    Status
                                </div>
                                <div className="filterSectionToggle cR e" onClick={()=>{setShowPolicyStatus(prevState => ({ ...prevState, all: !prevState.all }))}}>
                                    { !showPolicyStatus["all"] ?
                                        <KeyboardArrowUpIcon/>
                                        :
                                        <KeyboardArrowDownIcon/>
                                    }
                                </div>
                            </div>
                            <div className="category cC g f fC">
                                <div className="list cC g f fC dP">
                                    {statusFilters["origination"]?.length > 0 &&
                                        <div className="header bold g f cL s">
                                            <div className="filterSectionToggle cR e" onClick={()=>{setShowPolicyStatus(prevState => ({ ...prevState, origination: !prevState.origination }))}}>
                                                { !showPolicyStatus["origination"] ?
                                                    <KeyboardArrowUpIcon/>
                                                    :
                                                    <KeyboardArrowDownIcon/>
                                                }
                                            </div>
                                            <div className="cL f p" onClick={()=>{setShowPolicyStatus(prevState => ({ ...prevState, origination: !prevState.origination }))}}>
                                                Origination Statuses
                                            </div>
                                            <div className="cC f p" onClick={()=>{props.toggleFiltersSubGroup("status", "origination")}}>
                                                {categoryData["status"]["origination"].active === 0 ?
                                                    <div className="toggleStatus off cC bR">
                                                        Off
                                                    </div>
                                                    :
                                                    <div className="toggleStatus on cC bR">
                                                        On
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {showPolicyStatus["origination"] && statusFilters["origination"]}
                                    {statusFilters["origination"]?.length > 0 && statusFilters["servicing"]?.length &&
                                        <div className="divider">
                                        </div>
                                    }
                                    {statusFilters["servicing"]?.length > 0 &&
                                        <div className="header bold g f cL s">
                                            <div className="filterSectionToggle cR e" onClick={()=>{setShowPolicyStatus(prevState => ({ ...prevState, servicing: !prevState.servicing }))}}>
                                                { !showPolicyStatus["servicing"] ?
                                                    <KeyboardArrowUpIcon/>
                                                    :
                                                    <KeyboardArrowDownIcon/>
                                                }
                                            </div>
                                            <div className="cL f p" onClick={()=>{setShowPolicyStatus(prevState => ({ ...prevState, servicing: !prevState.servicing }))}}>
                                                Fund Statuses
                                            </div>
                                            <div className="cC f p" onClick={()=>{props.toggleFiltersSubGroup("status", "servicing")}}>
                                                {categoryData["status"]["servicing"].active === 0 ?
                                                    <div className="toggleStatus off cC bR">
                                                        Off
                                                    </div>
                                                    :
                                                    <div className="toggleStatus on cC bR">
                                                        On
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {showPolicyStatus["servicing"] && statusFilters["servicing"]}
                                </div>
                            </div>
                        </div>
                    )
                }

                if(data === "affiliates"){
                    Object.keys(props.filterHandler[data]).forEach(function(keyTag, i){
                        if(typeof props.filterHandler[data][keyTag] === "object" && props.filterHandler[data][keyTag].count !== 0){
                            if(!affiliatesFilters[props.filterHandler[data][keyTag].accountType]){
                                affiliatesFilters[props.filterHandler[data][keyTag].accountType] = [];
                            }

                            if(props.filterHandler[data][keyTag].accountType === "servicing"){
                                let account = props.filterHandler[data][keyTag];
                                if(!account.active){
                                    categoryData["affiliates"]["servicing"]["account"].inactive++;
                                }else{
                                    categoryData["affiliates"]["servicing"]["account"].active++;
                                }

                                /* Account */
                                affiliatesFilters[account.accountType].push(
                                    <div
                                        key={keyTag + i}
                                        className={account.active ?
                                        "item cC g bR bold p on "
                                        :
                                        "item cC g bR bold p off "}
                                        onClick={()=>{props.updateFilters(account, "affiliates", keyTag)}}
                                        onDoubleClick={()=>{handleDoubleClick(account, keyTag)}}
                                    >
                                        {account.accountType === "origination" &&
                                            <div className="cC f"
                                                style={{color:"#00BFFF"}}>
                                                <ShieldIcon/>
                                            </div>
                                        }
                                        {account.accountType === "servicing" &&
                                            <div className="cC f"
                                                style={{color:"#ff9800"}}>
                                                <SafetyCheckIcon/>
                                            </div>
                                        }
                                        <div className="cL f">
                                            {account.accountName}
                                        </div>
                                        <div className="cC f">
                                            {account.count}
                                        </div>
                                        <div className="cC f">
                                            {account.active ?
                                                <div className="toggleStatus on cC bR">
                                                    On
                                                </div>
                                                :
                                                <div className="toggleStatus off cC bR">
                                                    Off
                                                </div>
                                            }
                                        </div>
                                    </div>
                                );

                                /* Sub Fund */
                                // if (Object?.keys(props?.filterHandler?.[data]?.[keyTag]?.funds)?.length > 1) {
                                if (props?.filterHandler?.[data]?.[keyTag]?.funds && Object.keys(props.filterHandler[data][keyTag].funds).length > 1) {
                                    let nestedFunds = [];
                                    for (const index in props?.filterHandler?.[data]?.[keyTag]?.funds) {
                                        if(!props.filterHandler[data][keyTag].active){
                                            categoryData["affiliates"]["servicing"]["funds"].inactive++;
                                        }else{
                                            categoryData["affiliates"]["servicing"]["funds"].active++;
                                        }
    
                                        const fund = props.filterHandler[data][keyTag].funds[index];
                                        nestedFunds.push(
                                            <div
                                                key={keyTag + i + fund.fundID}
                                                className={
                                                props.filterHandler[data][keyTag].active ?
                                                    "item cC g bR bold p on nested"
                                                :
                                                    "item cC g bR bold p off nested"
                                                }
                                                onClick={()=>{props.updateFilters(fund, "affiliates", keyTag, fund.fundID)}}
                                                onDoubleClick={()=>{handleDoubleClick(fund, fund.fundID, "funds")}}
                                            >
                                                <div className="cC f"
                                                    style={{color:"#ff9800"}}>
                                                    <SafetyCheckIcon/>
                                                </div>
                                                <div className="cL f">
                                                    {fund.fundName}
                                                </div>
                                                <div className="cC f">
                                                    {fund.count}
                                                </div>
                                                <div className="cC f">
                                                    {fund.active ?
                                                        <div className="toggleStatus on cC bR">
                                                            On
                                                        </div>
                                                        :
                                                        <div className="toggleStatus off cC bR">
                                                            Off
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    }
    
                                    affiliatesFilters[props.filterHandler[data][keyTag].accountType].push(
                                        <div className="nestedCat g" key={keyTag}>
                                            <div className="vDivider">
                                            </div>
                                            <div className="list g fC cL">
                                                {nestedFunds}
                                            </div>
                                        </div>
                                    );
                                }
                            }

                            if(props.filterHandler[data][keyTag].accountType === "origination"){
                                if(!props.filterHandler[data][keyTag].active){
                                    categoryData["affiliates"][props.filterHandler[data][keyTag].accountType].inactive++;
                                }else{
                                    categoryData["affiliates"][props.filterHandler[data][keyTag].accountType].active++;
                                }

                                let account = props.filterHandler[data][keyTag];

                                affiliatesFilters[account.accountType].push(
                                    <div
                                        key={keyTag + i}
                                        className={account.active ?
                                        "item cC g bR bold p on "
                                        :
                                        "item cC g bR bold p off "}
                                        onClick={()=>{props.updateFilters(account, "affiliates", keyTag)}}
                                        onDoubleClick={()=>{handleDoubleClick(account, keyTag)}}
                                    >
                                        {account.accountType === "origination" &&
                                            <div className="cC f"
                                                style={{color:"#00BFFF"}}>
                                                <ShieldIcon/>
                                            </div>
                                        }
                                        {account.accountType === "servicing" &&
                                            <div className="cC f"
                                                style={{color:"#ff9800"}}>
                                                <SafetyCheckIcon/>
                                            </div>
                                        }
                                        <div className="cL f">
                                            {account.accountName}
                                        </div>
                                        <div className="cC f">
                                            {account.count}
                                        </div>
                                        <div className="cC f">
                                            {account.active ?
                                                <div className="toggleStatus on cC bR">
                                                    On
                                                </div>
                                                :
                                                <div className="toggleStatus off cC bR">
                                                    Off
                                                </div>
                                            }
                                        </div>
                                    </div>
                                );
                            }
                        }
                    });

                    if(parseJWT(props.sessionUser?.sessionToken)?.managedAccounts?.length > 1){
                        policiesFilters.push(
                            <div
                                key="sources"
                                className={showSource["all"] ? "filterSection g bR active" : "filterSection g bR"}>
                                <div className="filterSectionHeader p">
                                    <div className="cC">
                                        <div className="defaultBtn fMP cC bold" onClick={()=>{props.toggleFiltersGroup("affiliates")}}>
                                            {categoryData["affiliates"]["origination"].active === 0 && categoryData["affiliates"]["servicing"].active === 0 ?
                                                "Show All"
                                                :
                                                "Hide All"
                                            }
                                        </div>
                                    </div>
                                    <div className="filterSectionTitle cC" onClick={()=>{setShowSource(prevState => ({ ...prevState, all: !prevState.all }))}}>
                                        Affiliates
                                    </div>
                                    <div className="filterSectionToggle cR e" onClick={()=>{setShowSource(prevState => ({ ...prevState, all: !prevState.all }))}}>
                                        { !showSource["all"] ?
                                            <KeyboardArrowUpIcon/>
                                            :
                                            <KeyboardArrowDownIcon/>
                                        }
                                    </div>
                                </div>
                                <div className="category cC g f fC">
                                    <div className="list cC g f fC dP">
                                        {affiliatesFilters["origination"]?.length > 0 &&
                                            <div className="header g bold f cL s">
                                                <div className="filterSectionToggle cR e" onClick={()=>{setShowSource(prevState => ({ ...prevState, origination: !prevState.origination }))}}>
                                                    { !showSource["origination"] ?
                                                        <KeyboardArrowUpIcon/>
                                                        :
                                                        <KeyboardArrowDownIcon/>
                                                    }
                                                </div>
                                                <div className="cL f p" onClick={()=>{setShowSource(prevState => ({ ...prevState, origination: !prevState.origination }))}}>
                                                    Origination Affiliates
                                                </div>
                                                <div className="cC f p" onClick={()=>{props.toggleFiltersSubGroup("affiliates", "origination", null)}}>
                                                    {categoryData["affiliates"]["origination"].active === 0 ?
                                                        <div className="toggleStatus off cC bR">
                                                            Off
                                                        </div>
                                                        :
                                                        <div className="toggleStatus on cC bR">
                                                            On
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {showSource["origination"] && affiliatesFilters["origination"]}
                                        {affiliatesFilters["origination"]?.length > 0 && affiliatesFilters["servicing"]?.length &&
                                            <div className="divider">
                                            </div>
                                        }
                                        {affiliatesFilters["servicing"]?.length > 0 &&
                                            <div className="header g bold f cL s">
                                                <div className="filterSectionToggle cR e" onClick={()=>{setShowSource(prevState => ({ ...prevState, servicing: !prevState.servicing }))}}>
                                                    { !showSource["servicing"] ?
                                                        <KeyboardArrowUpIcon/>
                                                        :
                                                        <KeyboardArrowDownIcon/>
                                                    }
                                                </div>
                                                <div className="cL f p" onClick={()=>{setShowSource(prevState => ({ ...prevState, servicing: !prevState.servicing }))}}>
                                                    Fund Affiliates
                                                </div>
                                                <div className="cC f p" onClick={()=>{props.toggleFiltersSubGroup("affiliates", "servicing", null, "funds")}}>
                                                    {categoryData["affiliates"]["servicing"]["account"].active === 0 ?
                                                        <div className="toggleStatus off cC bR">
                                                            Off
                                                        </div>
                                                        :
                                                        <div className="toggleStatus on cC bR">
                                                            On
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {showSource["servicing"] &&
                                            affiliatesFilters["servicing"]
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    }
                }

                if(data === "leadSource"){
                    /* Lead Source */
                    Object.keys(props.filterHandler[data]).forEach(function(keyTag, i){
                        if(typeof props.filterHandler[data][keyTag] === "object" && props.filterHandler[data][keyTag].count !== 0){
                            if(!leadSourceFilters[props.filterHandler[data][keyTag].accountType]){
                                leadSourceFilters[props.filterHandler[data][keyTag].accountType] = [];
                            }

                            if(!props.filterHandler[data][keyTag].active){
                                categoryData["leadSource"][props.filterHandler[data][keyTag].accountType].inactive++;
                            }else{
                                categoryData["leadSource"][props.filterHandler[data][keyTag].accountType].active++;
                            }

                            let leadSourceInfo = leadSourceTag(keyTag, true);
                            leadSourceFilters[props.filterHandler[data][keyTag].accountType].push(
                                <div
                                    key={keyTag + i}
                                    className={props.filterHandler[data][keyTag].active ?
                                    "item cC g bR bold p on " + leadSourceInfo.className
                                    :
                                    "item cC g bR bold p off " + leadSourceInfo.className}
                                    onClick={()=>{props.updateFilters(props.filterHandler[data][keyTag], "leadSource", keyTag)}}
                                    onDoubleClick={()=>{handleDoubleClick(props.filterHandler[data][keyTag], keyTag)}}
                                >
                                    <div className="cC f">
                                        {leadSourceInfo.icon}
                                    </div>
                                    <div className="cL f">
                                        {keyTag}
                                    </div>
                                    <div className="cC f">
                                        {props.filterHandler[data][keyTag].count}
                                    </div>
                                    <div className="cC f">
                                        {props.filterHandler[data][keyTag].active ?
                                            <div className="toggleStatus on cC bR">
                                                On
                                            </div>
                                            :
                                            <div className="toggleStatus off cC bR">
                                                Off
                                            </div>
                                        }
                                    </div>
                                </div>
                            )

                        }
                    });

                    /* Lead Source */
                    policiesFilters.push(
                        <div
                            key="leadSources"
                            className={showLeadSource["all"] ? "filterSection g bR active" : "filterSection g bR"}>
                            <div className="filterSectionHeader p">
                                <div className="cC">
                                    <div className="defaultBtn fMP cC bold" onClick={()=>{props.toggleFiltersGroup("leadSource")}}>
                                        {categoryData["leadSource"]["origination"].active === 0 && categoryData["leadSource"]["servicing"].active === 0 ?
                                            "Show All"
                                            :
                                            "Hide All"
                                        }
                                    </div>
                                </div>
                                <div className="filterSectionTitle cC" onClick={()=>{setShowLeadSource(prevState => ({ ...prevState, all: !prevState.all }))}}>
                                    Lead Source
                                </div>
                                <div className="filterSectionToggle cR e" onClick={()=>{setShowLeadSource(prevState => ({ ...prevState, all: !prevState.all }))}}>
                                    { !showLeadSource["all"] ?
                                        <KeyboardArrowUpIcon/>
                                        :
                                        <KeyboardArrowDownIcon/>
                                    }
                                </div>
                            </div>
                            <div className="list cC g f fC dP">
                                {/* {leadSourceFilters["origination"]?.length > 0 &&
                                    <div className="header bold g f cL s">
                                        <div className="filterSectionToggle cR e" onClick={()=>{setShowLeadSource(prevState => ({ ...prevState, origination: !prevState.origination }))}}>
                                            { !showLeadSource["origination"] ?
                                                <KeyboardArrowUpIcon/>
                                                :
                                                <KeyboardArrowDownIcon/>
                                            }
                                        </div>
                                        <div className="cL f p" onClick={()=>{setShowLeadSource(prevState => ({ ...prevState, origination: !prevState.origination }))}}>
                                            Origination Lead Sources
                                        </div>
                                        <div className="cC f p" onClick={()=>{props.toggleFiltersSubGroup("leadSource", "origination")}}>
                                            {categoryData["leadSource"]["origination"].active === 0 ?
                                                <div className="toggleStatus off cC bR">
                                                    Off
                                                </div>
                                                :
                                                <div className="toggleStatus on cC bR">
                                                    On
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {showLeadSource["origination"] && leadSourceFilters["origination"]}
                                {leadSourceFilters["origination"]?.length > 0 && leadSourceFilters["servicing"]?.length &&
                                    <div className="divider">
                                    </div>
                                } */}
                                {leadSourceFilters["servicing"]?.length > 0 &&
                                    <div className="header bold g f cL s">
                                        <div className="filterSectionToggle cR e" onClick={()=>{setShowLeadSource(prevState => ({ ...prevState, servicing: !prevState.servicing }))}}>
                                            { !showLeadSource["servicing"] ?
                                                <KeyboardArrowUpIcon/>
                                                :
                                                <KeyboardArrowDownIcon/>
                                            }
                                        </div>
                                        <div className="cL f p" onClick={()=>{setShowLeadSource(prevState => ({ ...prevState, servicing: !prevState.servicing }))}}>
                                            Fund Lead Sources
                                        </div>
                                        <div className="cC f p" onClick={()=>{props.toggleFiltersSubGroup("leadSource", "servicing")}}>
                                            {categoryData["leadSource"]["servicing"].active === 0 ?
                                                <div className="toggleStatus off cC bR">
                                                    Off
                                                </div>
                                                :
                                                <div className="toggleStatus on cC bR">
                                                    On
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {showLeadSource["servicing"] && leadSourceFilters["servicing"]}
                            </div>
                        </div>
                    );
                }
            }

            function printRangeCategory(category){
                const filterFunctions = props?.policiesProps?.sessionVars?.envVars?.filterHandler?.functions;
                const categoryData = filterFunctions.groupData(category);
                const filterHandler = props?.policiesProps?.sessionVars?.envVars?.filterHandler;
                const filterBranch = filterHandler?.policies;

                function convertInt(value) {
                    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
                    return formatter.format(value);
                }
        
                let rangeData = {
                    minVal : filterBranch?.[category]?.minVal,
                    maxVal : filterBranch?.[category]?.maxVal
                }
        
                return (
                    <div key={category} className="category range g fC f cC ns">
                        <div className="rangeHeader f g">
                            <div className="f cC">
                                {categoryData.icon}
                            </div>
                            <div className="f cL bold">
                                {categoryData.prompt + " " + convertInt(filterBranch?.[category]?.minVal) + " - " + convertInt(filterBranch?.[category]?.maxVal)}
                            </div>
                            {filterBranch?.[category]?.empty?.count > 0 && (
                                <>
                                    <div
                                        className={filterBranch?.[category]?.empty?.active ? "f cR p gC4" : "inactive f cR p gC4"}
                                        onClick={() => { filterFunctions?.updateFilterAttr(null, props?.branch, props?.stem, category, "empty", "active") }}>
                                        {"Include " + filterBranch?.[category]?.empty?.count + " Empty Values"}
                                    </div>
                                    <div
                                        className="f cC p oH" onClick={() => { filterFunctions?.updateFilterAttr(null, props?.branch, props?.stem, category, "empty", "active") }}>
                                        {filterBranch?.[category]?.empty?.active ? <CheckCircleIcon sx={{ color: "#00BFFF" }} /> : <RadioButtonUncheckedIcon sx={{ color: "#E2E2E2" }} />}
                                    </div>
                                </>
                            )}
                        </div>
                        <RangeSlider
                            rangeData={rangeData}
                            functionPointer={filterFunctions?.alterRange}
                            fixedMinVal={filterBranch?.[category]?.fixedMinVal}
                            fixedMaxVal={filterBranch?.[category]?.fixedMaxVal}
                            branch={props?.branch}
                            stem={props?.stem}
                            category={category}
                            reset={filterHandler?.functions?.alterRange}
                            prompt={prompt}
                        />
                    </div>
                );
            }

            if(currentFilterList === "priceRangeGroup"){

                policiesFilters[0] = <div className="section fMP dP tO g">
                                        <div className="body">
                                            {printRangeCategory("deathBenefit")}
                                        </div>
                                    </div>;
                    // <div key="policiesRangeBoard" className="policiesRangeBoard g fC tC">
                    //     <div className="policiesRangeBoardBlock">
                    //         <div className="policiesRangeBoardHeader">
                    //             Death Benefit Range
                    //         </div>
                    //         <RangeSlider
                    //             rangeData = {props.deathBenefitsRange}
                    //             functionPointer={props.updateDeathBenefitsRange}
                    //             fixedMin={props.deathBenefitsRange.fixedMinVal}
                    //             fixedMax={props.deathBenefitsRange.fixedMaxVal}
                    //             deathBenefitsRangeReset={props.deathBenefitsRangeReset}
                    //             updateDeathBenefitsRangeReset={props.updateDeathBenefitsRangeReset}
                    //         />
                    //         <div>
                    //             <div className="policyRangeInfo">
                    //                 { "Showing policies in range of" }
                    //             </div>
                    //             <div className="policiesRangeCurrentValues">
                    //                 {convertInt(props.deathBenefitsRange.minVal) + " - " + convertInt(props.deathBenefitsRange.maxVal) }
                    //             </div>
                    //         </div>
                    //     </div>
                    //     <div className="policiesRangeBoardBlock dP">
                    //         <ToggleOption
                    //             toggleStatus = {props.emptyDeathBenefit}
                    //             functionPointer = {props.updateEmptyDeathBenefitStatus}
                    //             optionString={"Include Death Benefits that are N/A"}
                    //             totalCount = {props.filterHandler.priceRangeGroup.emptyCount}
                    //             showCount = {true}
                    //         />
                    //     </div>
                    //     <div className="policiesRangeBoardBlock">
                    //         <div className="policiesRangeMessage">
                    //             { "Currently " + countEligablePriceRange() + " out of " + countTotalInPriceRange() +
                    //                  " policies meet this range" }
                    //         </div>
                    //     </div>
                    // </div>
            }
        });

        return policiesFilters;
    }

    function checkCurrentFilterList(filterListString){
        if(filterListString === null){
            return currentFilterList;
        }

        if(currentFilterList === filterListString){
            return " selected";
        }

        return "";
    }

    function updateCurrentFilterList(filterListString){
        setCurrentFilterList(filterListString);
        setSectionBlock(updateSectionBlock(filterListString));
    }

    function convertInt(value){
        let formatter = Intl.NumberFormat('en', { notation: 'compact' });
        return formatter.format(value);
    }
    
    function updateSectionBlock(filterListString){
        if(filterListString === "filters"){
            return "policiesFiltersToggleSection";
        }

        if(filterListString === "priceRangeGroup"){
            return "policiesFiltersRangeSliderSection";
        }
    }

    function countEligablePriceRange(){
        let totalCount = props.filterHandler.priceRangeGroup.deathBenefits.reduce((r,n) => n >= props.deathBenefitsRange.minVal && n <= props.deathBenefitsRange.maxVal ? ++r : r, 0);

        if(props.emptyDeathBenefit){
            totalCount = totalCount + props.filterHandler.priceRangeGroup.emptyCount
        }

        return totalCount;
    }

    function countTotalInPriceRange(){
        let totalCount = props.filterHandler.priceRangeGroup.deathBenefits.length;

        if(props.emptyDeathBenefit){
            totalCount = totalCount + props.filterHandler.priceRangeGroup.emptyCount;
        }
        
        return totalCount;
    }

    function maintainCongifureButtons(){
        let buttonDisplay = [];

        if(checkEligableRangeBtn() && sectionBlock === "policiesFiltersRangeSliderSection"){
            buttonDisplay.push(
                <div key={"resetToggleAllBtn"} className="toggleAllBtn">
                    <div onClick={ () => { props.resetDeathBenefitsRange() }}>
                        Reset Range
                    </div>
                </div>
            );
        }

        return buttonDisplay;
    }

    function checkEligableRangeBtn(){
        if(props.deathBenefitsRange.minVal === props.deathBenefitsRange.fixedMinVal && props.deathBenefitsRange.maxVal === props.deathBenefitsRange.fixedMaxVal){
            return false
        }
        
        return true;
    }

    useEffect(() => {
        //console.log(props);
    }, []);

    return (
        props.deathBenefitsRange.fixedMinVal === 0 && props.deathBenefitsRange.fixedMaxVal === 0 ? null :
        <div className="policiesFiltersModule moduleBlock">
            <div className="policiesFiltersModuleInner g cC fC">
                <div className="policiesFiltersHeaderWrap cC">
                    {/* <div className="policiesFiltersHeader cR g fR f">
                        <div className={"switchViewBtn f g cC s e" + checkCurrentFilterList("filters") } onClick = { () => { updateCurrentFilterList("filters")}}>
                            <div className="switchViewBtnOption">
                                Toggles
                            </div>
                            <div className="inlineBtnIcon">
                                <ToggleOffOutlinedIcon/>
                            </div>
                        </div>
                        <div className={"switchViewBtn f g cC s e" + checkCurrentFilterList("priceRangeGroup") } onClick = { () => { updateCurrentFilterList("priceRangeGroup")}}>
                            <div className="switchViewBtnOption">
                                Death Benefit Range
                            </div>
                            <div className="inlineBtnIcon">
                                <AttachMoneyIcon/>
                            </div>
                        </div> 
                        
                    </div>*/}
                    Toggles
                </div>
                <div className="closeBtn" onClick={ props.toggleFilterStatus }>
                    <CloseIcon/>
                </div>
                <div className={ sectionBlock + " fMP dP tO g"}>
                    <div className="content g fC">
                        { loadPoliciesFilters() }
                    </div>
                </div>
                <div className="policiesFiltersFooter"></div>
                { maintainCongifureButtons() }
            </div>
        </div>
    )
}

export default PoliciesFilters;