import React from 'react';
import { useState, useEffect, useRef } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import InputField from "../Components/InputField.js";
import ViewBarBtn from '../Components/ViewBarBtn.js';
import ReportSheet from '../Components/Reporting/ReportSheet.js';
import ReportList from '../Components/Reporting/ReportList.js';

function Reporting(props){
    const [requestingBatch, setRequestingBatch] = useState(undefined);
    const [search, setSearch] = useState({
        searching : props?.viewType !== "mobile" ? true : undefined,
        searchValue : ''
    });
    const dataPointerStems = [
        "deathTrac",
        "lifeTrac",
    ];

    // const [sortedListResults, setSortedListResults] = useState();
    const [reportingView, setReportingView] = useState("list");
    const [editingRowValues, setEditingRowValues] = useState();
    const [editCellDropdownHandler, setEditCellDropdownHandler] = useState({ showMenu : undefined });
    const [editMode, setEditMode] = useState(false);

    const reportTable = useRef(null);

    const session = props?.session;
    const reporting = session?.reporting;
    const allReports = props?.session?.reporting?.data?.allReports;
    const selectedReport = reporting?.data?.selectedReport;
    const updateReporting = session?.reporting?.setData;
    const viewableReport = selectedReport?.query && selectedReport?.columns?.length > 0;
    const userOpportunityTypes = session?.user?.data?.opportunityTypes;

    const initialReportCategory = selectedReport?.stem || (userOpportunityTypes && userOpportunityTypes[0]) || "deceased";
    const reportType = reporting?.functions?.reportType(selectedReport?.stem);

    const [reportCategory, setReportCategory] = useState(initialReportCategory);
    const reportCategoryObj = {reportCategory, setReportCategory}

    const [existingColumnsCopy, setExistingColumnsCopy] = useState(selectedReport?.columns);

    /* Dropdowns */
    const [pagination, setPagination] = useState({
        showMenu : false,
        rowMax : session?.storage?.reporting?.rowMax ?? 100,
        index : selectedReport?.lastPageIndex ?? 1,
    });
    const [selectedFolder, setSelectedFolder] = useState({
        name : "Show All",
        showMenu : false
    });
    const resetShowMenu = (setDropdownState) => {
        setDropdownState((prev) => {
            // Check if showMenu is already true
            if (prev?.showMenu) {
                return { ...prev, showMenu: false };
            }
            // If showMenu is not true, return the previous state without changes
            return prev;
        });
    };

    const resetDropdowns = () => {
        if(pagination?.showMenu){
            resetShowMenu(setPagination);
        }
        if(selectedFolder?.showMenu){
            resetShowMenu(setSelectedFolder);
        }
        if(editCellDropdownHandler?.showMenu){
            resetShowMenu(setEditCellDropdownHandler);
        }
    };

    const requestObject = {
        session,
        requestingBatch,
        setRequestingBatch,
        pagination,
        setExistingColumnsCopy
    }

    useEffect(() => {
        if(selectedReport?.stem && reportCategory !== selectedReport?.stem){
            setReportCategory(selectedReport?.stem);
        }
    }, [selectedReport?.stem]);

    useEffect(() => {
        if(selectedReport?.query){
            if(reportType === "inherited" || selectedReport?.stem === "scheduled"){
                const items = props?.session?.[reporting?.data?.dataPointer]?.data?.[selectedReport?.branch]?.[selectedReport?.stem];
                const filteredResults = reporting?.functions?.filterItemsWithQuery(selectedReport?.query, items);
                updateReporting("selectedReport", "list", filteredResults);
                setEditingRowValues([]);
                setEditMode(selectedReport?.editable === "always" ?? false);
            }
        }

        if(selectedReport?.lastRecordID !== selectedReport?.recordID){
            updateReporting("sorting", []);
            setExistingColumnsCopy(selectedReport?.columns);
            props?.session?.reporting?.functions?.updateSelectedReport("lastPageIndex", 1);    
            setPagination((prev) => ({
                ...prev,
                index: 1,
            }));
        }

        setSearch({
            searching: false,
            searchValue: '',
        });

        props?.session?.reporting?.functions?.updateSelectedReport("lastRecordID", selectedReport?.recordID);
        if (reportTable.current) {
            reportTable.current.scrollLeft = 0;
        }
    }, [
        selectedReport?.recordID,
        ...dataPointerStems.map(key => session?.[reporting?.data?.dataPointer]?.data?.[key])
    ]);

    useEffect(() => {
        if(search?.searching === true && props?.viewType === "mobile"){
            document?.getElementById('searchBarInput')?.focus();
        }else{
            setSearch((prevSearch) => ({
                ...prevSearch,
                searchValue: '',
            }));
        }
    }, [search?.searching]);

    useEffect(() => {
        if(reportCategory === undefined){
            setReportCategory(userOpportunityTypes?.[0])
        }
    }, [userOpportunityTypes]);

    return (
        <div className="reportingPage g bR" onClick={resetDropdowns}>
            <div className="reportingHub g oH">
                {props?.viewType === "mobile" &&
                    <div className="reportingNavBar f g cC">
                        <div className="f cC t">
                            <div className="viewBar cC">
                                <ViewBarBtn
                                    isActive={reportingView === "list"}
                                    onClick={() => setReportingView("list")}
                                    label="All Reports"
                                    alertType={"Status"}
                                    viewType={props?.viewType}
                                    session={session}
                                    currentPolicy={props?.currentPolicy}
                                />
                                <ViewBarBtn
                                    isActive={reportingView === "report"}
                                    onClick={() => setReportingView("report")}
                                    label="Selected Report"
                                    alertType={"Status"}
                                    viewType={props?.viewType}
                                    session={session}
                                    currentPolicy={props?.currentPolicy}
                                />
                            </div>
                        </div>
                    </div>
                }
                <div className={`actionBar g fR cC s${search?.searching ? " searching" : ''}${props?.viewType === "mobile" ? " " + reportingView : ''} ${reportType ?? "generated"}`}>
                    {(props?.viewType !== "mobile" || (props?.viewType === "mobile" && reportingView === "list")) &&
                        <div className="bold">
                            {reportCategory ? `${reportCategory?.charAt(0)?.toUpperCase() + reportCategory?.slice(1)} Reports` : "Select Category"}
                        </div>
                    }
                    {reportType === "inherited" &&
                        <div className="searchContainer f g cC">
                            {(props?.viewType !== "mobile" || (props?.viewType === "mobile" && reportingView === "report")) && reportType === "inherited" &&
                                <div className={`searchBtn f s cL fR lH${search?.searching ? " active" : ''}`}>
                                    <div
                                        className="btn cC"
                                        onClick={() => {
                                            if(props?.viewType === "mobile"){
                                                setSearch((prevSearch) => ({
                                                    ...prevSearch,
                                                    searching: !search?.searching,
                                                }));
                                            }
                                        }}
                                    >
                                        {search?.searching && props?.viewType === "mobile" ?
                                            <SearchOffIcon/>
                                        :
                                            <SearchIcon/>
                                        }
                                    </div>
                                </div>
                            }
                            {(props?.viewType !== "mobile" || (props?.viewType === "mobile" && reportingView === "report" && search?.searching)) &&
                                (reportType === "inherited" || props?.viewType === "mobile") &&
                                <div className={`searchBar g f bold cC fR fC`}>
                                    <InputField
                                        search={true}
                                        value={search?.searchValue ?? ''}
                                        placeholder={`Search ${reporting?.data?.appSourceType}s`}
                                        name={"searchValue"}
                                        readOnly={false}
                                        functionPointer={session?.env?.functions?.updateInput}
                                        setter={setSearch}
                                        autoComplete={"off"}
                                        id={"searchBarInput"}
                                    />
                                </div>
                            }
                        </div>
                    }
                    {reportType === "dynamic" && selectedReport?.subReport?.list &&
                        <div className="f cL">
                            <div
                                className="btnWIcon gC2 bR dG cC p bold lH"
                                onClick={()=>{
                                    const subReport = {...selectedReport?.subReport};
                                    subReport.list = undefined;
                                    subReport.columns = undefined;        
                                    subReport.name = undefined;        
                                    updateReporting("selectedReport", "subReport", subReport);
                                }}
                            >
                                <ChevronLeftIcon/>
                                <div className="f cC e">
                                    {selectedReport?.details?.name ?? "Unnamed Report"}
                                </div>
                            </div>
                        </div>
                    }
                    {(props?.viewType !== "mobile"
                        || (props?.viewType === "mobile" && reportingView === "report" && !search?.searching)
                        || reportType === "inherited" && reportingView === "report" && !search?.searching) &&
                        <div
                            className={`reportTitle g f bold cC fR fC ${props?.viewType === "mobile" ? "gC2" : "gC3"}`}
                            onClick={()=>{console.log(session)}}
                        >
                            {`${selectedReport?.details?.name ?? "Unnamed Report"}${selectedReport?.subReport?.name ?? ''}`}
                        </div>
                    }
                    {(props?.viewType !== "mobile") &&
                        <div
                            className={`btnOptions cR g fR fC f dG gC4${selectedReport?.editable ? " editable" : ''}`}
                        >
                            {(reportType === "inherited" || reportType === "dynamic") &&
                                <div
                                    className={`btnWIcon bold bR fMP cC ns${viewableReport && selectedReport?.list?.length ? " p" : " inactive"}`}
                                    onClick={viewableReport && selectedReport?.list?.length ? ()=>{reporting?.functions?.downloadReport(session)} : null}
                                >
                                    <div className="f cC lH">
                                        Download
                                    </div>
                                    <DownloadIcon/>
                                </div>
                            }
                            <div
                                className="btn more cR p"
                                onClick={()=>{
                                    session?.env?.setOverlay("reportOptions");
                                }}
                            >
                                <MoreHorizIcon/>
                            </div>
                            {/* {reportType === "inherited" && selectedReport?.editable &&
                                <div
                                    className={`btnWIcon bold bR fMP cC ns p${editMode ? " filled" : ''}`}
                                    onClick={()=>{setEditMode(!editMode)}}
                                >
                                    <div className="f cC lH">
                                        {`${editMode ? "Editing" : "Edit"}`}
                                    </div>
                                    <EditIcon/>
                                </div>
                            } */}
                        </div>
                    }
                    {(props?.viewType === "mobile" && reportingView === "report") &&
                        <div className={
                            `btnOptions cC g fR fC f dG ${(props?.viewType === "mobile"
                            && reportingView === "report"
                            && search?.searching) ?
                            "gC2" : "gC3"}`}
                        >
                            {reportType === "inherited" &&
                                <div
                                    className={`btn circle bold bR fMP cC ns${!viewableReport ? " inactive" : " p"}`}
                                    onClick={viewableReport ? ()=>{ reporting?.functions?.downloadReport(selectedReport, selectedReport?.sortedListResults)} : null}
                                >
                                    <DownloadIcon/>
                                </div>
                            }
                        </div>
                    }
                </div>
                <div className="reportBuilder f fR g s oH">
                    {(props?.viewType !== "mobile" || (props?.viewType === "mobile" && reportingView === "list")) &&
                        <ReportList
                            session={session}
                            requestObject={requestObject}
                            reportCategoryObj={reportCategoryObj}
                            selectedFolder={{data : selectedFolder, set : setSelectedFolder}}
                            resetDropdowns={resetDropdowns}
                            reportingView={{data : reportingView, set : setReportingView }}
                        />
                    }
                    {(props?.viewType !== "mobile" || (props?.viewType === "mobile" && reportingView === "report")) &&
                        <ReportSheet
                            session={props?.session}
                            requestObject={requestObject}
                            reportType={reportType}
                            search={{ data : search, set : setSearch}}
                            reportCategoryObj={reportCategoryObj}
                            pagination={{data : pagination, set : setPagination}}
                            editingRowValues={{data : editingRowValues, set : setEditingRowValues}}
                            // sortedListResults={{data : sortedListResults, set : setSortedListResults}}
                            resetDropdowns={resetDropdowns}
                            editCellDropdownHandler={{
                                data: editCellDropdownHandler,
                                set: (newValue) => setEditCellDropdownHandler(prev => ({ ...prev, showMenu: newValue }))
                            }}
                            editMode={editMode}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default Reporting;