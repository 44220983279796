import React, { useCallback } from 'react';
import { useState, useEffect, useRef } from 'react';
import Dropdown from '../Dropdown';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

function EditMode(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const selectedReport = reporting?.data?.selectedReport
    const columnValue = props?.columnValue;
    const rowIndex = props?.rowIndex;
    const colIndex = props?.colIndex;
    const columnName = props?.columnData?.columnName;
    const attributeData = session?.[reporting?.data?.dataPointer]?.data?.attributeData;
    const columnData = attributeData?.[columnName];

    const dataArray = props?.editingRowValues?.data;
    const searchValue = props?.rowObj?.[reporting?.data?.selectAllVar];
    const foundRowObject = dataArray.find(obj => obj[reporting?.data?.selectAllVar] === searchValue);
    const [isTyping, setIsTyping] = useState(false);

    const inputValueRef = useRef(foundRowObject?.[columnName] ?? columnValue);
    const [inputValue, setInputValue] = useState(inputValueRef.current);

    const [showMenu, setShowMenu] = useState(props?.editCellDropdownHandler?.data?.showMenu ?? undefined);

    const recordID = props.rowObj[reporting.data.selectAllVar];
    const editInputRef = useRef(null);

    const debounce = (func, wait) => {
        let timeout;
    
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
    
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    };

    const deepEqual = (a, b) => {
        if (typeof a === 'string' && typeof b === 'number') {
            return a === b.toString();
        }
        if (typeof a === 'number' && typeof b === 'string') {
            return a.toString() === b;
        }
    
        if (typeof a !== typeof b) {
            return false;
        }
        
        if (typeof a !== 'object' || a === null || b === null) {
            return a === b;
        }
        
        if (Array.isArray(a) && Array.isArray(b)) {
            if (a.length !== b.length) {
                return false;
            }
            for (let i = 0; i < a.length; i++) {
                if (!deepEqual(a[i], b[i])) {
                    return false;
                }
            }
            return true;
        } else { // Handle plain objects
            const keysA = Object.keys(a);
            const keysB = Object.keys(b);
            if (keysA.length !== keysB.length) {
                return false; // Different number of properties
            }
            
            for (let key of keysA) {
                if (!keysB.includes(key) || !deepEqual(a[key], b[key])) {
                    return false; // Different properties or unequal property values
                }
            }
            return true; // All properties are equal
        }
    };

    const debouncedUpdate = useCallback(debounce((value) => {
        props.editingRowValues.set(prev => {
            // Find the index of the object with the matching recordID in the editingRowValues
            const index = prev.findIndex(item => item.recordID === recordID);
    
            // Find the corresponding original object from selectedReport?.list
            const originalObj = reporting?.data?.selectedReport?.list.find(item => item[reporting?.data?.selectAllVar] === recordID);
            let updatedArray = [...prev];
    
            if (index !== -1) {
                // Update the object at the found index with the new value
                updatedArray[index] = {
                    ...updatedArray[index],
                    [columnName]: value,
                };
    
                // Check if the updated object matches the original object using deep comparison
                if (deepEqual(updatedArray[index], originalObj)) {
                    // If they match, remove the object from the array
                    updatedArray.splice(index, 1);
                }
            } else {
                // If the object doesn't exist, add a new one with the complete rowObj and set the specific column value
                const newRowObj = { ...props.rowObj, [columnName]: value, recordID: recordID }; // Ensure rowObj includes recordID or add it explicitly
                updatedArray.push(newRowObj);
    
                // Check if the new object matches the original object using deep comparison
                if (deepEqual(newRowObj, originalObj)) {
                    // If they match, remove the object from the array (in this case, the last object)
                    updatedArray.pop();
                }
            }

            return updatedArray;
        });
    }, 50), [props.editingRowValues, recordID, columnName, props.rowObj]);

    
    const debouncedSetIsTypingFalse = useCallback(debounce(() => {
        setIsTyping(false);
    }, 500), []); // Adjust the debounce time as needed

    const debouncedDropdown = useCallback(debounce((value) => {
        props?.editCellDropdownHandler?.set(value)
    }, 0), []);

    // const handleChange = (val) => {
    //     setIsTyping(true); // User starts typing
    //     const newValue = val?.target ? val?.target?.value : val;
    //     inputValueRef.current = newValue;
    //     setInputValue(newValue);
    //     debouncedUpdate(newValue);
    //     debouncedSetIsTypingFalse(); // Set isTyping to false after a delay
    // };

    const handleChange = (val) => {
        setIsTyping(true); // Indicate typing has stopped after a delay

        const inputType = columnData?.formType;
        let newValue = val?.target ? val?.target?.value : val;

        // For numeric input, remove formatting before processing
        if (columnData?.formType === "int") {
            // Remove "$" and "," from the newValue for processing
            newValue = newValue.replace(/[\$,]/g, '');
        }
        setInputValue(newValue); // Store the raw numeric value
        debouncedUpdate(newValue); // Process the raw value for any side effects
        debouncedSetIsTypingFalse(); // Set isTyping to false after a delay
    };

    function formatDateValue(value) {
        // Check if value includes a timestamp ('T' character presence)
        if (value && value.includes('T')) {
            // If it does, extract the date part before the 'T'
            return value.split('T')[0];
        }
        // If there's no 'T', return the value as it is
        return value;
    }

    function inputType(type){
        if(type === "generatedList"){
            const list = attributeData?.[columnName]?.list?.[reporting?.data?.selectedReport?.stem];
            // Filter out items that do not meet the condition
            const filteredList = Object?.entries(list || {}).reduce((acc, [key, value]) => {
                if (value?.[reporting?.data?.selectedReport?.stem]) { // Check if the item has the selectedReport?.stem attribute
                    acc[key] = value; // Keep the item in the list
                }
                return acc;
            }, {});

            return (
                <Dropdown
                    generatedList={filteredList}
                    setShowMenu={(input) => {
                        const newState = input ?? (showMenu ? undefined : `${rowIndex}-${colIndex}`);
                        setShowMenu(!newState)
                        debouncedDropdown(newState);
                    }}
                    // default={inputValue ?? props?.columnValue}
                    default={foundRowObject?.[columnName] ?? props?.columnValue}
                    showMenu={showMenu}
                    onClick={(value) => {
                        handleChange(value)
                    }}
                    reset={props?.resetDropdowns}
                    currentValue={inputValue}
                />
            )
        }

        if(type === "dropdown"){
            return (
                <Dropdown
                    list={columnData?.list}
                    setShowMenu={(input) => {
                        const newState = input ?? (showMenu ? undefined : `${rowIndex}-${colIndex}`);
                        setShowMenu(!newState)
                        debouncedDropdown(newState);
                    }}
                    default={foundRowObject?.[columnName] ?? props?.columnValue}
                    showMenu={showMenu}
                    onClick={(value) => {
                        handleChange(value)
                    }}
                    reset={props?.resetDropdowns}
                    currentValue={foundRowObject?.[columnName] ?? columnValue ?? undefined}
                />
            )
        }

        if (type === "int") {
            const formatNumberForDisplay = (value) => {
                const numericValue = parseInt(value, 10);
                if (!isNaN(numericValue)) {
                    const formattedNumber = numericValue.toLocaleString();
                    return columnData?.returnType === "currency" ? `$${formattedNumber}` : formattedNumber;
                }
                return '';
            };
        
            const inputValueFormatted = formatNumberForDisplay(inputValue);
        
            return (
                <input
                    type="text"
                    value={inputValueFormatted}
                    onChange={handleChange}
                    className="reportingInput f cC s e bold"
                />
            );
        }

        if (type === "string") {
            return (
                <input
                    key={recordID + " - " + rowIndex + " - " + colIndex}
                    className="reportingInput f cC s e bold"
                    value={inputValue ?? columnValue}
                    onChange={handleChange}
                />
            );
        }

        if(type === "date"){
            return (
                <input
                    className="inputMatch f bold"
                    type="date"
                    value={formatDateValue(inputValue ?? columnValue)}
                    onChange={handleChange}
                    ref={editInputRef}
                />
            )
        }
    }

    useEffect(() => {
        const isActive = props?.editCellDropdownHandler?.data?.showMenu === `${rowIndex}-${colIndex}`;
        if(showMenu !== isActive){
            setShowMenu(isActive);
        }
    }, [props?.editCellDropdownHandler?.data]);

    useEffect(() => {
        if (!isTyping) { // Only update the input value when the user is not typing
            const newValue = foundRowObject?.[columnName] ?? columnValue ?? undefined;

            if (newValue !== inputValueRef.current) {

                inputValueRef.current = newValue;
                setInputValue(newValue);
            }
        }
    }, [selectedReport?.sortedListResults, columnName, columnValue, isTyping]);

    return (
        <div
            key={rowIndex + colIndex}
            className={`inputMatchContainer active g f cC fC${foundRowObject?.[columnName] && foundRowObject?.[columnName] !== columnValue ? " changed" : ''}`}
        >
            {inputType(columnData?.formType)}
            {columnData?.formType === "date" &&
                <CalendarMonthIcon sx={{ color: "#42a5f5" }} />
            }
        </div>
    )
};

export default EditMode;