import React from 'react';
import { useState, useEffect } from 'react';

import './DonutChart.css';
import { returnStatusData } from '../StatusIcons.js';
import { convertIntToCurrency } from '../../envFunctions.js';

import StatusIcon from '../StatusIcons.js';

import { orange, grey, blue, green } from '@mui/material/colors';

import CircularProgress from '@mui/material/CircularProgress';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FlakyIcon from '@mui/icons-material/Flaky';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import CelebrationIcon from '@mui/icons-material/Celebration';

function DonutChart(props){
    const [currentCategoryHover, setCurrentCategoryHover] = useState("default");
    const [currentRelativeTotal, setCurrentRelativeTotal] = useState();

    useEffect(() => {
        maintainCenterMessage();
    }, [currentCategoryHover]);

    useEffect(()=>{
        setCurrentRelativeTotal(props.friendlyTotal);
    }, [props.total]);

    if(!props?.chartData){
        return (
            <div className="f loading cC">
                <div className="cC f">
                    <div>
                        <CircularProgress color="inherit"/>
                    </div>
                </div>
            </div>
        )
    }

    let categoryDataType = [];
    // let relativeTotal = props.chartData.total;
    let relativeTotal = props.chartData.friendlyTotal;
    let eligibleSubData = [];

    function maintainCenterMessage(){
        if(currentCategoryHover === "default"){
            setCurrentRelativeTotal(props.friendlyTotal);
            if(props.subData  !== undefined && props.chartData[(props.subData).toLowerCase()] !== undefined){
                setCurrentCategoryHover(props.subData + " Policies");
            }else{
                setCurrentCategoryHover("Total Policies");
            }
        }
    }

    if(props.subData){
        categoryDataType = [
            props.subData
        ];

        relativeTotal = props.chartData[(props.subData).toLowerCase()].count;

        if(props.subData === "Active"){
            eligibleSubData.push("Bids", "Escrow / At Carrier", "Rescission", "Contracts Out", "Contracts Back", "Contracts Back (Digital)");
        }
    }else{
        categoryDataType = [
            "active",
            "inactive",
            "closed"
        ];
    }

    let eachCategory = [];
    let summary = [];
    let pieceCount = 0;
    let lastPosition = 0;
    let lastAnimationDelay = 0;
    //let zIndex = relativeTotal;

    let zIndex = 0;

    let categoryColors = {
        active : {
            color : green[400]
        },
        closed : {
            // color : blue[600]
            color : orange[900]
        },
        inactive : {
            color : grey[400]
        }
    }

    function createAnimation(rules){
        var styleEl = document.createElement("style");
        document.head.appendChild(styleEl);
        var styleSheet = styleEl.sheet;
        styleSheet.insertRule(rules, 0);
    }

    function checkDataType(category){
        if(props.subData === undefined && categoryDataType.includes(category)){
            return true;
        }

        if(props.subData && props.chartData[category].position === props.subData){
            return true;
        }
    }

    function checkSummaryType(category){
        if(props.subData === undefined){
            return true;
        }

        if(props.subData !== null && eligibleSubData.includes(category)){
            return true;
        }
    }

    function loadCategoryColor(category){
        if(props.subData !== null && categoryDataType.includes(category)){
            return categoryColors[category].color;
        }
        if(props.subData && props.chartData[category].position === props.subData){
            let statusData = returnStatusData(category, "Status", true);
            return statusData.color;
        }
    }

    function loadSummaryDisplay(category, colorScheme){
        let returningDisplay = [];
        const accountsObj = props.sessionUser?.accountData;

        const categoryString = category.charAt(0).toUpperCase() + category.slice(1);
        let categoryPrompt = "0 " + categoryString;
        if(props.chartData[category]?.count > 0){
            categoryPrompt = props.chartData[category]?.count + " " + categoryString;
        }
        
        if(category === "inactive" && props.chartData[category]?.count > 0){
            categoryPrompt = props.chartData[category]?.count + " " + categoryString + " < 90 Days";
        }

        let deathBenefit = 0;

        Object.keys(accountsObj)?.forEach(accountID => {
            if (["Broker", "Agent", "Origination", "Marketing"].includes(accountsObj[accountID].type)) {
                deathBenefit += accountsObj[accountID]?.[category + "FaceValue"];
            }
        });

        returningDisplay.push(
            <div key={0} className="summaryInfoTop f g">
                <div className="f cC s">
                    {category === "active" && <AccountTreeIcon sx={{color: colorScheme}}/>}
                    {category === "closed" && <CelebrationIcon sx={{color: colorScheme}}/>}
                    {category === "inactive" && <DoNotDisturbOnIcon sx={{color: colorScheme }}/>}
                </div>
                <div className="f cC oH">
                    <div className="summaryInfoBlockName g f fR s">
                        <div className="f cC">
                            <div className="gCW">
                                {categoryPrompt}
                            </div>
                        </div>
                        <div className="overViewDataTotalAmountOption f cC gCW">
                            {convertIntToCurrency(deathBenefit, true)}
                        </div>
                    </div>
                </div>
            </div>);
        returningDisplay.push(
            <div key={1} className="divider">
            </div>
        );
        
        return returningDisplay;
    }

    const donutChartCategories = [
        "active",
        "closed",
        "inactive"
    ];

    let animationTime = .125;  // Reduce this to speed up the animation
    
    for (const category of donutChartCategories) {
        if (checkDataType(category)) {
            const currentCategory = category;
            const categoryTotal = props.chartData[category]?.count;
        
            let degree = (categoryTotal / relativeTotal) * 360;
            let colorScheme = loadCategoryColor(category);
            let animationDelay = null;
            let firstPartDegree;
            let continuedDegree = 0;
            if (lastAnimationDelay === 0) {
                animationDelay = 0;
                lastAnimationDelay = animationTime;
            } else {
                animationDelay = lastAnimationDelay;
                lastAnimationDelay = lastAnimationDelay + animationTime;
            }
    
            if (degree > 180) {
                firstPartDegree = 180;
                continuedDegree = degree - 180;
            } else {
                firstPartDegree = degree;
                continuedDegree = 0;
            }
    
            let keyframes = `@keyframes ${category.replace(/[^A-Z0-9]/ig, "_")}${pieceCount} {
                from { transform: rotate(0deg); }
                to { transform: rotate(${firstPartDegree}deg); }
            }`;
    
            createAnimation(keyframes);
            
            let categorySlider = {
                transform: `rotate(${lastPosition}deg)`,
                zIndex: zIndex,
            };
    
            let categoryProgress = {
                animation: `${category.replace(/[^A-Z0-9]/ig, "_")}${pieceCount} ${animationTime}s 1 forwards`,
                backgroundColor: colorScheme,
                animationTimingFunction: "ease-out",
                animationDelay: `${animationDelay}s`,
            };
    
            eachCategory.push(
                <div
                    key={pieceCount + "a"}
                    className="pieSlice"
                    style={categorySlider}
                    title={category}
                    onMouseOver={() => {
                        setCurrentCategoryHover(currentCategory);
                        setCurrentRelativeTotal(categoryTotal);
                    }}
                >
                    <div className="sliceProgress" style={categoryProgress}></div>
                </div>
            );
    
            lastPosition = lastPosition + firstPartDegree;   

            if (continuedDegree > 0) {
                pieceCount++;
                
                let continuedAnimationTime = (continuedDegree / 360) * animationTime;
                
                keyframes = `@keyframes ${category.replace(/[^A-Z0-9]/ig, "_")}${pieceCount} {
                    from { transform: rotate(0deg); }
                    to { transform: rotate(${continuedDegree}deg); }
                }`;
                
                createAnimation(keyframes);
                
                categorySlider = {
                    transform: `rotate(${lastPosition}deg)`,
                    zIndex: zIndex,
                };
                
                categoryProgress = {
                    animation: `${category.replace(/[^A-Z0-9]/ig, "_")}${pieceCount} ${continuedAnimationTime}s 1 forwards`,
                    backgroundColor: colorScheme,
                    animationTimingFunction: "ease-out",
                    animationDelay: `${lastAnimationDelay}s`,
                };
                
                eachCategory.push(
                    <div
                        key={pieceCount + "b"}
                        className="pieSlice"
                        style={categorySlider}
                        onMouseOver={() => {
                            setCurrentCategoryHover(currentCategory);
                            setCurrentRelativeTotal(categoryTotal);
                        }}
                    >
                        <div className="sliceProgress" style={categoryProgress} title={category}>
                        </div>
                    </div>);
                
                lastAnimationDelay = lastAnimationDelay + continuedAnimationTime;
            
                // Update the lastPosition here for the continuedDegree
                lastPosition = lastPosition + continuedDegree;
            }

            if(checkSummaryType(category)){
                summary.push(
                    <div key={pieceCount + "c"} className="summaryInfoBlock g cC f fC" title={category} onMouseOver={() => { setCurrentCategoryHover(currentCategory); setCurrentRelativeTotal(categoryTotal) }}>
                        {loadSummaryDisplay(category, colorScheme)}
                        <div className="summaryInfoBlockPercentage f cC">
                            {isNaN(props.chartData[category]?.count / relativeTotal) ? 
                                "0%"
                            :
                                ((props.chartData[category]?.count / relativeTotal) * 100).toFixed(0) + "%"
                            }
                        </div>
                    </div>
                )
            }

            //lastPosition = ((props.chartData[category]?.count / relativeTotal) * 360) + lastPosition;
            pieceCount++;
            zIndex++;
        }
    }


    let chartContainer = "homePagePieChart fR fC g cC";
    let chartType = "homePagePie";
    let chartSummary = "pieChartSummary fMP g f bR fR";
    let summaryContainer = "pieSummarySection g fMP";

    if(props.subData){
        chartContainer = "homePagePieChart small";
        chartType = "homePagePie small";
        chartSummary = "pieChartSummary fMP bR fR small";
        summaryContainer = "pieSummarySection g small";
    }
      
    return (
        <div className="chartContainer f">
            <div className="chartData g fC cC">
                <div className={chartContainer}>
                    <div className={chartType} onMouseLeave={()=> { setCurrentCategoryHover("default")}}>
                        {eachCategory}
                        <div className="pieTotalCountContainer" style={{zIndex : (relativeTotal + 1)}} onMouseOver={(e) => {e.preventDefault()}}>
                            <div className="pieTotalCountWrap">
                                <div className="pieTotalCount">
                                    <div>
                                        <div className="pieCountNumber">
                                            {currentRelativeTotal}
                                        </div>
                                        <span className="pieCountMessage">
                                            {currentCategoryHover}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={chartSummary} onMouseLeave={()=> { setCurrentCategoryHover("default")}}>
                    {summary}
                </div>
            </div>
        </div>

     )
 }

 export default DonutChart;