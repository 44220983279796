import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';

import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import PolicyTwoToneIcon from '@mui/icons-material/PolicyTwoTone';
import RingVolumeTwoToneIcon from '@mui/icons-material/RingVolumeTwoTone';
import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';

import './SideBar.css';

function SideBar(props){
    const twoToned = { color: 'blue', fill: 'red' };
    const location = useLocation();
    const [bidVerbiage, setBidVerbiage] = useState("Bid");

    function toggleMobileOptions(){
        if(props.showMobileMore){
            props.setShowMobileMore(false);
        }else{
            props.setShowMobileMore(true);
        }
    }

    function verifyAccess(page){
        const userData = props?.session?.user?.data;
        const accountData = userData?.accountData;

        if(userData?.views?.includes(page)){
            if (userData?.managedAccounts) {
                for (const accountId in accountData) {
                    const child = accountData?.[accountId];
                    if (child.views && child?.views.includes(page)) {
                        return true;
                    }
                }

                return false;
            }else{
                return accountData?.[userData?.accountID]?.views?.includes(page)
            }
        }

        return false;
    }

    function printPages(){

        let returningPages = [];

        if((verifyAccess("Home"))){
            returningPages.push(
                <Link
                    key="home"
                    to="/home"
                    className={"sideBarBtn fC f g cC" + props.maintainSelectedPage("home")} onClick={() => { props.changeActivityView("dashboard")}}
                >
                    <DashboardTwoToneIcon/>
                    <div className="cC f oH">
                        <div className="gCW">
                            Home
                        </div>
                    </div>
                </Link>
            )
        }

        if((verifyAccess("Policies"))){
            returningPages.push(
                <Link
                    key="policies"
                    to="/policies"
                    className={"sideBarBtn fC f g cC" + props.maintainSelectedPage("policies")} onClick={() => { props.changeActivityView("dashboard")}}
                >
                    <PolicyTwoToneIcon/>
                    <div className="cC f oH">
                        <div className="gCW">
                            Policies
                        </div>
                    </div>
                </Link>
            );
        }

        if(verifyAccess("Upload")){
            returningPages.push(
                <Link
                    key="upload"
                    to="/upload"
                    className={"sideBarBtn fC f g cC" + props.maintainSelectedPage("upload")} onClick={() => { props.changeActivityView("dashboard")}}
                >
                    <UploadTwoToneIcon/>
                    <div className="cC f oH">
                        <div className="gCW">
                            Upload
                        </div>
                    </div>
                </Link>
            );
        }

        if(verifyAccess("Leads")){
            returningPages.push(
                <Link
                    key="leads"
                    to="/leads"
                    className={"sideBarBtn f fC g cC" + props.maintainSelectedPage("leads")} onClick={() => { props.changeActivityView("dashboard")}}
                >
                    <RingVolumeTwoToneIcon/>
                    <div className="cC f oH">
                        <div className="gCW">
                            Leads
                        </div>
                    </div>
                </Link>
            );
        }

        if(verifyAccess("Bids")){
            returningPages.push(
                <Link
                    key="bids"
                    to="/bids"
                    className={"sideBarBtn f fC g cC" + props.maintainSelectedPage("bids")} onClick={() => { props.changeActivityView("dashboard")}}
                >
                    <StorefrontTwoToneIcon/>
                    <div className="cC f oH">
                        <div className="gCW">
                            {bidVerbiage === "Interest" ? "Cases" : "Bids"}
                        </div>
                    </div>
                </Link>
            );
        }

        if(verifyAccess("Reporting")){
            returningPages.push(
                <Link
                    key="reporting"
                    to="/reporting"
                    className={"sideBarBtn f fC g cC" + props.maintainSelectedPage("reporting")} onClick={() => { props.changeActivityView("dashboard")}}
                >
                    <AnalyticsTwoToneIcon/>
                    <div className="cC f oH">
                        <div className="gCW">
                            Reporting
                        </div>
                    </div>
                </Link>
            );
        }

        return returningPages;
    }

    useEffect(() => {
        if(props?.sessionUser?.accountData?.[props?.sessionUser?.accountID]?.isNetworker){
            setBidVerbiage("Interest");
        }
    }, [props?.sessionUser?.accountData?.[props?.sessionUser?.accountID]]);

    if(printPages().length > 1){
        return (
            <div className={"sideBar g ns fR pC" + printPages().length}>
                {printPages()}
            </div>
        )
    }
}

export default SideBar;

/*<div className={"sideBarBtn" + maintainSelectedPage("analytics")}>
                    <div className="sideBarBtnInner">
                        <div className="sideBarBtnInnerWrap">
                            <AnalyticsIcon/>
                        </div>
                    </div>
                    Analytics
                </div>*/