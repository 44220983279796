import React from 'react';

import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PanToolIcon from '@mui/icons-material/PanTool';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import MessageIcon from '@mui/icons-material/Message';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ForwardIcon from '@mui/icons-material/Forward';
import NextPlanOutlinedIcon from '@mui/icons-material/NextPlanOutlined';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SellIcon from '@mui/icons-material/Sell';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import GavelIcon from '@mui/icons-material/Gavel';
import SearchIcon from '@mui/icons-material/Search';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CampaignIcon from '@mui/icons-material/Campaign';
import ExtensionOffIcon from '@mui/icons-material/ExtensionOff';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import CancelIcon from '@mui/icons-material/Cancel';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ForumIcon from '@mui/icons-material/Forum';
import StartIcon from '@mui/icons-material/Start';

import { deepPurple, red, purple, indigo, blue, green, lightGreen, lightBlue, yellow, orange, deepOrange, blueGrey, grey, brown } from '@mui/material/colors';

export function returnStatusData(currentStatus, type, returnDataOnly, noColor, originator, timelineType){
    if(currentStatus === "Invoicing"){
        if(returnDataOnly === true){
            return {    color : "#673ab7",
                        icon : <ReceiptLongIcon sx={{ color: noColor ? "#FFFFFF" : deepPurple[500] }}/>,
                        position : "active"
                        }
        }
        return (<ReceiptLongIcon sx={{ color: noColor ? "#FFFFFF" : deepPurple[500] }}/>)
    }

    if(currentStatus === "Rescission"){
        if(returnDataOnly === true){
            return {    color : "#3f51b5",
                        icon : <HourglassTopIcon sx={{ color: noColor ? "#FFFFFF" : indigo[500] }}/>,
                        position : "active"
                        }
        }
        return (<HourglassTopIcon sx={{ color: noColor ? "#FFFFFF" : indigo[500] }}/>)
    }

    if(currentStatus === "Meds/Illustration Ordered"){
        if(returnDataOnly === true){
            return {    color : "#e53935",
                        icon : <MedicalInformationIcon sx={{ color: noColor ? "#FFFFFF" : red[600] }}/>,
                        position : "active"
                        }
        }
        return (<MedicalInformationIcon sx={{ color: noColor ? "#FFFFFF" : red[600] }}/>)
    }

    if(currentStatus === "Underwriting (LE's)"){
        if(returnDataOnly === true){
            return {    color : "#2196f3",
                        icon : <NoteAltIcon sx={{ color: noColor ? "#FFFFFF" : blue[500] }}/>,
                        position : "active"
                        }
        }
        return(<NoteAltIcon sx={{ color: noColor ? "#FFFFFF" : blue[500] }}/>)
    }

    if(currentStatus === "Contracts Back"){
        if(returnDataOnly === true){
            return {    color : "#f9a825",
                        icon : <LockPersonIcon sx={{ color: noColor ? "#FFFFFF" : yellow[800] }}/>,
                        position : "active"
                        }
        }
        return(<LockPersonIcon sx={{ color: noColor ? "#FFFFFF" : yellow[800] }}/>)
    }

    if(currentStatus === "Contracts Back (Digital)"){
        if(returnDataOnly === true){
            return {    color : "#f9a825",
                        icon : <LockPersonIcon sx={{ color: noColor ? "#FFFFFF" : yellow[800] }}/>,
                        position : "active"
                        }
        }
        return(<LockPersonIcon sx={{ color: noColor ? "#FFFFFF" : yellow[800] }}/>)
    }

    if(currentStatus === "Contracts Out"){
        if(returnDataOnly === true){
            return {    color : "#f9a825",
                        icon : <LockPersonIcon sx={{ color: noColor ? "#FFFFFF" : yellow[800] }}/>,
                        position : "active"
                        }
        }
        return(<LockPersonIcon sx={{ color: noColor ? "#FFFFFF" : yellow[800] }}/>)
    }

    if(currentStatus === "Offer Letter Out / CRF"){
        if(returnDataOnly === true){
            return {    color : "#455a64",
                        icon : <RequestQuoteIcon sx={{ color: noColor ? "#FFFFFF" : blueGrey[700] }}/>,
                        position : "active"
                        }
        }
        return(<RequestQuoteIcon sx={{ color: noColor ? "#FFFFFF" : blueGrey[700] }}/>)
    }

    if(currentStatus === "Offer Letter In"){
        if(returnDataOnly === true){
            return {    color : "#607d8b",
                        icon : <RequestPageIcon sx={{ color: noColor ? "#FFFFFF" : blueGrey[500] }}/>,
                        position : "active"
                        }
        }
        return(<RequestPageIcon sx={{ color: noColor ? "#FFFFFF" : blueGrey[500] }}/>)
    }

    if(currentStatus === "Bids"){
        if(returnDataOnly === true){
            return {    color : "#6a1b9a",
                        icon : <ThumbsUpDownIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>,
                        position : "active"
                        }
        }
        return(<ThumbsUpDownIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>)
    }

    if(currentStatus === "Pre-Priced"){
        if(returnDataOnly === true){
            return {    color : "#4caf50",
                        icon : <PriceChangeIcon sx={{ color: noColor ? "#FFFFFF" : green[500] }}/>,
                        position : "active"
                        }
        }
        return(<PriceChangeIcon sx={{ color: noColor ? "#FFFFFF" : green[500] }}/>)
    }

    if(currentStatus === "Client Not Interested" || currentStatus === "Client not Interested"){
        if(returnDataOnly === true){
            return {    color : "#ef5350",
                        icon : <PersonOffIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>,
                        position : "inactive"
                        }
        }
        return(<PersonOffIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>)
    }

    if(currentStatus === "Failed to Contact"){
        if(returnDataOnly === true){
            return {    color : "#ef5350",
                        icon : <PhoneDisabledIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>,
                        position : "inactive"
                        }
        }
        return(<PhoneDisabledIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>)
    }
    if(currentStatus === "Unqualified"){
        if(returnDataOnly === true){
            return {    color : "#ef5350",
                        icon : <CancelIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>,
                        position : "inactive"
                        }
        }
        return(<CancelIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>)
    }
    if(currentStatus === "Sent to 3rd Party"){
        if(returnDataOnly === true){
            return {    color : "#f57c00",
                        icon : <EmojiPeopleIcon sx={{ color: noColor ? "#FFFFFF" : orange[700] }}/>,
                        position : "active"
                        }
        }
        return(<EmojiPeopleIcon sx={{ color: noColor ? "#FFFFFF" : orange[700] }}/>)
    }
    if(currentStatus === "Sent to Abacus"){
        if(returnDataOnly === true){
            return {    color : "#104735",
                        icon : <RestartAltIcon sx={{ color: noColor ? "#FFFFFF" : "#104735"}}/>,
                        position : "active"
                        }
        }
        return(<RestartAltIcon sx={{ color: noColor ? "#FFFFFF" : "#104735" }}/>)
    }
    if(currentStatus === "Escrow / At Carrier" || currentStatus === "Escrow"){
        if(returnDataOnly === true){
            return {    color : "#1976d2",
                        icon : <HandshakeIcon sx={{ color: noColor ? "#FFFFFF" : blue[700] }}/>,
                        position : "active"
                        }
        }
        return(<HandshakeIcon sx={{ color: noColor ? "#FFFFFF" : blue[700] }}/>)
    }

    if(currentStatus === "On Hold"){
        if(returnDataOnly === true){
            return {    color : "#ff7043",
                        icon : <PanToolIcon sx={{ color: noColor ? "#FFFFFF" : deepOrange[400] }}/>,
                        position : "active"
                        }
        }
        return(<PanToolIcon sx={{ color: noColor ? "#FFFFFF" : deepOrange[400] }}/>)
    }

    if(currentStatus === "Lead"){
        if(returnDataOnly === true){
            return {    color : "#9ccc65",
                        icon : <FollowTheSignsIcon sx={{ color: noColor ? "#FFFFFF" : lightGreen[400] }}/>,
                        position : "active"
                        }
        }
        return(<FollowTheSignsIcon sx={{ color: noColor ? "#FFFFFF" : lightGreen[400] }}/>)
    }

    if(currentStatus === "Shipped"){
        if(returnDataOnly === true){
            return {    color : "#9c27b0",
                        icon : <LocalShippingIcon sx={{ color: noColor ? "#FFFFFF" : purple[500] }}/>,
                        position : "active"
                        }
        }
        return(<LocalShippingIcon sx={{ color: noColor ? "#FFFFFF" : purple[500] }}/>)
    }

    if(currentStatus === "Shipped - Bid"){
        if(returnDataOnly === true){
            return {    color : "#4caf50",
                        icon : <LocalShippingIcon sx={{ color: noColor ? "#FFFFFF" : green[500] }}/>,
                        position : "active"
                        }
        }
        return(<LocalShippingIcon sx={{ color: noColor ? "#FFFFFF" : green[500] }}/>)
    }

    if(currentStatus === "Pricing / Valuation"){
        if(returnDataOnly === true){
            return {    color : "#9c27b0",
                        icon : <PersonSearchIcon sx={{ color: noColor ? "#FFFFFF" : purple[600] }}/>,
                        position : "active"
                        }
        }
        return(<PersonSearchIcon sx={{ color: noColor ? "#FFFFFF" : purple[600] }}/>)
    }

    if(currentStatus === "Unqualified Closed"){
        if(returnDataOnly === true){
            return {    color : "#ef5350",
                        icon : <DoNotDisturbOnIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>,
                        position : "closed"
                        }
        }
        return(<DoNotDisturbOnIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>)
    }

    if(currentStatus === "Closed Lost with Bid" || currentStatus === "Lost with Bid"){
        if(returnDataOnly === true){
            return {    color : "#ef5350",
                        icon : <SentimentVeryDissatisfiedIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>,
                        position : "closed"
                        }
        }
        return(<SentimentVeryDissatisfiedIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>)
    }

    if(currentStatus === "Closed Lost"){
        if(returnDataOnly === true){
            return {    color : "#ef5350",
                        icon : <NotInterestedIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>,
                        position : "closed"
                        }
        }
        return(<NotInterestedIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>)
    }

    if(currentStatus === "Closed Lost no bid"){
        if(returnDataOnly === true){
            return {    color : "#ef5350",
                        icon : <ThumbDownIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>,
                        position : "closed"
                        }
        }
        return(<ThumbDownIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>)
    }

    if(currentStatus === "Closed Won"){
        if(returnDataOnly === true){
            return {    color : "#e65100",
                        icon : <CelebrationIcon sx={{ color: noColor ? "#FFFFFF" : orange[900] }}/>,
                        position : "closed"
                        }
        }
        return(<CelebrationIcon sx={{ color: noColor ? "#FFFFFF" : orange[900] }}/>)
    }

    if(currentStatus === "Application Sent"){
        if(returnDataOnly === true){
            return {    color : "#66bb6a",
                        icon : <ForwardIcon sx={{ color: noColor ? "#FFFFFF" : green[400] }}/>,
                        position : "active"
                        }
        }
        return(<ForwardIcon sx={{ color: noColor ? "#FFFFFF" : green[400] }}/>)
    }

    if(currentStatus === "Application Sent - Lead"){
        if(returnDataOnly === true){
            return {    color : "#6a1b9a",
                        icon : <ForwardIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>,
                        position : "active"
                        }
        }
        return(<ForwardIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>)
    }

    if(currentStatus === "Contact Attempted 1" || currentStatus === "Contact Attempted 2" || currentStatus === "Contact Attempted 3" || currentStatus === "Contact Attempted 4" || currentStatus === "Contact Attempted 5"){
        if(returnDataOnly === true){
            return {    color : "#0277bd",
                        icon : <PhoneMissedIcon sx={{ color: noColor ? "#FFFFFF" : lightBlue[800] }}/>,
                        position : "active"
                        }
        }
        return(<PhoneMissedIcon sx={{ color: noColor ? "#FFFFFF" : lightBlue[800] }}/>)
    }

    if(currentStatus === "Application Received"){
        if(returnDataOnly === true){
            return {    color : "#6a1b9a",
                        icon : <CallReceivedIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>
                        };
        }
        return (<CallReceivedIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>)
    }

    if(currentStatus === "Follow Up"){
        if(returnDataOnly === true){
            return {    color : "#6a1b9a",
                        icon : <AccessAlarmIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>
                        };
        }
        return (<AccessAlarmIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>)
    }

    if(currentStatus === "Owned"){
        if(returnDataOnly === true){
            return {    color : "#6d4c41",
                        icon : <AccountBalanceWalletIcon sx={{ color: noColor ? "#FFFFFF" : brown[600] }}/>
                        };
        }
        return (<AccountBalanceWalletIcon sx={{ color: noColor ? "#FFFFFF" : brown[600] }}/>)
    }

    if(currentStatus === "Sold"){
        if(returnDataOnly === true){
            return {    color : "#4caf50",
                        icon : <SellIcon sx={{ color: noColor ? "#FFFFFF" : green[500] }}/>
                        };
        }
        return (<SellIcon sx={{ color: noColor ? "#FFFFFF" : green[500] }}/>)
    }

    if(currentStatus === "Matured"){
        if(returnDataOnly === true){
            return {    color : "#4527a0",
                        icon : <RunningWithErrorsIcon sx={{ color: noColor ? "#FFFFFF" : deepPurple[800] }}/>
                        };
        }
        return (<RunningWithErrorsIcon sx={{ color: noColor ? "#FFFFFF" : deepPurple[800] }}/>)
    }

    if(currentStatus === "Lapsed"){
        if(returnDataOnly === true){
            return {    color : "#ff9800",
                        icon : <TimelapseIcon sx={{ color: noColor ? "#FFFFFF" : orange[500] }}/>
                        };
        }
        return (<TimelapseIcon sx={{ color: noColor ? "#FFFFFF" : orange[500] }}/>)
    }

    if(currentStatus === "Contracts"){
        if(returnDataOnly === true){
            return {    color : "#ff5722",
                        icon : <GavelIcon sx={{ color: noColor ? "#FFFFFF" : deepOrange[500] }}/>
                        };
        }
        return (<GavelIcon sx={{ color: noColor ? "#FFFFFF" : deepOrange[500] }}/>)
    }

    if(currentStatus === "Contracts"){
        if(returnDataOnly === true){
            return {    color : "#ff5722",
                        icon : <GavelIcon sx={{ color: noColor ? "#FFFFFF" : deepOrange[500] }}/>
                        };
        }
        return (<GavelIcon sx={{ color: noColor ? "#FFFFFF" : deepOrange[500] }}/>)
    }
    if(currentStatus === "Does Not Fit Parameters"){
        if(returnDataOnly === true){
            return {    color : "#ef5350",
                        icon : <ExtensionOffIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>
                        };
        }
        return (<ExtensionOffIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>)
    }

    if(currentStatus === "Bidding" || currentStatus === "Interested"){
        if(returnDataOnly === true){
            if(originator === "Customer"){
                return {    color : "#6a1b9a",
                    icon : <ThumbsUpDownIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>
                    };
            }else{
                return {    color : "#6a1b9a",
                    icon : <CampaignIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>
                    };
            }
        }
    
        if(originator === "Customer"){
            return (<ThumbsUpDownIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>)
        }else{
            return (<CampaignIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>)
        }
    }

    if(currentStatus === "In Conflict"){
        if(returnDataOnly === true){
            return {    color : "#ef5350",
                        icon : <NearbyErrorIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>,
                        position : "closed"
                        }
        }
        return(<NearbyErrorIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>)
    }

    if(currentStatus === "No Bid"){
        if(returnDataOnly === true){
            return {    color : "#ef5350",
                        icon : <ThumbDownOffAltIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>,
                        position : "closed"
                        }
        }
        return(<ThumbDownOffAltIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>)
    }

    if(currentStatus === "Under Review"){
        if(returnDataOnly === true){
            return {    color : "#2196f3",
                        icon : <SearchIcon sx={{ color: noColor ? "#FFFFFF" : blue[500] }}/>
                        };
        }
        return (<SearchIcon sx={{ color: noColor ? "#FFFFFF" : blue[500] }}/>)
    }

    if(currentStatus === "Requested Info"){
        if(returnDataOnly === true){
            return {    color : "#6a1b9a",
                        icon : <PendingActionsIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>
                        };
        }
        return (<PendingActionsIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>)
    }

    if(currentStatus === "Service Start"){
        if(returnDataOnly === true){
            return {    color : "#4caf50",
                        icon : <StartIcon sx={{ color: noColor ? "#FFFFFF" : green["500"] }}/>
                        };
        }
        return (<StartIcon sx={{ color: noColor ? "#FFFFFF" : green["500"] }}/>)
    }

    if(type === "Message"){
        if(returnDataOnly === true){
            return {    color : "#4fc3f7",
                        icon : <ForumIcon sx={{ color: noColor ? "#FFFFFF" : lightBlue[800] }}/>
                        };
        }
        return (<ForumIcon sx={{ color: noColor ? "#FFFFFF" : lightBlue[800] }}/>)
    }

    if(type === "Request"){
        if(returnDataOnly === true){
            return {    color : "#ef5350",
                        icon : <AddTaskIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>
                        };
        }
        return (<AddTaskIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>)
    }

    if(type === "Note"){
        if(returnDataOnly === true){
            if(originator === "Customer" && timelineType.toLowerCase() !== "lead"){
                return {    color : "#66bb6a",
                    icon : <MessageIcon sx={{ color: noColor ? "#FFFFFF" : green[400] }}/>
                    };
            }else{
                return {    color : "#81d4fa",
                    icon : <MessageIcon sx={{ color: noColor ? "#FFFFFF" : green[400] }}/>
                    };
            }
        }

        if(originator === "Customer" && timelineType.toLowerCase() !== "lead"){
            return (<MessageIcon sx={{ color: noColor ? "#FFFFFF" : green[400] }}/>)
        }else{
            return (<MessageIcon sx={{ color: noColor ? "#FFFFFF" : lightBlue[500] }}/>)
        }
    }

    if(type === "Follow Up"){
        if(returnDataOnly === true){
            return {    color : "#6a1b9a",
                        icon : <AccessAlarmIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>
                        };
        }
        return (<AccessAlarmIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>)
    }

    if(type === "VOC"){
        if(returnDataOnly === true){
            return {    color : "#4fc3f7",
                        icon : <VerifiedUserIcon sx={{ color: noColor ? "#FFFFFF" : lightBlue[400] }}/>
                        };
        }
        return (<VerifiedUserIcon sx={{ color: noColor ? "#FFFFFF" : lightBlue[400] }}/>)
    }

    if(type === "Payment"){
        if(returnDataOnly === true){
            return {    color : "#4caf50",
                        icon : <MonetizationOnIcon sx={{ color: noColor ? "#FFFFFF" : green[500] }}/>
                        };
        }
        return (<MonetizationOnIcon sx={{ color: noColor ? "#FFFFFF" : green[500] }}/>)
    }

    if(returnDataOnly === true){
        return {    color : "#9e9e9e",
                    icon : <NextPlanOutlinedIcon sx={{ color: noColor ? "#FFFFFF" : grey[500] }}/>,
                    position : "active"
                    }
    }

    return(<NextPlanOutlinedIcon sx={{ color: noColor ? "#FFFFFF" : grey[500] }}/>)
    
}

function StatusIcon(props){
    return returnStatusData(props.currentStatus, props.type, null, props.noColor, props.originator, props?.timelineType);
}

export default StatusIcon;